import { makeStyles } from "@material-ui/core";
export const useCallsStyles = makeStyles((theme) => ({
    sectionTitle: {
        fontSize: 14,
        lineHeight: '17px',
        letterSpacing: '0.1em',
        marginLeft: 10,
        fontFamily: 'Kanit'
    },
    betCell: {
        width: 'calc(100% - 32px)',
        backgroundColor: '#2A2D35',
        borderRadius: 4,
        marginLeft: 16,
        marginRight: 16,
        marginBottom: 16
    },
    betCellTopSection: {
        flex: 1,
        width: 'calc(100% - 32px)',
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 16,
        paddingRight: 16
    },
    teamLabel: {
        fontFamily: 'Kanit',
        color: '#FFF',
        fontWeight: 500,
        fontSize: 18,
        lineHeight: '20px',
        letterSpacing: '0.02em',
        width: '50%',
        float: 'left'
    },
    winOrLoss: {
        fontFamily: 'Kanit',
        color: '#FFF',
        fontWeight: 500,
        fontSize: 18,
        lineHeight: '20px',
        letterSpacing: '0.02em',
        width: '50%',
        textAlign: 'right',
        float: 'right'
    },
    betCellTopSecTopRow: {
        flex: 1,
        flexDirection: 'row',
        width: '100%',
        height: 20,
        marginBottom: 32,
    },
    betCellTopSecBottRow: {
        flex: 1,
        flexDirection: 'row',
        width: '100%',
        height: 20
    },
    leftLabel: {
        color: 'rgba(255, 255, 255, 0.5)',
        float: 'left',
        lineHeight: '20px',
        marginRight: 8,
        height: 20
    },
    leftValue: {
        color: '#FFF',
        float: 'left',
        lineHeight: '20px',
        height: 20
    },
    openTotGains: {
        color: 'rgba(255, 255, 255, 0.5)',
        float: 'right',
        lineHeight: '20px',
        marginRight: 16
    },
    betCellBottSection: {
        flex: 1,
        width: 'calc(100% - 32px)',
        paddingTop: 20,
        paddingBottom: 12,
        paddingLeft: 16,
        paddingRight: 16,
        backgroundColor: '#34373F',
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
    },
    betCellBottSecTopRow: {
        flex: 1,
        flexDirection: 'row',
        width: '100%',
        height: 20,
        paddingBottom: 24
    },
    betCellOdds: {
        float: 'right',
        height: 20,
        borderRadius: 4,
        marginRight: 0,
        border: '1px solid rgba(255, 255, 255, 0.2)',
        paddingLeft: 10,
        paddingRight: 10,
        color: '#FDC934',
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: '0.04em',
        fontWeight: 600,
        textAlign: 'center'
    },
    betCellBottSecBottRow: {
        flex: 1,
        flexDirection: 'row',
        width: '100%',
        height: 20,
        marginBottom: 10
    },
    betCellPotGains: {
        float: 'right',
        height: 20,
        marginRight: 0,
        flexDirection: 'row'
    },
    coin: {
        float: "left",
        height: 14,
        marginLeft: 2,
        marginTop: 3
    },
    resultSection: {
        flex: 1,
        flexDirection: 'row',
        width: '100%',
        marginTop: 20,
        marginBottom: 10,
        color: '#fff',
        minHeight: 30,
        overflow: 'hidden'
    },
    resultDescription: {
        color: '#FFF',
        float: 'left',
        lineHeight: '20px',
        maxHeight: 40,
        height: 'auto',
        textOverflow: 'ellipsis',
        overflow: "hidden",
        whiteSpace: "nowrap",
        maxWidth: "85%"
    },
}));
