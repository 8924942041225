import React, { useEffect, useRef, useState } from "react";
import {Box, Container, FormControl, Grid, TextField, Typography} from "@material-ui/core";
import { useStyles } from "../styles";
import PlaysHeader from "../../ui/PlaysHeader";
import {PRIVACY_POLICY} from "../../PrivacyPolicyText";


function PrivacyPolicy() {
    const classes = useStyles();
    return (
        <Box className={classes.body}>
            <PlaysHeader showBackButton={false} showAccountIcon={false}/>
            <Grid
                container
                className={classes.terms}
            >
                {PRIVACY_POLICY.TEXT_FIRST}
                <ul>
                    {PRIVACY_POLICY.LI_ELEMENTS.map(item => {
                        return <li key={item}>{item}</li>;
                    })}
                </ul>
                {PRIVACY_POLICY.TEXT_SECOND}
            </Grid>
        </Box>
    );
}
export default PrivacyPolicy;
