import { Box, Grid, Typography } from "@material-ui/core";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import CompassCalibrationIcon from "@material-ui/icons/CompassCalibration";
import FlagIcon from "@material-ui/icons/Flag";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import React, { useEffect, useRef, useState } from "react";
import Fade from "react-reveal/Fade";
import { getGameInfo } from "../../../hooks/useGame";
import { fetchData } from "../../../service/getPlaysOdds";
import { getRunPassData } from '../../../service/getRunPassData';
import { getNbaData } from '../../../service/getNbaData';
import BetBar from "../../ui/BetBar";
import BetButton from "../../ui/BetButton";
import BetWonAlert from "../../ui/BetWonAlert";
import PauseScreen from "../../ui/PauseScreen";
import PotentialReturnAlert from "../../ui/PotentialReturnAlert";
import { useStyles } from "../styles";
import BetList from "./BetList";
import "./Game.css";
import MatchHeader from "./MatchHeader";
import {utcDateStrToDate} from "../../../utils/dateUtils";
import BetErrorAlert from "../../ui/BetErrorAlert";

/*duumy comment*/

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function Game(props) {
  const setIsShowBottomBar = props.setIsShowBottomBar
  const setSelectedTabIdx = props.setSelectedTabIdx
  const { token, plays, setToken, gameType, possesionOptions} = props
  // console.log('plays = ', props ? props.plays : null)
  const [showBets, setShowBets] = useState(true);
  const [type, setType] = useState({ name: "", odds: "", icon: "" });
  const [player, setPlayer] = useState({ id: 0, name: "", odds: "" });
  const [newPossesionOptions, setPossesionOptions] = useState(possesionOptions);
  const [newPlays, setNewPlays] = useState(plays);
  const [gameStatus, setGameStatus] = useState();
  // const [nflGame, setNflGame] = useState();
  const [selectedBet, setSelectedBet] = useState(null);
  const [selectedButton, setSelectedButton] = useState(0);
  const [betMountClicked, setBetMountClicked] = useState(false);
  const [oddsClicked, setOddsClicked] = useState(false);
  const classes = useStyles();
  const [activeShow, setActiveShow] = useState(true);
  const [betAmount, setBetAmount] = useState(0);
  const [showPotentialReturn, setShowPotentialReturn] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [runPlayers, setRunPlayers] = useState([]);
  const [passPlayers, setPassPlayers] = useState([]);
  const [positionTargets, setPositionTargets] = useState([]);
  const [playerTargets, setPlayerTargets] = useState([]);
  const [playResults, setPlayResults] = useState([]);

  const [showBetWonAlert, setShowBetWonAlert] = useState(false);
  const [seeMoreClicked, setSeeMoreClicked] = useState(false);
  const [recentBets, setRecentBets] = useState([]);
  // const [betsWonIds, setBetsWonIds] = useState([]);
  const [winAmount, setWinAmount] = useState(0);
  // const [outcomeId, setOutcomeId] = useState(0);
  let interval = useRef();
  // const prevGameStatus = usePrevious(gameStatus);
  const prevNewPlays = usePrevious(newPlays);
  const prevNewPossesionOptions = usePrevious(newPossesionOptions);
  // const prevOutcomeId = usePrevious(outcomeId);
  // const [nextPossesion, setNextPossesion]= useState(false);
  // const [nextPossesionClicked, setNextPossClicked] = useState(false);
  // const [nextPossessionOptionClicked, setNextPossessionOptionClicked] = useState(false);
  // const [nbaPlayersVal, setNbaPlayersVal] =  useState([]);
  // const [mainShotZoneOpt, setMainShotZoneOpt] =  useState({
  //   isClicked: false,
  //   playerId: '',
  //   mainShotId:''
  // });
  const [combineBet, setConbineBet] =  useState({
    mainBet: "",
    playerBet: ""
  });
  const [tournament, setTournament] = useState(null)
  const [gameId, setGameId] = useState(null)
  const [quarter, setQuarter] = useState(0)
  const [betsPaused, setBetsPaused] = useState(false)
  const [betErrorMsg, setBetErrorMsg] = useState(false);
  const [showBetErrorAlert, setShowBetErrorAlert] = useState(false);

  // const location = useLocation();

  useEffect(() => {
    return function cleanup() {
      console.log('-----------CLEANUP')
      if (interval && interval.current) {
        clearInterval(interval.current);
        interval.current = null
      }
    }
  }, [])

  // useEffect(() => {
  //   // console.log('runPlayers = ', runPlayers)
  // }, [runPlayers])

  useEffect(() => {
    if (gameId) {
      if (gameStatus?.offense_team)
        updateAsync(gameStatus.offense_team);
    }
  }, [gameId]);

  useEffect(() => {
    console.log('useEffect plays = ', plays)
    if (
      prevNewPlays &&
      prevNewPlays.odds !== newPlays.odds
    )  {
      setNewPlays(plays);
    }
  }, [plays]);

  useEffect(() => {

    // console.log('useEffect newPossesionOptions = ', newPossesionOptions)
    // console.log('useEffect possesionOptions = ', possesionOptions)
    if (
      prevNewPossesionOptions &&
      prevNewPossesionOptions.odds !== newPossesionOptions.odds
    ) {
      setNewPlays(possesionOptions);
    }
  }, [possesionOptions]);


  useEffect(() => {
    console.log('useEffect type')
    setIsShowBottomBar(true)
    setSelectedTabIdx(0)

    return function cleanup() {
      // this is done now in useEffect(, []) (default) since
      // this useEffect(,type) wasn't getting called when coming back from
      // betting section e.g. Pass Play options section
      // // console.log('type cleanup')
      // if (interval && interval.current) {
      //   clearInterval(interval.current);
      //   interval.current = null
      // }
    }
  }, [type]);

  // useEffect(() => {}, [selectedBet]);

  useEffect(() => {
    // console.log('showBets changing: ', showBets)
    // console.log('--tournament = ', tournament)
    // console.log('--game id = ', gameId)
    if (showBets) {
     // setType({ name: "", odds: "", icon: "" })
      setSelectedButton(0);
      setSelectedBet(null);

      setRunPlayers([])
      setPassPlayers([])
      setPlayerTargets([])
      setPositionTargets([])
      setPlayResults([])

      initGameStatusCalls()
    }
    return function cleanup() {
      console.log('------showBets cleanup')
      // if (interval && interval.current) {
      //   clearInterval(interval.current);
      //   interval.current = null
      // }
    }
  }, [showBets]);

  useEffect(() => {
    // console.log('useEffect showBetWonAlert = ', showBetWonAlert)
    if (!showBetWonAlert) {
      setWinAmount(0);

      if (recentBets) {
        // we can mark these recent won bets as shown, so if they come back
        // from the back-end we don't re-show
        let betsWonIds = JSON.parse(localStorage.getItem("betsWonIds"))
        if (!betsWonIds)
          betsWonIds = []
        for (let i = 0; i < recentBets.length; ++i) {
          betsWonIds.push(recentBets[i].id)
        }
        localStorage.setItem("betsWonIds", JSON.stringify(betsWonIds));
        setRecentBets([]);
      }
    }
  }, [showBetWonAlert]);

  useEffect(async () => {
    // if (!prevGameStatus || (gameStatus && JSON.stringify(prevGameStatus) !== JSON.stringify(gameStatus))) {
    //   // console.log('Got different gameStatus, gameId = ' + gameId)
    //   if (gameStatus && gameStatus.outcome_id !== prevOutcomeId) {
    //     // setShowBetWonAlert(false);
    //     setSeeMoreClicked(false);
    //     if (gameStatus?.offense_team) {
    //       await updateAsync(gameStatus.offense_team);
    //     }
    //   }
    // // console.log('Game.jsx hook = gameStatus, gameType = ', gameType)
    // // console.log('Game.jsx hook = gameStatus = ', gameStatus)
    // }
    if (!betsPaused && gameStatus && gameStatus.bets_live === 0) {
      setBetsPaused(true)
    } else if (gameStatus?.offense_team && gameStatus && gameStatus.bets_live === 1) {
      const unpause_bets = betsPaused
      await updateAsync(gameStatus.offense_team, unpause_bets);
    }
  }, [gameStatus]);

  const initGameStatusCalls = () => {
    if (tournament) {
      if (!interval || !interval.current) {
        getGameStatusInfo(tournament);
      }
    } else {
      // let tournamentLocal = localStorage.getItem("tournament");
      const tournament = localStorage.getItem('tournament');
      // let tournamentParsed = location.state.tournament
      let tournamentParsed = JSON.parse(tournament)

      // console.log('tournamentParsed = ', tournamentParsed)

      setGameId(tournamentParsed.game_id)
      // console.log('setGameId(tournamentParsed.game_id) = ', tournamentParsed.game_id)
      setTournament(tournamentParsed) // seems to be a race-condition between this and getGameStatusInfo() being able
                                      // to see the new 'tournament' object by referencing 'tournament'

      if (!interval || !interval.current) {
        getGameStatusInfo(tournamentParsed)
      }
    }
  }

  const updateAsync = async (team, unpause_bets = false) => {
    // console.log('updateAsync, gameId = ', gameId)
    // console.log('updateAsync, team = ', team)
    try {
      if (unpause_bets) {
        console.log('updateAsync starting with unpause')
      }
      await fetchData(gameId);
      if (unpause_bets) {
        // console.log('Done getting new probabilities and ready to unpause')
        setBetsPaused(false);
      }
      if (team) {
        // console.log('getRunPassData from timeout, team = ', team);
        getRunPassData(gameId, type, team, setRunPlayers, setPassPlayers, setPlayerTargets, setPositionTargets,
          setPlayResults)
      }
      clearTimeout()
    } catch (e) {
      alert(e);
    }
  }

  const refreshOddsForSection = async () => {
    console.log('refreshOddsForSection')
    if (!gameStatus?.offense_team)
      return
    const offense_team = gameStatus.offense_team
    await getRunPassData(gameId, type, offense_team, setRunPlayers, setPassPlayers, setPlayerTargets, setPositionTargets,
        setPlayResults)
  }

  const onDoShowBetErrorAlert = async () => {
    setShowBetErrorAlert(true)
    await refreshOddsForSection()
  }

  const fetchBetsData = (response, bets_live) => {
    // console.log('fetchBetsData response = ', response)
    // let amount = 0;
    let alreadyShow = false;
    // let alreadyLostShow = false;
    // let wonBets = [];
    // let betLostIds = []
    // let retrievedBetWonIds = JSON.parse(localStorage.getItem("betsWonIds"));
    // console.log('retrievedBetWonIds = ', retrievedBetWonIds)
    // console.log('fetchBetsData response.length = ', response.length)
    // console.log('fetchBetsData response = ', response)
    if (response && response.length > 0) {
      let betsWonIds = JSON.parse(localStorage.getItem("betsWonIds"));
      // betsWonIds = [];
      // let retrievedBetLostIds = JSON.parse(localStorage.getItem("betsLostIds"));
      let newRecentBets = recentBets
      let newWinAmount = 0
      response.forEach((item) => {
        // console.log('bet = ', item)
        // if (item.did_win_bet === 0 && bets_live === 1) {
        // if (item.did_win_bet === 0) {
        //   if(!retrievedBetLostIds) {
        //     betLostIds.push(item.id);
        //     wonBets.push(item);
        //     amount = winAmount - parseFloat(item.amount);
        //     localStorage.setItem("betsLostIds", JSON.stringify(betLostIds));
        //     // setRecentBets([...recentBets, item]);
        //     // setWinAmount(amount);
        //     newRecentBets = [...recentBets, item]
        //     newWinAmount += amount
        //   }
        //   else {
        //     alreadyLostShow = retrievedBetLostIds.some((arrVal) => item.id === arrVal);
        //     if (!alreadyLostShow) {
        //       retrievedBetLostIds.push(item.id);
        //       wonBets.push(item);
        //       amount = winAmount - parseFloat(item.amount);
        //       localStorage.setItem("betsLostIds", JSON.stringify(retrievedBetLostIds));
        //       // setRecentBets([...recentBets, item]);
        //       // setWinAmount(amount);
        //       newRecentBets = [...recentBets, item]
        //       newWinAmount += amount
        //     }
        //   }
        //  /* wonBets.push(item);
        //   winAmount -= parseFloat(item.amount);*/
        // }
        // if (item.did_win_bet === 1 && bets_live === 1) {

        // console.log('bet item = ', item)
        // console.log('betsWonIds = ', betsWonIds)
        if (item.did_win_bet === 1) {
          // console.log('A')
          if (!betsWonIds) {
            betsWonIds = [item.id]
            // console.log('B')
            let win_amount = item.winning_amount? item.winning_amount : 0;
            // amount = winAmount + parseFloat(win_amount);
            // wonBets.push(item);
            // setRecentBets([...recentBets, item]);
            // setWinAmount(amount);
            newRecentBets = [...newRecentBets, item]
            newWinAmount += parseFloat(win_amount)
            // localStorage.setItem("betsWonIds", JSON.stringify([item.id]));
          } else {
            // console.log('V')
            alreadyShow = betsWonIds.some((arrVal) => item.id === arrVal);
            if (!alreadyShow) {
              let win_amount = item.winning_amount? item.winning_amount : 0;
              // setBetsWonIds([...betsWonIds, item.id]);
              betsWonIds.push(item.id);

              // wonBets.push(item);
              // localStorage.setItem("betsWonIds", JSON.stringify(betsWonIds));
              // setRecentBets([...recentBets, item]);
              // amount =  winAmount + parseFloat(win_amount);
              // setWinAmount(amount);
              newRecentBets = [...newRecentBets, item]
              newWinAmount += parseFloat(win_amount)
            }
          }
        }
      });
      // console.log('fetchBetsData setRecentBets(newRecentBets) = ', newRecentBets)
      setRecentBets(newRecentBets)
      setWinAmount(newWinAmount)

      // do this on useEffect() for newRecentBets
      // localStorage.setItem("betsWonIds", JSON.stringify(betsWonIds));
    }
  }

  const fetchGameStatusData = (t) => {
    // console.log('fetchGameStatusData2')
    // console.log('fetchGameStatusData2, token = ', token)
    // const t = getTournament()
    // console.log('fetchGameStatusData2, t = ', t)
    if (token?.id && t?.id) {
      // console.log('doing call')
      let queryParams = {
        user_id: token.id,
        tournament_id: t.id
      }
      getGameInfo(queryParams)
          .then((response) => {
            // console.log('Response2 = ', response)
            if (response?.data?.live_play_situations?.length > 0) {
              // console.log('Response3 = ', response.data)
              let live_play_situation = null
              // console.log('t = ', t)
              for (let i = 0; i < response.data.live_play_situations.length; ++i) {
                const loop_sit = response.data.live_play_situations[i]
                // console.log('loop_sit = ', loop_sit)
                if (t.away === loop_sit.away_team && t.home === loop_sit.home_team) {
                  live_play_situation = loop_sit
                  break
                }
              }
              // console.log('live_play_situation = ', live_play_situation)
              // console.log('response.data.recent_bets = ', response.data.recent_bets)
              if (live_play_situation) {
                setGameStatus({
                  ...live_play_situation,
                  user: response.data.user,
                  bets_live: response.data.bets_live,
                  num_wins: response.data.num_wins,
                  total_spent: response.data.total_spent,
                  total_gains: response.data.total_gains,
                  user_rank: response.data.user_rank,
                  rank_total_users: response.data.rank_total_users,
                  recent_bets: response.data.recent_bets,
                });
                setQuarter(live_play_situation?.quarter)
              }
            } else {
              setGameStatus({
                user: response.data.user,
                bets_live: response.data.bets_live,
                recent_bets: response.data.recent_bets,
                total_spent: response.data.total_spent,
                total_gains: response.data.total_gains,
                user_rank: response.data.user_rank,
                rank_total_users: response.data.rank_total_users,
              });
            }
            // console.log('response.data.recent_bets = ', response.data.recent_bets)
            // console.log('response.data.bets_live = ', response.data.bets_live)
            if (response.data.recent_bets) {
              fetchBetsData(response.data.recent_bets, response.data.bets_live);
            }
          })
          .catch((error) => {
            console.log("Error getting the game status:: ", error);
            // setTimeout(function() {
            //   fetchGameStatusData(t)
            // }, 1500)
          });
    }
  };

  const getGameStatusInfo = (tournamentParsed) => {
    fetchGameStatusData(tournamentParsed);
    interval.current = setInterval(() => {
      // console.log('going to call fetchGameStatusData')
      fetchGameStatusData(tournamentParsed);
    }, 1500);
  };

  useEffect(() => {
    // let game = localStorage.getItem("gameNfl");
    // // console.log('gameNfl useEffect = ', game)
    // let gameJSON = JSON.parse(game)
    // setNflGame(gameJSON)

    // getGameStatusInfo();

    return function cleanup() {
      // if (interval && interval.current)
      //   clearInterval(interval.current);
    }
  }, [token]);

  useEffect(() => {
    if (showBets) {
      setBetMountClicked(false);
      setOddsClicked(false);
      fetchData(gameId);
    }
  }, [showBets]);

  const renderBetButton = (item) => (
      <Grid item xs={6} className={classes.item}>
        <BetButton
            disable={String(item.id !== player.id)}
            key={item}
            setRunPlayers={setRunPlayers}
            setPassPlayers={setPassPlayers}
            setPositionTargets={setPositionTargets}
            setPlayerTargets={setPlayerTargets}
            setPlayResults={setPlayResults}
            onClick={() => {
              // console.log('onClick, item = ', item)
              setSelectedBet(item.odds);
              setSelectedButton(item);
              setOddsClicked(true);
              if (selectedButton.id === item.id && oddsClicked) {
                setActiveShow(false);
                setSelectedButton(0);
              } else {
                setActiveShow(true);
              }
            }}
            item={item}
            className={
              (item.id === selectedButton.id && showBets && activeShow) ||
              (item.id === selectedButton.id && oddsClicked && activeShow)
                  ? classes.activeButton
                  : classes.button
            }
            setType={setPlayer}
            type="small"
            badgeContent={item.odds.toFixed(1)+"x"}
            content={item.name}
            setShowBets={setShowBets}
            setOddsClicked={setOddsClicked}
            spanClassName={
              (item.id === selectedButton.id && showBets && activeShow) ||
              (item.id === selectedButton.id && oddsClicked && activeShow)
                  ? classes.activeBtnSpan
                  : classes.btnSpan
            }
        />
      </Grid>
  )

  let body = (
    <Grid
      direction="row"
      container
      spacing={2}
      className={classes.grid}
      style={{
        maxHeight:
          type.name === "Run play" || type.name === "Pass play"
            ? "40vh"
            : "50vh",
        overflow: "auto",
      }}
      wrap="wrap"
    >
      <Grid item xs={12} className={classes.containerPlayWrap}>
        <Grid direction="row" container className={classes.containerPlay}>
          {(type.name === "Run play" || type.name === "Player target") && (
            <Grid item>
              <RadioButtonCheckedIcon
                style={{
                  paddingLeft: "6.31",
                  paddingRight: "11.31",
                  color: "#FDC934",
                  width: "11.38px",
                  height: "11.38px",
                }}
              />
            </Grid>
          )}
          {type.name === "Pass play" && (
            <Grid item>
              <CallSplitIcon
                style={{
                  paddingLeft: "6.31",
                  paddingRight: "11.31",
                  color: "#FDC934",
                  width: "11.38px",
                  height: "11.38px",
                }}
              />
            </Grid>
          )}
          {type.name === "Position target" && (
            <Grid item>
              <CompassCalibrationIcon
                style={{
                  paddingLeft: "6.31",
                  paddingRight: "11.31",
                  color: "#FDC934",
                  width: "11.38px",
                  height: "11.38px",
                }}
              />
            </Grid>
          )}
          {type.name === "Play Result" && (
            <Grid item>
              <FlagIcon
                style={{
                  paddingLeft: "6.31",
                  paddingRight: "11.31",
                  color: "#FDC934",
                  width: "11.38px",
                  height: "11.38px",
                }}
              />
            </Grid>
          )}
          {(type.name === "Run play" || type.name === "Pass play") && (
            <Grid item>
              {" "}
              <Typography className={classes.playerTarget}>
                ADD PLAYER TARGET
              </Typography>{" "}
            </Grid>
          )}
          {type.name === "Position target" && (
            <Grid item>
              {" "}
              <Typography className={classes.playerTargetSec}>
                Position target
              </Typography>{" "}
            </Grid>
          )}
          {type.name === "Player target" && (
            <Grid item>
              {" "}
              <Typography className={classes.playerTargetSec}>
                Player target
              </Typography>{" "}
            </Grid>
          )}
          {type.name === "Play Result" && (
            <Grid item>
              {" "}
              <Typography className={classes.playerTargetSec}>
                Play Result
              </Typography>{" "}
            </Grid>
          )}
        </Grid>
      </Grid>
      {type.name === "Run play" && runPlayers && runPlayers.map(renderBetButton)}
      {type.name === "Pass play" && passPlayers && passPlayers.map(renderBetButton)}
      {type.name === "Position target" && positionTargets && positionTargets.map(renderBetButton)}
      {type.name === "Player target" && playerTargets && playerTargets.map(renderBetButton)}
      {type.name === "Play Result" && playResults && playResults.map(renderBetButton)}
    </Grid>
  );

  // console.log('newPlays = ', newPlays)
  // console.log('gameStatus = ', gameStatus)
  // console.log('Game.jsx recent bets = ', gameStatus?.recent_bets)
  // console.log('Game.jsx recent bets = ', recentBets)

  return (
    <Box className={classes.body}>
      <MatchHeader
        gsData={gameStatus}
        game={{
          home_team: tournament?.home,
          away_team: tournament?.away,
          startDate: tournament?.startDate && utcDateStrToDate(tournament.startDate)
          // offense_team: 'ATL',
          // defense_team: 'NE',
        }}
        quarter={quarter}
        token={token}
        setShowBets={setShowBets}
        showBets={showBets}
        setRefresh={setRefresh}
        gameType={'NFL'}
        tournament={tournament}
      />
      {betsPaused ? (   //for development, pause screen only show when NFL game is selected
          <div>
            <PauseScreen gameStatus={gameStatus} />
            <Grid
                className={
                  showBetWonAlert ? classes.fadeIn : classes.fadeOut
                }
            >
              <Grid item className={classes.potentialReturnContainer}>
                <BetWonAlert
                    visible={showBetWonAlert}
                    winAmount={winAmount}
                    setShowBetWonAlert={setShowBetWonAlert}
                    recentBets={recentBets}
                    seeMoreClicked={seeMoreClicked}
                    setSeeMoreClicked={setSeeMoreClicked}
                    duration={seeMoreClicked ? null : 3000}
                />
              </Grid>
            </Grid>
          </div>
      ) : (
        <div>
          <Fade bottom>
            <Grid container spacing={2} className={classes.game}>
              {showBets && (
                <BetList
                  gameStatus={gameStatus}
                  gameId={gameId}
                  newPlays={newPlays}
                  newPossesionOptions={newPossesionOptions}
                  setType={setType}
                  setShowBets={setShowBets}
                  setRunPlayers={setRunPlayers}
                  setPassPlayers={setPassPlayers}
                  setPlayerTargets={setPlayerTargets}
                  setPositionTargets={setPositionTargets}
                  setPlayResults={setPlayResults}
                  getRunPassData={getRunPassData}
                  getNbaData={getNbaData}
                  setOddsClicked={setOddsClicked}
                  gameType={'NFL'}
                  tournament={tournament}
                />
              )}
              {/* here */}
              {!showBets &&
                (type.name === "Run play") && (
                  <Grid item xs={12}>
                    <BetButton
                      type="big"
                      content={type.name}
                      icon={type.icon}
                      setShowBets={setShowBets}
                      className={classes.activeButton}
                      badgeContent={newPlays[0].odds.split(" - ")[0]}
                      spanClassName={classes.activeBtnSpan}
                    />
                  </Grid>
                ) ||
               (!showBets && (type.name === "Pass play")) && (
                  <Grid item xs={12}>
                    <BetButton
                      type="big"
                      content={type.name}
                      icon={type.icon}
                      setShowBets={setShowBets}
                      className={classes.activeButton}
                      badgeContent={ newPlays[1].odds.split(" - ")[0]}
                      spanClassName={classes.activeBtnSpan}
                    />
                  </Grid>
                )
                }
            </Grid>
          </Fade>
          <Fade bottom when={!showBets}>
            {!showBets && body}
          </Fade>
          {!showBetErrorAlert && <BetBar
            gsData={gameStatus}
            gameType={'NFL'}
            hide={showBets}
            selectedButton = {selectedButton}
            tgt={selectedButton.name}
            setShowBets={setShowBets}
            type={type}
            odds={selectedBet}
            token={token}
            rpk={(type.name.split(/[ ]+/) && type.name.split(/[ ]+/).length > 0 && type.name.split(/[ ]+/)[0].toUpperCase()) || type}
            setBetMountClicked={setBetMountClicked}
            betMountClicked={betMountClicked}
            activeShow={activeShow}
            setBetAmount={setBetAmount}
            setShowPotentialReturn={setShowPotentialReturn}
            combineBet={combineBet}
            setConbineBet={setConbineBet}
            tournament={tournament}
            setBetErrorMsg={setBetErrorMsg}
            showBetErrorAlert={onDoShowBetErrorAlert}
          />}

          <Grid
              className={
                showBetErrorAlert ? classes.fadeIn : classes.fadeOut
              }
          >
            <Grid item className={classes.potentialReturnContainer}>
              <BetErrorAlert
                  visible={showBetErrorAlert}
                  betErrorMsg={betErrorMsg}
                  setShowBetErrorAlert={setShowBetErrorAlert}
                  duration={4000}
              />
            </Grid>
          </Grid>

          <Grid
            className={
              showPotentialReturn && showBets ? classes.fadeIn : classes.fadeOut
            }
          >
            <Grid item className={classes.potentialReturnContainer}>
              <PotentialReturnAlert
                visible={showPotentialReturn && showBets}
                betAmount={betAmount}
                setShowPotentialReturn={setShowPotentialReturn}
                duration={4000}
                tournament={tournament}
              />
            </Grid>
          </Grid>

          <Grid
            className={
              showBetWonAlert ? classes.fadeIn : classes.fadeOut
            }
          >
            <Grid item className={classes.potentialReturnContainer}>
              <BetWonAlert
                visible={showBetWonAlert}
                winAmount={winAmount}
                setShowBetWonAlert={setShowBetWonAlert}
                recentBets={recentBets}
                seeMoreClicked={seeMoreClicked}
                setSeeMoreClicked={setSeeMoreClicked}
                duration={seeMoreClicked ? null : 3000}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </Box>
  );
}
export default Game;
