import {Grid, makeStyles, Typography} from "@material-ui/core";
import React, { useEffect, useState, useRef} from "react";
import {convertProbToOdds} from "../../../utils/probabilityUtils"
import BetButton from "../../ui/BetButton";

import { useStyles } from "../styles";
import constants from "../../../constants";
import {getNbaGameProbabilities} from "../../../hooks/useNbaProbs";
import TwoPoint from "../../icons/2-point.png";
import ThreePoint from "../../icons/3-point.png";
import FlagIcon from "@material-ui/icons/Flag";
import TrophyIcon from '@material-ui/icons/EmojiEvents';
import CopyrightIcon from "@material-ui/icons/Copyright";

const DEFAULT_OPTIONS_STATE = [
  { icon: <img alt={"2-point-shot"} className="MuiSvgIcon-root" src={TwoPoint}  style={{fontSize: "14px"}} />, name: "2-point shot", odds: "LOADING", type:"nextPossession"},
  { icon: <img alt={"3-point-shot"} className="MuiSvgIcon-root" src={ThreePoint} style={{fontSize: "14px"}} />, name: "3-point shot", odds: "LOADING", type:"nextPossession"},
  // { icon: <img alt={"Player-shot"} className="MuiSvgIcon-root" src={TShirt} style={{fontSize: "14px"}} />, name: "Player shot", odds: "0.0x - 0.0x", type:"nextPossession"},
  // { icon: <CompassCalibrationIcon style={{color: "#FDC934", fontSize: "20px"}} />, name: "Shooting zone", odds: "0.0x - 0.0x", type:"nextPossession"},
  { icon: <FlagIcon style={{color: "#FDC934", fontSize: "20px"}} />, name: "Possession result", odds: "LOADING", type:"nextPossession"}
];

// let nbaTeam = null

const useTournRowStyles = makeStyles((theme) => ({
  inTournStatsRow: {
    float: 'left', width: '100%', flexDirection: 'row', marginBottom: 12
  },
  inTournStatsRowCell: {
    float: 'left', width: '25%'
  },
  inTournStatsRowLabel: {
    float: 'left', width: '100%', height: 16, lineHeight: '16px', fontSize: 14, fontFamily: 'Kanit',
    marginBottom: 8
  },
  inTournStatsRowValue: {
    // backgroundColor: 'black',
    float: 'left',
    width: '100%',
    height: 16,
    lineHeight: '16px',
    fontSize: 14,
    fontFamily: 'Kanit', marginBottom: 6, color: '#fff'
  },
  coinValWrap: {
    // margin: 'auto',
    width: '100%',
    height: 16,
    lineHeight: '16px',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'center'
  },
  inTournStatsRowValueCoin: {
    height: 16,
    lineHeight: '16px',
    fontSize: 14,
    fontFamily: 'Kanit',
    color: '#fff',
    // flexDirection: 'row',
  },
  inTournStatCoin: {
    height: 14,
    color: '#fdc934',
    marginTop: 1
  }
}))


export default function BetList({
  newPlays,
  setType,
  setShowBets,
  getRunPassData,
  getNbaData,
  setOddsClicked,
  gameType,
  game,
  setNextPossesion,
  nextPossesion,
  setNextPossessionOptionClicked,
    setRunPlayers,
    setPassPlayers,
    setPositionTargets,
    setPlayerTargets,
    setPlayResults,
    gameStatus,
    onNbaTeamPress,
    nbaTeam,
  gameId,
  tournament
}) {
  // console.log('newPossesionOptions[0].odds = ' + (newPossesionOptions && newPossesionOptions.length > 0 && newPossesionOptions[0] && newPossesionOptions[0].odds))
  // console.log('newPlays = ', newPlays)
  // console.log('game = ', game)
  // console.log('gameStatus = ', gameStatus)
  // console.log('gameId = ', gameId)
  // console.log('BetList.recent_bets = ', gameStatus?.recent_bets)

  const getDefaultOptionsState = () => {
    return DEFAULT_OPTIONS_STATE.slice()
  }
  const classes = useStyles();
  const tournRowStyles = useTournRowStyles();
  const [playOptionTitle, setPlayOptionTitle] = useState("");
  const [nextPossesionClicked, setNextPossesionClicked] = useState(false);
  // const [titleImgSrc, setTitleImgSrc] = useState("");
  // const [nbaTeam, setNbaTeam] = useState(null);
  const [nbaDataResponse, setNbaDataResponse] = useState([]);
  const [possessionOptions, setPossessionOptions] = useState(getDefaultOptionsState());
  let interval = useRef();

  function clearFetchProbabilitiesInterval() {
    if (interval && interval.current) {
      // console.log('BetList Clearing Interval')
      clearInterval(interval.current)
    }
  }

  // const nbaPlaysOptionsFirstGame = [
  //   { icon: <img alt={"MIAMI-HEAT"} className={classes.teamLogo} src={netsLogo} />, name: "Next possession"},
  //   { icon: <img alt={"MILWAUKEE"}  className={classes.teamLogo} src={bucksLogo} />, name: "Next possession"}
  // ];
  //
  // const nbaPlaysOptionsSecondGame = [
  //   { icon: <img alt={"LA-LAKERS"}  className={classes.teamLogo} src={phoenixSunsLogo} />, name: "Next possession"},
  //   { icon: <img alt={"PHOENIX-SUNS"} style={{width: '50px', height: '31px'}} className={classes.teamLogo} src={laLakersLogo} />, name: "Next possession"}
  // ];

  useEffect(() => {
    // console.log('BetList init')
    const gameSelected = localStorage.getItem("gameSelected");
    // console.log('gameSelected = ', gameSelected)
    if (gameSelected === "NFL") {
      setPlayOptionTitle("SELECT CALL")
    } else {
      // if(gameSelected === "NBA-MiamiVsMilwaukee" || gameSelected === "NBA-PhoenixVsLALakers") {
      //   setPlayOptionTitle("SELECT TEAM")
      // }
      let team = localStorage.getItem("team")
      // console.log('useEffect ROOT, team = ', team)
      if (team && team !== 'null') {
        // console.log("---------- WE HERE-------------- ", team)
        // setNbaTeam(team)
        setPlayOptionTitle('NEXT POSSESSION ' + team.toUpperCase());
        setNextPossesion(true)
      } else {
        // console.log("---------- YOU HERE--------------")
        setPlayOptionTitle("SELECT TEAM")
      }
    }
    return function cleanup() {
      // don't do this here as a user can unload this view
      // when going to betting categories listing - instead
      // clear team when user goes back to Plays.jsx
      // localStorage.setItem("team", null)

      clearFetchProbabilitiesInterval()
    }
  }, [])

  useEffect(() => {
    // console.log('nbaTeam = ', nbaTeam)
    clearFetchProbabilitiesInterval()
    if (nbaTeam && nbaTeam !== 'null') {
      setPlayOptionTitle('NEXT POSSESSION ' + nbaTeam.toUpperCase());
      // console.log('calling loopNBAGameProbabilities1');
      loopNBAGameProbabilities(nbaTeam);
    } else {
      // console.log('Hello')
      if (gameType == 'NFL')
        setPlayOptionTitle('SELECT CALL');
      else
        setPlayOptionTitle('SELECT TEAM');
    }

    return function cleanup() {
      resetNbaData()
      clearFetchProbabilitiesInterval()
    }
  }, [nbaTeam])

  // useEffect(() => {
  //   if (!nextPossesionClicked)
  //     console.log("!nextPossesionClicked")
  // }, [nextPossesionClicked])

  useEffect(() => {
    setNextPossesionClicked(nextPossesion);
    // console.log('BetList useEffect, nextPossesion = ', nextPossesion)
    // console.log('BetList useEffect, nextPossessionOptionClicked = ', nextPossessionOptionClicked)
    //  if (nextPossesion && !nextPossessionOptionClicked) {
    //    teamChangeCleanup()
    //   let team = localStorage.getItem("team");
    //    console.log('calling loopNBAGameProbabilities2')
    //   loopNBAGameProbabilities(team);
    //
    //    return function cleanup() { // emulating componentWillUnload
    //      // console.log('BetList cleanup!')
    //      // resetNbaData()
    //      //
    //      // teamChangeCleanup()
    //      // // if (interval && interval.current) {
    //      // //   console.log('BetList clear interval')
    //      // //   clearInterval(interval.current)
    //      // // }
    //      // //
    //      // // // if (resetBetData)
    //      // // //   resetBetData()
    //    }
    // } else if (!nextPossesion) {
    //    // console.log('!nextPossession')
    //    let team = localStorage.getItem("team");
    //    if (!team) {
    //      setPlayOptionTitle("SELECT TEAM");
    //      teamChangeCleanup()
    //    }
    // }

  }, [nextPossesion])

  const resetNbaData = () => {
    // console.log('BetList resetNbaData')
    // setPossessionOptions(getDefaultOptionsState())
    // setNbaTeam(null)
    // localStorage.setItem('nbaDataResponse', null)
  }

  const loopNBAGameProbabilities = async (team) => {
    // setSelectedTeam(team)
    // setNbaTeam(team)
    await fetchNbaData(team);
    interval.current = setInterval(async () => {
      await fetchNbaData(team);
    }, 2000);
  };

  const setMainPosibility = (items) => {
    if (items?.length > 0) {
      let possesionOptions = possessionOptions;
      items.forEach(element => {
        var foundIndex = possesionOptions.findIndex(x => x.name === element.name);
        possesionOptions[foundIndex] = element;
      });
      // console.log('possesionOptions[0].odds = ' + possesionOptions[0].odds)
      setPossessionOptions(possesionOptions)
    }
    // console.log('BetList odds = ' + possesionOptions[0].odds)
  };

  const mappMainProbability =  (probabilityResponse) => {
    // console.log('mappMainProbability')
    let twoPtShot = [];
    let treePointShot = [];
    let playResultCells = [];
    let plays = [];

    for (const property in probabilityResponse) {
      switch(property) {
        case 'two_pt_fg_cells':
          twoPtShot.push(probabilityResponse["two_pt_fg_cells"]);
          if(twoPtShot.length > 0) {
            twoPtShot[0].forEach((item) => {
              let prob = Number(item.percent / 100).toFixed(2);
              // let odds = ((1 - prob) / prob).toFixed(1);
              let odds = convertProbToOdds(prob)
              item["odds"] = parseFloat(odds);
            })
            let max = probabilityResponse["two_pt_fg_cells"].reduce((a, b) => a.odds > b.odds ? a : b, 0);
            let min = probabilityResponse["two_pt_fg_cells"].reduce((a, b) => a.odds < b.odds ? a : b, 0);
            if(max === 0) {
              max = {odds: 0 }
            }
            if(min === 0) {
              min = {odds: 0 }
            }
            plays.push({
              icon: <img alt={"2-point-shot"} className="MuiSvgIcon-root" src={TwoPoint}  style={{fontSize: "14px"}} />,
              name: "2-point shot",
              odds: min.odds.toFixed(1) + "x - " + max.odds.toFixed(1) + "x",
              type:"nextPossession"
            });
          }
          break;
        case 'three_pt_fg_cells':
          treePointShot.push(probabilityResponse["three_pt_fg_cells"]);
          if(treePointShot.length > 0) {
            treePointShot[0].forEach((item) => {
              let prob = Number(item.percent / 100).toFixed(2);
              // let odds = ((1 - prob) / prob).toFixed(1);
              let odds = convertProbToOdds(prob)
              item["odds"] = parseFloat(odds);
            })
            let max = probabilityResponse["three_pt_fg_cells"].reduce((a, b) => a.odds > b.odds ? a : b, 0);
            let min = probabilityResponse["three_pt_fg_cells"].reduce((a, b) => a.odds < b.odds ? a : b, 0);
            if(max === 0) {
              max = {odds: 0 }
            }
            if(min === 0) {
              min = {odds: 0 }
            }
            plays.push({
              icon: <img alt={"3-point-shot"} className="MuiSvgIcon-root" src={ThreePoint} style={{fontSize: "14px"}} />,
              name: "3-point shot",
              odds: min.odds.toFixed(1) + "x - " + max.odds.toFixed(1) + "x",
              type:"nextPossession"
            });
          }
          break;
        case 'play_result_cells':
          playResultCells.push(probabilityResponse["play_result_cells"]);
          if(playResultCells.length > 0) {
            playResultCells[0].forEach((item) => {
              let prob = Number(item.percent / 100).toFixed(2);
              // let odds = ((1 - prob) / prob).toFixed(1);
              let odds = convertProbToOdds(prob)
              item["odds"] = parseFloat(odds);
            })
            const play_result_cells = probabilityResponse["play_result_cells"]
            let max = play_result_cells.reduce((a, b) => a.odds > b.odds ? a : b, 0);
            let min = play_result_cells.reduce((a, b) => a.odds < b.odds ? a : b, 0);
            if(max === 0) {
              max = {odds: 0 }
            }
            if(min === 0) {
              min = {odds: 0 }
            }
            let odds_range_string
            if (play_result_cells.length > 1) {
              odds_range_string = min.odds.toFixed(1) + "x - " + max.odds.toFixed(1) + "x"
            } else {
              odds_range_string = max.odds.toFixed(1) + "x"
            }
            // console.log('probabilityResponse["play_result_cells"] = ', probabilityResponse["play_result_cells"])
            plays.push({
              icon: <FlagIcon style={{color: "#FDC934", fontSize: "20px"}} />,
              name: "Possession result",
              odds: odds_range_string,
              type:"nextPossession"
            })
          }
          break;
        default:
          break;
      }
    }
    setMainPosibility(plays);
  }

  const fetchNbaData = async (team) => {
    // console.log('BetList fetchNbaData = ', team)
    let dataResponse = [];
    // dataResponse = await getNbaGameProbabilities();
    // console.log('nbaTeam = ' + nbaTeam)
    // console.log('team = ' + team)
    if (nbaTeam == null || team !== nbaTeam) {
      return
    }
    dataResponse = await getNbaGameProbabilities(team);
    // console.log('about to call mappMainProbability')
    mappMainProbability(dataResponse.data);
    setNbaDataResponse(dataResponse.data)

    localStorage.setItem('nbaDataResponse', JSON.stringify(dataResponse.data))
  }

  const mapTeamForFetchData = (srcPath) => {
    // console.log('srcPath = ', srcPath)
    // if(srcPath.includes("Bucks")) {
    //   localStorage.setItem("team", 'mil');
    //   return 'mil'
    // }
    // else if (srcPath.includes("nets")) {
    //   localStorage.setItem("team", 'bkn');
    //   return 'bkn'
    // }
    // else if(srcPath.includes("Los-angeles-lakers")) {
    //   localStorage.setItem("team", 'lal');
    //   return 'lal';
    // }
    // else if (srcPath.includes("Phoenix-Suns")) {
    //   localStorage.setItem("team", 'phx');
    //   return 'phx';
    // }
    // else
    // return '';

    // localStorage.setItem("team", 'atl');
    // return 'atl'

    // localStorage.setItem("team", 'uta');
    // return 'uta'

    localStorage.setItem('nbaDataResponse', null)

    if(srcPath.includes("Bucks")) {
      console.log('Selected Bucks')
      localStorage.setItem("team", 'mil');
      return 'mil'
    }
    else if(srcPath.includes("clippers")) {
      console.log('Selected Clippers')
      localStorage.setItem("team", 'lac');
      return 'lac'
    }
    else if(srcPath.includes("Suns")) {
      console.log('Selected Suns')
      localStorage.setItem("team", 'phx');
      return 'phx'
    }

    console.log('Selected Atlanta')
    localStorage.setItem("team", 'atl');
    return 'atl'
  }

  const handleNbaGame = (item) => {
    // // let team = mapTeamForFetchData(item.icon.props.text);
    // console.log('ittem = ', item)
    // let team = item.text.toLowerCase()
    // localStorage.setItem("team", team)
    //
    // if (interval && interval.current)
    //   clearInterval(interval.current)
    //
    // if (resetBetData)
    //   resetBetData()
    //
    // setNbaTeam(team);
    // // setTitleImgSrc(item.icon?.props?.src);
    // setNextPossesionClicked(true);
    // setNextPossClicked(true);
    // setPlayOptionTitle('NEXT POSSESSION ' + team.toUpperCase());
    // setNextPossesion(true);

    // let team = mapTeamForFetchData(item.icon.props.text);
    // console.log('ittem = ', item)
    let team = item.text.toLowerCase()

    onNbaTeamPress(team)

    if (interval && interval.current)
      clearInterval(interval.current)

    // setNbaTeam(team);
    // setTitleImgSrc(item.icon?.props?.src);
    setNextPossesionClicked(true);
    setPlayOptionTitle('NEXT POSSESSION ' + team.toUpperCase());
  }

  const renderTournamentHeader = () => {
    if (!gameStatus)
    // if (true)
      return null

    // console.log('tourn header: ', gameStatus)
    const is_most_bets_won_scoring = (tournament?.score_system === 'most_bets_won')

    return (<div style={{float: 'left',width: '100%', marginBottom: 12}}>
      <div style={{float: 'left', width: '100%', height: 30, flexDirection: 'row', marginBottom: 10}}>
        <TrophyIcon style={{float: 'left', height: 20, color: '#fdc934', marginTop: 5}} />
        <Typography style={{float: 'left', fontSize: 16, color: '#fff', fontFamily: 'Kanit', marginLeft: 10, lineHeight: '30px'}}>
          {tournament?.sport === constants.SPORT_FOOTBALL ? 'Pro Football Tournament' : 'Pro Basketball Tournament'}
        </Typography>
      </div>
      <div className={tournRowStyles.inTournStatsRow}>
        <div className={tournRowStyles.inTournStatsRowCell} style={is_most_bets_won_scoring ? {width: '33%'} : {}}>
          <Typography className={tournRowStyles.inTournStatsRowLabel}>
            {is_most_bets_won_scoring ? 'Calls Placed' : 'Total Spent'}
          </Typography>
          <div className={tournRowStyles.inTournStatsRowValue}>
            <div className={tournRowStyles.coinValWrap}>
              <Typography className={tournRowStyles.inTournStatsRowValueCoin}>
                {gameStatus.total_spent || 0}
              </Typography>
              {!is_most_bets_won_scoring && <CopyrightIcon className={tournRowStyles.inTournStatCoin}/>}
            </div>
          </div>
        </div>
        <div className={tournRowStyles.inTournStatsRowCell} style={is_most_bets_won_scoring ? {width: '33%'} : {}}>
          <Typography className={tournRowStyles.inTournStatsRowLabel}>
            Calls Won
          </Typography>
          <Typography className={tournRowStyles.inTournStatsRowValue}>
            {gameStatus.num_wins || 0}
          </Typography>
        </div>
        {!is_most_bets_won_scoring && <div className={tournRowStyles.inTournStatsRowCell}>
          <Typography className={tournRowStyles.inTournStatsRowLabel}>
            Total Gains
          </Typography>
          <div className={tournRowStyles.inTournStatsRowValue}>
            <div className={tournRowStyles.coinValWrap}>
              <div className={tournRowStyles.inTournStatsRowValueCoin}>
                {gameStatus.total_gains || 0}
              </div>
              <CopyrightIcon className={tournRowStyles.inTournStatCoin}/>
            </div>
          </div>
        </div>}
        <div className={tournRowStyles.inTournStatsRowCell} style={is_most_bets_won_scoring ? {width: '33%'} : {}}>
          <Typography className={tournRowStyles.inTournStatsRowLabel}>
            Rank
          </Typography>
          <Typography className={tournRowStyles.inTournStatsRowValue} style={{fontWeight: 500}}>
            {gameStatus.user_rank} / {gameStatus.rank_total_users}
          </Typography>
        </div>
      </div>
      <div className="primline" style={{float: 'left', height: 1, width:'100%'}}></div>
    </div>)
  }

  const getNbaPossBtnLabel = (item) => {
    return item.name + ' (' + item.teamTricode + ')'
  }

  let nbaAwayStr, nbaHomeStr, nbaAwayLogo, nbaHomeLogo
  const nba_choices_arr = []
  if (gameType === 'NBA') {
    // const gameTypeArr = gameType.split('-')
    // // nbaAwayStr = 'PHI'
    // // nbaHomeStr = 'ATL'
    // nbaAwayStr = gameTypeArr[0]
    // nbaHomeStr = gameTypeArr[1]
    nbaAwayStr = game['away']
    nbaHomeStr = game['home']

    // if (nbaAwayStr === 'MIL') {
    //   nbaAwayLogo = bucksLogo
    // } else if (nbaAwayStr === 'ATL') {
    //   nbaAwayLogo = hawksLogo
    // } else if (nbaAwayStr === 'PHX') {
    //   nbaAwayLogo = phoenixSunsLogo
    // } else if (nbaAwayStr === 'LAC') {
    //   nbaAwayLogo = clippersLogo
    // }
    //
    // if (nbaHomeStr === 'MIL') {
    //   nbaHomeLogo = bucksLogo
    // } else if (nbaHomeStr === 'ATL') {
    //   nbaHomeLogo = hawksLogo
    // } else if (nbaHomeStr === 'PHX') {
    //   nbaHomeLogo = phoenixSunsLogo
    // } else if (nbaHomeStr === 'LAC') {
    //   nbaHomeLogo = clippersLogo
    // }

    let awayIcon = null, homeIcon = null

    if (nbaAwayLogo)
      awayIcon = <img alt={nbaAwayStr} className={classes.teamLogo} src={nbaAwayLogo} />

    if (nbaAwayStr)
      nba_choices_arr.push({
        text: nbaAwayStr,
        teamTricode: nbaAwayStr,
        icon: awayIcon,
        name: "Next possession"
      })

    if (nbaHomeLogo)
      homeIcon = <img alt={nbaHomeStr} className={classes.teamLogo} src={nbaHomeLogo} />

    if (nbaHomeStr)
      nba_choices_arr.push({
        text: nbaHomeStr,
        teamTricode: nbaHomeStr,
        icon: homeIcon,
        name: "Next possession"
      })
  }

  // console.log('newPossesionOptions2 = ', newPossesionOptions)
  return (
    <>
      {!nextPossesion && renderTournamentHeader()}
      {/*titleImgSrc != null && typeof(titleImgSrc) !== 'undefined'*/playOptionTitle && <Grid item xs={12}>
        {/*<Typography className={classes.selectBetIcn}>{(titleImgSrc !== "" && nextPossesionClicked) ?<img alt={"CHICAGO-BULLS"} style={titleImgSrc.includes("Los-angeles-lakers")?{width: '30px', height: '19px'} : {}}className={classes.teamLogoTitle} src={titleImgSrc}/> : null}{playOptionTitle}</Typography>*/}
        <Typography className={classes.selectBetIcn}>{playOptionTitle}</Typography>
      </Grid>}
      {gameType === "NFL" && newPlays.map((item) => (
        <Grid item xs={12}>
          <BetButton
            className={classes.button}
            type="big"
            item={item}
            content={item.name}
            setType={setType}
            setShowBets={setShowBets}
            badgeContent={item.odds}
            getRunPassData={getRunPassData}
            setOddsClicked={setOddsClicked}
            setRunPlayers={setRunPlayers}
            setPassPlayers={setPassPlayers}
            setPositionTargets={setPositionTargets}
            setPlayerTargets={setPlayerTargets}
            setPlayResults={setPlayResults}
            team={gameStatus?.offense_team}
            gameId={gameId}
          />
        </Grid>
      ))}
       {gameType !== "NFL" && !nextPossesionClicked && nba_choices_arr.map((item) => (
        <Grid item xs={12}>
          <BetButton
            className={classes.button}
            type="big"
            item={item}
            content={getNbaPossBtnLabel(item)}
            setType={setType}
            onClick={(e) => {handleNbaGame(item)}}
          />
        </Grid>
      ))}

       {gameType !== 'NFL' && nextPossesionClicked && possessionOptions.map((item) => (
        <Grid item xs={12}>
          <BetButton
            className={classes.button}
            type="big"
            item={item}
            content={item.name}
            setType={setType}
            icon={item.icon}
            badgeContent={item.odds}
            setShowBets={setShowBets}
            getNbaData={getNbaData}
            setNextPossessionOptionClicked={setNextPossessionOptionClicked}
            gameType={gameType}
            team={gameStatus?.offense_team}
          />
        </Grid>
      ))}
    </>
  );
}
