import axios from "axios";
import constants from "../constants";

let leaderboardUri = constants.API_HOST + "/app/leaderboard";

export function getLeaderboardData(tournament_id) {
    let params = {}
    if (tournament_id)
        params.tournament_id = tournament_id
    return axios.get(leaderboardUri, {params});
}
