import axios from "axios";
import constants from "../constants";

export async function getGameProbabilities(game_id) {
  // console.log('Game_id = ', game_id)
  const response = await axios.get(
      constants.API_HOST + '/app/probabilities.json?game_id=' + game_id
  ).catch(reason => {
    console.log("Error getting probabilities: ", reason)
    // setTimeout(function() {
    //   getGameProbabilities(game_id)
    // }, 2000)
  });
  return response?.data;
}

export function getRunPassProbabilityData(uri, params) {
  return axios.get(uri, { params });
}
