import React, {useEffect, useRef, useState} from "react";
import {Button, Grid, Typography} from "@material-ui/core";
import { Link } from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from "react-router-dom";
import useWebSocket from "react-use-websocket";

import { useStyles } from "../styles";
import Jersey from "../../icons/jersey-transparent.png"
import AtlJersey from "../../icons/nba/ATL.png"
import BknJersey from "../../icons/nba/BKN.png"
import BosJersey from "../../icons/nba/BOS.png"
import ChaJersey from "../../icons/nba/CHA.png"
import ChiJersey from "../../icons/nba/CHI.png"
import CleJersey from "../../icons/nba/CLE.png"
import DalJersey from "../../icons/nba/DAL.png"
import DenJersey from "../../icons/nba/DEM.png"
import DetJersey from "../../icons/nba/DET.png"
import GswJersey from "../../icons/nba/GSW.png"
import HouJersey from "../../icons/nba/HOU.png"
import IndJersey from "../../icons/nba/IND.png"
import MemJersey from "../../icons/nba/MEM.png"
import LacJersey from "../../icons/nba/LAC.png"
import LalJersey from "../../icons/nba/LAL.png"
import MiaJersey from "../../icons/nba/MIA.png"
import MilJersey from "../../icons/nba/MIL.png"
import MinJersey from "../../icons/nba/MIN.png"
import NopJersey from "../../icons/nba/NOP.png"
import NykJersey from "../../icons/nba/NYK.png"
import OkcJersey from "../../icons/nba/OKC.png"
import OrlJersey from "../../icons/nba/ORL.png"
import PhiJersey from "../../icons/nba/PHI.png"
import PhxJersey from "../../icons/nba/PHX.png"
import PorJersey from "../../icons/nba/POR.png"
import SacJersey from "../../icons/nba/SAC.png"
import SasJersey from "../../icons/nba/SAS.png"
import TorJersey from "../../icons/nba/TOR.png"
import UtaJersey from "../../icons/nba/UTA.png"
import WasJersey from "../../icons/nba/WAS.png"

import TenAwayNfl from '../../icons/nfl/helmets/ten_away.png'
import BufHomeNfl from '../../icons/nfl/helmets/buf_home.png'
import MinAwayNfl from '../../icons/nfl/helmets/min_away.png'
import MinHomeNfl from '../../icons/nfl/helmets/min_home.png'
import PhiHomeNfl from '../../icons/nfl/helmets/phi_home.png'
import PitAwayNfl from '../../icons/nfl/helmets/pit_away.png'
import CleHomeNfl from '../../icons/nfl/helmets/cle_home.png'
import TenHomeNfl from '../../icons/nfl/helmets/ten_home.png'
import LvAwayNfl from '../../icons/nfl/helmets/lv_away.png'
import GbAwayNfl from '../../icons/nfl/helmets/gb_away.png'
import TbHomeNfl from '../../icons/nfl/helmets/tb_home.png'
import SfAwayNfl from '../../icons/nfl/helmets/sf_away.png'
import DenHomeNfl from '../../icons/nfl/helmets/den_home.png'
import DalAwayNfl from '../../icons/nfl/helmets/dal_away.png'
import DalHomeNfl from '../../icons/nfl/helmets/dal_home.png'
import NygAwayNfl from '../../icons/nfl/helmets/nyg_away.png'
import NygHomeNfl from '../../icons/nfl/helmets/nyg_home.png'
import MiaAwayNfl from '../../icons/nfl/helmets/mia_away.png'
import MiaHomeNfl from '../../icons/nfl/helmets/mia_home.png'
import CinHomeNfl from '../../icons/nfl/helmets/cin_home.png'
import AriAwayNfl from '../../icons/nfl/helmets/ari_away.png'
import AtlAwayNfl from '../../icons/nfl/helmets/atl_away.png'
import AtlHomeNfl from '../../icons/nfl/helmets/atl_home.png'
import CarHomeNfl from '../../icons/nfl/helmets/car_home.png'
import ChiAwayNfl from '../../icons/nfl/helmets/chi_away.png'
import CleAwayNfl from '../../icons/nfl/helmets/cle_away.png'
import DenAwayNfl from '../../icons/nfl/helmets/den_away.png'
import GbHomeNfl from '../../icons/nfl/helmets/gb_home.png'
import LvHomeNfl from '../../icons/nfl/helmets/lv_home.png'
import NeAwayNfl from '../../icons/nfl/helmets/ne_away.png'
import NeHomeNfl from '../../icons/nfl/helmets/ne_home.png'
import NoHomeNfl from '../../icons/nfl/helmets/no_home.png'
import NyjAwayNfl from '../../icons/nfl/helmets/nyj_away.png'
import NyjHomeNfl from '../../icons/nfl/helmets/nyj_home.png'
import PitHomeNfl from '../../icons/nfl/helmets/pit_home.png'
import KcAwayNfl from '../../icons/nfl/helmets/kc_away.png'
import KcHomeNfl from '../../icons/nfl/helmets/kc_home.png'
import IndAwayNfl from '../../icons/nfl/helmets/ind_away.png'
import SfHomeNfl from '../../icons/nfl/helmets/sf_home.png'
import LaAwayNfl from '../../icons/nfl/helmets/la_away.png'
import LaHomeNfl from '../../icons/nfl/helmets/la_home.png'
import LacAwayNfl from '../../icons/nfl/helmets/lac_away.png'
import CinAwayNfl from '../../icons/nfl/helmets/cin_away.png'
import BalAwayNfl from '../../icons/nfl/helmets/bal_away.png'
import BalHomeNfl from '../../icons/nfl/helmets/bal_home.png'
import IndHomeNfl from '../../icons/nfl/helmets/ind_home.png'
import JaxAwayNfl from '../../icons/nfl/helmets/jax_away.png'
import TbAwayNfl from '../../icons/nfl/helmets/tb_away.png'
import CarAwayNfl from '../../icons/nfl/helmets/car_away.png'
import SeaAwayNfl from '../../icons/nfl/helmets/sea_away.png'
import SeaHomeNfl from '../../icons/nfl/helmets/sea_home.png'
import BufAwayNfl from '../../icons/nfl/helmets/buf_away.png'
import LacHomeNfl from '../../icons/nfl/helmets/lac_home.png'
import ChiHomeNfl from '../../icons/nfl/helmets/chi_home.png'
import WasAwayNfl from '../../icons/nfl/helmets/was_away.png'
import WasHomeNfl from '../../icons/nfl/helmets/was_home.png'
import AriHomeNfl from '../../icons/nfl/helmets/ari_home.png'
import NoAwayNfl from '../../icons/nfl/helmets/no_away.png'
import PhiAwayNfl from '../../icons/nfl/helmets/phi_away.png'
import HouHomeNfl from '../../icons/nfl/helmets/hou_home.png'
import DetAwayNfl from '../../icons/nfl/helmets/det_away.png'
import DetHomeNfl from '../../icons/nfl/helmets/det_home.png'

import './Game.css';
// import { fetchData } from "../../../service/getPlaysOdds";
import {getLiveGames} from "../../../hooks/nbaListings";
import CopyrightIcon from "@material-ui/icons/Copyright";
import {formatStartDate, utcDateStrToDate} from "../../../utils/dateUtils";
import constants from "../../../constants";
import querystring from "querystring";

// const SOCKET_URL = `ws://${constants.API_DOMAIN}/ws/chat/test4/`

const getJerseyForNflTeam = (team, is_home) => {
  // if (team) {
  //   const path = "../../icons/nfl/helmets/" + team.toLowerCase() + "_" + (is_home ? 'home' : 'away') + ".png"
  //   console.log(path)
  //   return require(path);
  // }
  // return null
  if (team === 'ARI') {
    if (is_home)
      return AriHomeNfl
    return AriAwayNfl
  } else if (team === 'ATL') {
    if (is_home)
      return AtlHomeNfl
    return AtlAwayNfl
  } else if (team === 'BAL') {
    if (is_home)
      return BalHomeNfl
    return BalAwayNfl
  } else if (team === 'BUF') {
    if (is_home)
      return BufHomeNfl;
    return BufAwayNfl
  } else if (team === 'CAR') {
    if (is_home)
      return CarHomeNfl
    return CarAwayNfl
  } else if (team === 'CHI') {
    if (is_home)
      return ChiHomeNfl
    return ChiAwayNfl
  } else if (team === 'CIN') {
    if (is_home)
      return CinHomeNfl;
    return CinAwayNfl
  } else if (team === 'CLE') {
    if (is_home) {
      return CleHomeNfl;
    }
    return CleAwayNfl;
  } else if (team === 'DAL') {
    if (is_home)
      return DalHomeNfl
    return DalAwayNfl;
  } else if (team === 'DEN') {
    if (is_home)
      return DenHomeNfl;
    return DenAwayNfl;
  } else if (team === 'DET') {
    if (is_home)
      return DetHomeNfl;
    return DetAwayNfl
  } else if (team === 'GB') {
    if (is_home)
      return GbHomeNfl;
    return GbAwayNfl;
  } else if (team === 'IND') {
    if (is_home)
      return IndHomeNfl;
    return IndAwayNfl;
  } else if (team === 'HOU') {
    // if (is_home)
      return HouHomeNfl;
    // return HouAwayNfl;
  } else if (team === 'JAX') {
    // if (is_home)
    //   return IndHomeNfl;
    return JaxAwayNfl;
  } else if (team === 'KC') {
    if (is_home)
      return KcHomeNfl;
    return KcAwayNfl;
  } else if (team === 'LA' || team === 'LAR') {
    if (is_home)
      return LaHomeNfl;
    return LaAwayNfl;
  } else if (team === 'LAC') {
    if (is_home)
      return LacHomeNfl;
    return LacAwayNfl;
  } else if (team === 'LV') {
    if (is_home)
      return LvHomeNfl;
    return LvAwayNfl;
  } else if (team === 'MIA') {
    if (is_home)
      return MiaHomeNfl;
    return MiaAwayNfl;
  } else if (team === 'MIN') {
    if (is_home)
      return MinHomeNfl
    return MinAwayNfl;
  } else if (team === 'NE') {
    if (is_home)
      return NeHomeNfl
    return NeAwayNfl;
  } else if (team === 'NO') {
    if (is_home)
      return NoHomeNfl;
    return NoAwayNfl
  } else if (team === 'NYG') {
    if (is_home)
      return NygHomeNfl;
    return NygAwayNfl
  } else if (team === 'NYJ') {
    if (is_home)
      return NyjHomeNfl
    return NyjAwayNfl;
  } else if (team === 'PHI') {
    if (is_home)
      return PhiHomeNfl;
    return PhiAwayNfl
  } else if (team === 'PIT') {
    if (is_home)
      return PitHomeNfl;
    return PitAwayNfl;
  } else if (team === 'SEA') {
    if (is_home)
      return SeaHomeNfl;
    return SeaAwayNfl;
  } else if (team === 'SF') {
    if (is_home)
      return SfHomeNfl;
    return SfAwayNfl;
  } else if (team === 'TB') {
    if (is_home)
      return TbHomeNfl;
    return TbAwayNfl;
  } else if (team === 'TEN') {
    if (is_home) {
      return TenHomeNfl;
    } else {
      return TenAwayNfl;
    }
  } else if (team === 'WAS') {
    if (is_home)
      return WasHomeNfl;
    return WasAwayNfl;
  }
  return null
}

const getJerseyForTeam = (team, gameType) => {
  // console.log('Team = ', team)
  if (gameType === 'NFL') {
    return null
  }
  switch (team) {
    case 'ATL':
      return AtlJersey;
    case 'BKN':
      return BknJersey;
    case 'BOS':
      return BosJersey;
    case 'CHA':
      return ChaJersey;
    case 'CHI':
      return ChiJersey;
    case 'CLE':
      return CleJersey;
    case 'DAL':
      return DalJersey;
    case 'DEN':
      return DenJersey;
    case 'DET':
      return DetJersey;
    case 'GSW':
      return GswJersey;
    case 'HOU':
      return HouJersey;
    case 'IND':
      return IndJersey;
    case 'LAC':
      return LacJersey;
    case 'LAL':
      return LalJersey;
    case 'MEM':
      return MemJersey;
    case 'MIA':
      return MiaJersey;
    case 'MIL':
      return MilJersey;
    case 'MIN':
      return MinJersey;
    case 'NYK':
      return NykJersey;
    case 'OKC':
      return OkcJersey;
    case 'ORL':
      return OrlJersey;
    case 'NOP':
      return NopJersey;
    case 'PHI':
      return PhiJersey;
    case 'PHX':
      return PhxJersey;
    case 'POR':
      return PorJersey;
    case 'SAC':
      return SacJersey;
    case 'SAS':
      return SasJersey;
    case 'TOR':
      return TorJersey;
    case 'UTA':
      return UtaJersey;
    case 'WAS':
      return WasJersey;
    default:
      return Jersey;
  }
}

const MatchHeader = ({gsData, token, setShowBets, showBets, gameType, game, nextPossesion,
                       setNextPossesion, nextPossesionClicked, setNextPossClicked,
                       nextPossessionOptionClicked, setNextPossessionOptionClicked,
                       setMainShotZoneOpt, setNbaPlayersVal, quarter,
                       nflGame, tournament, onNbaTeamPress, setNbaTeam,
                       onHideBetForTeamPaused, userBetsStats}) => {
  // console.log('offenseTeam = ', offenseTeam)
  // console.log('defenseTeam = ', defenseTeam)
  // console.log('homeTeam = ', homeTeam)
  // console.log('awayTeam = ', awayTeam)
  // console.log('gsData33 = ', gsData)
  // console.log('MatchHeader game = ', game)
  // console.log('MatchHeader quarter = ', quarter)
  const classes = useStyles();
  // const [gameStatus, setGameStatus] = useState({});
  const [nbaGameStatus, setNbaGameStatus] = useState({});
  // const [total_coins, setTotalCoins] = useState(0);
  const [showBetsRedirect, setShowBetsRedirect] = useState(false);
  const [lastOutcomeMsg, setLastOutcomeMsg] = useState("");
  const [lastNbaOutcome, setLastNbaOutcome] = useState("");
  const [nbaQuarter, setNbaQuarter] = useState(null);

  const history = useHistory();

  let nflGameInterval = useRef();
  let nbaGameInterval = useRef();
  let nbaLastOutcomeInterval = useRef();

  const getSocketUrl = () => {
    // console.log('game = ', game)
    const game_id = game?.game_id || game?.id || game?.gameId
    if (game_id && token?.access) {
      // console.log('token = ', token)
      const url_params = {
        "token": token.access
      }
      const url = `${constants.WEBSOCKET_HOST}/ws/chat/${game_id}/`
        + '?' + querystring.stringify(url_params)
      // console.log('url = ', url)
      return url
    }
    return null
  }

  // const { sendMessage, lastMessage, readyState, lastJsonMessage } = useWebSocket(getSocketUrl(), {
  //   onOpen: () => {
  //     console.log('MatchHeader: WebSocket connection established.');
  //   },
  //   onError: (event) => {
  //     console.log('MatchHeader: WebSocket ERROR: ', event);
  //   },
  //   //Will attempt to reconnect on all close events, such as server shutting down
  //   shouldReconnect: (closeEvent) => {
  //     console.log('MatchHeader: Trying to reconnect, closeEvent = ', closeEvent)
  //     return true
  //   },
  // });

  // const connectionStatus = {
  //   [ReadyState.CONNECTING]: 'Connecting',
  //   [ReadyState.OPEN]: 'Open',
  //   [ReadyState.CLOSING]: 'Closing',
  //   [ReadyState.CLOSED]: 'Closed',
  //   [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  // }[readyState];
  //
  // console.log('connectionStatus = ', connectionStatus)

  // const pollLastNbaOutcome = () => {
  //   // fetchLastNbaOutcome();
  //   // nbaLastOutcomeInterval.current  = setInterval(() => {
  //   //   fetchLastNbaOutcome();
  //   // }, 2000)
  // }

  const fetchNbaGameStatusData1 = () => {
    // getNbaGameProbabilities(team)
    //     .then((response) => {
    //       // console.log('fetchNbaGameStatusData1 response = ', response)
    //       if (response.data)
    //         setNbaGameStatus(response.data);
    //     }).catch((error) => {
    //   console.log("Error getting the game status:: ", error);
    // });
    getLiveGames(token)
        .then(response => {
          // if (response.data)
          //   setLiveGamesArr(response.data)
          // console.log('response33 = ', response)
          processLiveGamesResponse(response)
        }).catch((error) => {
      console.log("Error getting the list of live NBA games:: ", error);
    });
  }

  // useEffect(() => {
  //   // console.log('effect lastJsonMessage = ', lastJsonMessage)
  //   if (lastJsonMessage?.message) {
  //     // console.log("MatchHeader Websocket lastJsonMessage1 = ", lastJsonMessage);
  //     const json_str = lastJsonMessage.message
  //     const json = JSON.parse(json_str)
  //     // console.log('json = ', json)
  //     processLiveGamesResponse(json)
  //   } else if (lastJsonMessage) {
  //     // console.log("MatchHeader Websocket lastJsonMessage2 = ", lastJsonMessage);
  //     processLiveGamesResponse(lastJsonMessage)
  //   }
  // }, [lastJsonMessage]);

  const processLiveGamesResponse = (response) => {
    // console.log('processLiveGamesResponse = ', response)
    // console.log('MatchHeader game[\'id\'] = ', game['id'])
    // console.log('processLiveGamesResponse game = ', game)
    // console.log('processLiveGamesResponse gsData = ', gsData)
    const curr_game_id = game?.id ? game.id : gsData.game_id
    // const games_from_response = response?.data?.games
    if (response && response.data && response.data.games && response.data.games.length > 0) {
      let thisGame
      for (let i = 0; i < response.data.games.length; ++i) {
        const loop_response_game = response.data.games[i]
        // console.log('loop_response_game = ', loop_response_game)
        if (loop_response_game['gameId'] === curr_game_id) {
          thisGame = loop_response_game
          break
        }
      }
      // console.log('This Game = ', thisGame)
      if (thisGame) {
        // console.log('this game = ', thisGame)
        let q
        if (thisGame["isGameActivated"]) {
          if (thisGame["quarter"] === 0) {
            q = 'About to start'
          } else {
            q = thisGame["quarter"]
          }
        } else if (thisGame["quarter"] === 4) {
          q = "Final"
        } else{
          // q = thisGame["startTimeEastern"]
          if (thisGame['startDatetimeUtc'])
            q = 'Starts ' + formatStartDate(utcDateStrToDate(thisGame['startDatetimeUtc']))
        }
        setNbaGameStatus( {
          away: thisGame["away"],
          home: thisGame["home"],
          away_points: thisGame["awayPoints"] || 0,
          home_points: thisGame["homePoints"] || 0,
          quarter: q,
          clock: thisGame["isGameActivated"] ? thisGame["clock"] : ''
        })
        setLastNbaOutcome(thisGame['lastOutcomeFormatted'])
        // games.push({
        //   away: 'PHX',
        //   home: 'LAC',
        //   away_points: 100,
        //   home_points: 200,
        //   quarter: 1,
        //   clock: '14:00'
        // })
        // console.log('Games = ', games)
        // setNbaGameStatus(games)
      }
    } else {
      setNbaGameStatus({})
    }
  }

  const getNbaGameStatusInfo = () => {
    fetchNbaGameStatusData1();
    nbaGameInterval.current  = setInterval(() => {
      fetchNbaGameStatusData1();
    }, 2000)
  }

  // useEffect(() => {
  //   console.log('nflGame = ', nflGame)
  //   if (!nflGame) {
  //     return
  //   }
  //   const home_team = nflGame.home_team
  //   const away_team = nflGame.away_team
  //   if (home_team && away_team && gsData && gsData.live_play_situations
  //       && gsData.live_play_situations.length > 0) {
  //     // console.log('Looping through live_play_situations')
  //     for (let i = 0; i < gsData.live_play_situations.length; ++i) {
  //       const live_game = gsData.live_play_situations[i]
  //       if ((live_game.defense_team === home_team || live_game.offense_team === home_team)
  //           || (live_game.defense_team === away_team || live_game.offense_team === away_team)) {
  //         // console.log('setNflGame')
  //         setNflGame(live_game)
  //         break
  //       }
  //     }
  //   }
  // }, [nflGame])

  useEffect(() => {
    // const token = localStorage.getItem("token");
    // let total_coins = (gsData?.user?.total_coins ? gsData.user.total_coins.replace("$", "") : (token ? token.total_coins : 0));
    // // console.log('gsData = ', gsData)
    // // console.log('gsData')
    // // setGameStatus(gsData);
    //
    // setTotalCoins(total_coins);

    if (gameType === "NFL") {
    //   // fetchData()
    //   // console.log("gameType NFL")
    //   if (homeTeam && awayTeam) {
    //     // const home_team = nflGame.home_team
    //     // const away_team = nflGame.away_team
    //     const home_team = homeTeam
    //     const away_team = awayTeam
    //     // console.log("nflGame = ", nflGame)
    //     // console.log("home_team = ", home_team)
    //     // console.log("away_team = ", away_team)
    //
    //     let found_matching_game = false
    //     if (home_team && away_team && gsData && gsData.live_play_situations
    //         && gsData.live_play_situations.length > 0) {
    //       // console.log('Looping through live_play_situations')
    //       for (let i = 0; i < gsData.live_play_situations.length; ++i) {
    //         const live_game = gsData.live_play_situations[i]
    //         if ((live_game.defense_team === home_team || live_game.offense_team === home_team)
    //             || (live_game.defense_team === away_team || live_game.offense_team === away_team)) {
    //           // console.log('setNflGame')
    //           setNflGame(live_game)
    //           found_matching_game = true
    //           break
    //         }
    //       }
    //       if (!found_matching_game) {
    //         // fallback - very bad - just pick one of the returned
    //         // live games - it should most likely be the game the user
    //         // selected as we are usually only doing 1 game at a time now
    //         setNflGame(gsData.live_play_situations[0])
    //       }
    //     }
    //   } else {
    //     console.log("no nflGame")
    //   }
    } else if (gameType === "NBA") {
      // const gameTypeArr = gameType.split('-')
      // let nbaAwayStr = gameTypeArr[0].toLowerCase()

      // What is this??
      // let nbaAwayStr = game['away']
      // getNbaGameStatusInfo(nbaAwayStr)

      getNbaGameStatusInfo()

      // pollLastNbaOutcome()
    }
    setShowBetsRedirect(showBets);

    return function cleanup() {
      if (nflGameInterval && nflGameInterval.current) {
        clearInterval(nflGameInterval.current)
      }
      if (nbaGameInterval && nbaGameInterval.current) {
        clearInterval(nbaGameInterval.current)
      }
      if (nbaLastOutcomeInterval && nbaLastOutcomeInterval.current) {
        clearInterval(nbaLastOutcomeInterval.current)
      }
    }
  }, [gsData, token, showBets, gameType]);

  useEffect(() => {
    // console.log('gsData = ', gsData)
    // console.log('game = ', game)
    if (gsData) {
      // console.log('gsData33 = ', gsData)
      if (game) { // what is this check?
        if (gsData.last_play) {
          let outcome_msg = 'Last Play: ' + gsData.last_play;
          if (gsData.last_play_was_td) {
            outcome_msg += ', TOUCHDOWN'
          }
          setLastOutcomeMsg(outcome_msg)
        } else if (gsData.last_outcome) {
          const lastOutcome = mappLastOutcomeData(gsData.last_outcome);
          setLastOutcomeMsg(lastOutcome)
        } else {
          setLastOutcomeMsg('')
        }
      }

      // if (gsData.live_play_situations && game) {
      //   for (let i = 0; i < gsData.live_play_situations; ++i) {
      //     const live_sit = gsData.live_play_situations[i]
      //     const off_team = live_sit['offense_team']
      //     const def_team = live_sit['defense_team']
      //     console.log('off_team = ', off_team)
      //     console.log('def_team = ', def_team)
      //     if ((game.home_team == off_team || game.away_team == off_team)
      //         && (game.home_team == def_team || game.away_team == def_team)) {
      //
      //       setLastOutcomeMsg(live_sit.last_play);
      //     }
      //   }
      // }
    }
  }, [gsData]);

  const handleArrowBack = (e) => {
    e.preventDefault();

    if (onHideBetForTeamPaused)
      onHideBetForTeamPaused()

    switch(gameType) {
      case 'NFL':
        if(!showBetsRedirect) {
          setShowBets(true);
        }
        else {
          // history.push("/plays");
          history.push("/tournaments");
          // console.log('NFL')
        }
        break;
      default: // NBA
        // console.log('nextPossesion = ', nextPossesion)
        // console.log('!nextPossesionClicked = ', !nextPossesionClicked)
        // console.log('nextPossessionOptionClicked = ', nextPossessionOptionClicked)
        if (nextPossesion) {
          if (!nextPossesionClicked) {
            console.log('A');
            setNextPossesion(false);
            setNextPossClicked(false);
            setNbaPlayersVal([]);
          } else {
            if (nextPossessionOptionClicked) {
              console.log('B')
              setShowBets(true);
              setNextPossessionOptionClicked(false);
              setMainShotZoneOpt({
                isClicked: false,
                mainShotId: "",
                playerId: ""
              });
              setNbaPlayersVal([]);
            } else {
              console.log('Going back to select which team "Next possession"')
              setNextPossessionOptionClicked(false);
              setNextPossClicked(false);
              setNextPossesion(false);
              setNbaPlayersVal([]);
              setNbaTeam(null)
            }
          }
        } else {
          if (!nextPossesionClicked) {
            console.log('D')
            history.push("/tournaments");
          }
        }
        break;
    }
  };

  const mappLastOutcomeData = (lastOutcome) => {
    // console.log('lastOutcome = ', lastOutcome)
    // let lstOutcomeMsg = (lastOutcome? "last play: " + lastOutcome.rpk[0].toUpperCase() + lastOutcome.rpk.slice(1).toLowerCase() + " Play, " : "");
    let lstOutcomeMsg = ''
    if (lastOutcome && lastOutcome.rpk && lastOutcome.rpk.length > 0 && lastOutcome.rpk[0]) {
      lstOutcomeMsg = "Last Play: " + lastOutcome.rpk;
      if (lastOutcome.rpk !== 'Kickoff' && lastOutcome.rpk !== 'Punt'
        && lastOutcome.rpk !== 'Extra Point' && lastOutcome.rpk !== 'Penalty NO PLAY'
        && lastOutcome.rpk !== 'End 1st Quarter' && lastOutcome.rpk !== 'End 2nd Quarter'
        && lastOutcome.rpk !== 'End 3rd Quarter' && lastOutcome.rpk !== 'End 4th Quarter'
        && lastOutcome.rpk !== 'Field Goal' && lastOutcome.rpk !== 'Timeout') {

        // for pass incomplete, sometimes we don't have a target
        if (lastOutcome.rpk !== 'Pass Incomplete' || lastOutcome.tgt?.length > 0
            || lastOutcome.pos?.length > 0)
          lstOutcomeMsg += ', '

        if (lastOutcome.rpk === 'Pass Incomplete' && (lastOutcome.tgt?.length > 0
            || lastOutcome.pos?.length)) {
          lstOutcomeMsg += 'intended for '
        }
      }
    }

    if (lastOutcome && lastOutcome.tgt && lastOutcome.tgt !== "")
      lstOutcomeMsg += lastOutcome.tgt;
    else if(lastOutcome && lastOutcome.pos && lastOutcome.pos !== "")
      lstOutcomeMsg += lastOutcome.pos + " ";

    for (const property in lastOutcome) {
      switch(property) {
        case 'fumble':
          if (lastOutcome.fumble === 1) {
            lstOutcomeMsg += ", FUMBLE";
          }
          break;
        case 'firstdn':
          if(lastOutcome.firstdn === 1) {
            lstOutcomeMsg += ", FIRST DOWN";
          }
          break;
        case 'penalty':
          if(lastOutcome.penalty === 1) {
            lstOutcomeMsg += ", PENALTY";
          }
          break;
        case 'touchdown':
          if(lastOutcome.touchdown === 1) {
            lstOutcomeMsg += ", TOUCHDOWN";
          }
          break;
        case 'turnover':
          if(lastOutcome.turnover === 1) {
            lstOutcomeMsg += ", TURNOVER";
          }
          break;
        default:
          break;
      }
    }

    if (lastOutcome && lastOutcome.yds_gained) {
      if (lastOutcome.yds_gained >= 0) {
        lstOutcomeMsg += ', ' + lastOutcome.yds_gained + ' yd gain';
      } else {
        lstOutcomeMsg += ', ' + Math.abs(lastOutcome.yds_gained) + ' yd loss';
      }
    }

    if (lastOutcome && lastOutcome.play_min_sec) {
      lstOutcomeMsg += ' (' + lastOutcome.play_min_sec + ')'
    }

    if (lastOutcome && lastOutcome.reversed === 1) {
      lstOutcomeMsg += ' -- PLAY REVERSED'
    }
    return lstOutcomeMsg;
  };


  const getGameQuarterWithSuffix = (num) => {
    return ["st", "nd", "rd"][((((num + 90) % 100) - 10) % 10) - 1] || "th";
  };

  const getDownAndDistanceStr = () => {
    let nflCurrSituation = ''
    if (gsData) {
      // console.log('gsData = ', gsData)
      // if (lastOutcomeMsg && lastOutcomeMsg.indexOf('Touchdown') > 0) {
      //   nflCurrSituation = "PAT"
      // } else {
      if (gsData.down) {
        const down_str = gsData.down + getGameQuarterWithSuffix(Number(gsData.down));

        if (gsData.distance) {
          let did_create_label = false
          if (gsData.field_position) { // e.g. "WAS 35"
            const field_pos_split = gsData.field_position.split(" ")
            if (field_pos_split.length === 2
              && gsData.distance === parseInt(field_pos_split[1])
              && gsData.defense_team === field_pos_split[0]) {
              nflCurrSituation = down_str + " and GOAL"
              did_create_label = true
            }
          }
          if (!did_create_label) {
            nflCurrSituation = down_str + " and " + gsData.distance
          }

        } else {
          nflCurrSituation = down_str + " down"
        }
      }
      if (gsData.field_position) {
        nflCurrSituation += " on " + gsData.field_position
      }
      // }
    }
    return nflCurrSituation
  }

  let nbaAwayStr, nbaHomeStr, nbaAwayLogo, nbaHomeLogo
  // console.log('MatchHeader gameType = ', gameType)
  if (gameType === "NBA") {
    // const gameTypeArr = gameType.split('-')
    // // nbaAwayStr = 'PHI'
    // // nbaHomeStr = 'ATL'
    // nbaAwayStr = gameTypeArr[0]
    // nbaHomeStr = gameTypeArr[1]

    nbaAwayStr = game['away']
    nbaHomeStr = game['home']
    // console.log('MatchHeader Game = ', game)

    // if (nbaAwayStr === 'MIL') {
    //   nbaAwayLogo = bucksLogo
    // } else if (nbaAwayStr === 'ATL') {
    //   nbaAwayLogo = hawksLogo
    // } else if (nbaAwayStr === 'PHX') {
    //   nbaAwayLogo = sunsLogo
    // } else if (nbaAwayStr === 'LAC') {
    //   nbaAwayLogo = clippersLogo
    // }
    //
    // if (nbaHomeStr === 'MIL') {
    //   nbaHomeLogo = bucksLogo
    // } else if (nbaHomeStr === 'ATL') {
    //   nbaHomeLogo = hawksLogo
    // } else if (nbaHomeStr === 'PHX') {
    //   nbaHomeLogo = sunsLogo
    // } else if (nbaHomeStr === 'LAC') {
    //   nbaHomeLogo = clippersLogo
    // }
  }

  // console.log('nbaGameStatus = ', nbaGameStatus)
  // console.log('gameStatus = ', gameStatus)

  let lastNbaOutcomeStr = lastNbaOutcome
  // let lastNbaOutcomeStr
  // if (gameType === 'NBA') {
  //   if (lastNbaOutcome) {
  //     if (lastNbaOutcome['formatted'] && lastNbaOutcome['formatted']['description']) {
  //       lastNbaOutcomeStr = lastNbaOutcome['formatted']['description']
  //     } else if (lastNbaOutcome['description']) {
  //       lastNbaOutcomeStr = lastNbaOutcome['description']
  //     }
  //   }
  // }

  let period = quarter; // quarter is only for NFL, MatchHeader gets NBA quarter itself from the server
  // console.log('Quarter = ', quarter)
  let period_str = ''
  if (gameType === 'NBA') {
    // if (lastNbaOutcomeStr && lastNbaOutcomeStr.toUpperCase() === 'END OF 2ND QUARTER'){}
    //   period_str = 'HALFTIME';
    // else
    //   period_str = nbaGameStatus?.quarter
    period = nbaGameStatus?.quarter
  }
  // period_str = period
  if (period === 0) {
    period_str = "Starting Soon"
    if (game?.startDate && game.startDate.getTime) {
      // console.log('game?.startDate = ', game?.startDate)
      const is_started = game.startDate.getTime() < Date.now()
      if (!is_started) {
        // period_str = 'June 8, 09:00 PM'
        period_str = formatStartDate(game.startDate)
      }
      // console.log('game.startDateEastern = ', game?.startDateEastern);
    }
  } else if (period === 1)
    period_str = "1st Qtr";
  else if (period === 2)
    period_str = "2nd Qtr";
  else if (period === 3)
    period_str = "3rd Qtr";
  else if (period === 4)
    period_str = "4th Qtr";
  else if (period === 5)
    period_str = "Overtime";
  else if (period)
    period_str = period // for NBA, when game hasn't started

  // console.log('period_str = ' + period_str)

  let leftScore, rightScore
  let home, away
  // console.log('nflGame.defense_team = ', nflGame && nflGame.defense_team)
  // console.log('nflGame.defense_score = ', nflGame && nflGame.defense_score)
  // console.log('nflGame.offense_team = ', nflGame && nflGame.offense_team)
  // console.log('nflGame.offense_score = ', nflGame && nflGame.offense_score)

  // console.log('gsData2 = ', gsData)
  // console.log('game2 = ', game)
  // console.log('gameType = ', gameType)

  if (gameType === 'NFL') {
    // if (game) {
    //   if (game.defense_team === game.home_team) {
    //     leftScore = game.offense_score
    //     rightScore = game.defense_score
    //   }
    //   else {
    //     leftScore = game.defense_score
    //     rightScore = game.offense_score
    //   }
    // }
    // console.log('gsData11 = ', gsData)
    // console.log('game11 = ', game)
    if (gsData) {
      if (gsData.defense_team === gsData.home_team) {
        leftScore = gsData.offense_score
        rightScore = gsData.defense_score
      } else {
        leftScore = gsData.defense_score
        rightScore = gsData.offense_score
      }
    }

    away = game.away_team
    home = game.home_team

  } else {
    leftScore = nbaGameStatus.away_points
    rightScore = nbaGameStatus.home_points

    away = nbaAwayStr
    home = nbaHomeStr
  }

  let possession_arrow_class = null
  if (gsData) {
    if (gsData.defense_team) {
      if (gsData.defense_team === gsData.home_team) {
        possession_arrow_class = "ballpossession-indicator-left"
      } else {
        possession_arrow_class = "ballpossession-indicator-right"
      }
    }
  }

  // console.log('nflGame = ', nflGame)
  // console.log('nflGame.defense_team = ', nflGame.defense_team)
  // console.log('nflGame.home_team = ', nflGame.home_team)
  // let nflAwayLogo = nygLogo
  // let nflHomeLogo = wasLogo

  let lastPlayStr
  let nflCurrSituation = "" // e.g. down and distance + quarter
  // console.log('Period = ', period)
  // console.log('gsData?.user?.total_coins = ', gsData?.user?.total_coins)
  let total_coins
  if (gameType === 'NFL')
    total_coins = (gsData?.user?.total_coins ? gsData.user.total_coins.replace("$", "") : (token ? token.total_coins : 0));
  else
    // for WebSockets
    // total_coins = (userBetsStats?.user?.total_coins ? userBetsStats.user.total_coins : (token ? token.total_coins : 0));
    // not WebSockets
    total_coins = gsData?.user?.total_coins

  let showPossessionArrowNfl = true
  if (gameType === 'NFL') {
    const useTomsLastPlay = false
    // console.log('gsData?.last_play_was_score = ', gsData?.last_play_was_score)
    if (!period || period === 0) {
      nflCurrSituation = "Starting Soon"
      showPossessionArrowNfl = false
      if (game?.startDate && game.startDate.getTime) {
        // console.log('game?.startDate = ', game?.startDate)
        const is_started = game.startDate.getTime() < Date.now()
        if (!is_started) {
          // period_str = 'June 8, 09:00 PM'
          nflCurrSituation = 'Starts ' + formatStartDate(game.startDate)
        }
        // console.log('game.startDateEastern = ', game?.startDateEastern);
      }
    } else {
      if (useTomsLastPlay) {
        lastPlayStr = (nflGame && nflGame.last_play ? 'Last Play: ' + nflGame.last_play : '')
        // console.log('lastPlayStr.toLowerCase() = ' + lastPlayStr.toLowerCase())
        if (nflGame && nflGame.last_play_was_td) {
          nflCurrSituation = 'PAT'
        } else if ((lastPlayStr.toLowerCase().indexOf('field goal') >= 0
                && lastPlayStr.toLowerCase().indexOf('field goal missed') === -1)
            || lastPlayStr.indexOf('Extra Point') >= 0) {
          nflCurrSituation = 'SWITCHING POSSESSION'
        } else if (lastPlayStr.toLowerCase().indexOf('end 2nd quarter') >= 0) {
          nflCurrSituation = 'HALFTIME'
        } else {
          nflCurrSituation = 'Q' + period + ' ' + getDownAndDistanceStr()
        }
      } else {
        // console.log('lastOutcomeMsg = ', lastOutcomeMsg)
        // console.log('period = ', period)
        lastPlayStr = lastOutcomeMsg

        // NEEDS FINISHING TO DETECT TOUCHDOWNS/FIELDGOALS
        const last_play_was_td = gsData?.last_play_was_td
        const last_play_was_end_reg = gsData?.last_play_was_end_reg || lastPlayStr.indexOf('End 4th Quarter') > 0
        const last_play_was_reversed = gsData?.last_outcome?.reversed === 1
        if (last_play_was_td && !last_play_was_reversed) {
          nflCurrSituation = 'PAT'
        } else if (last_play_was_end_reg) {
          showPossessionArrowNfl = false
          nflCurrSituation = 'End of Regulation'
        } else if (gsData?.last_play_was_score || gsData?.last_play_type === 'TwoPointConversion') {
          if (period === 'F') {
            showPossessionArrowNfl = false
            nflCurrSituation = 'FINAL';
          } else {
            if (period !== 'HALF' && period !== 'OT' && period !== '2OT'){}
              nflCurrSituation = 'Q';
            nflCurrSituation += period + ', Change of Possession';
          }
        } else {
          if (period === 'F') {
            showPossessionArrowNfl = false
            nflCurrSituation = 'FINAL';
          } else {
            if (period === 'HALF') {
              nflCurrSituation = period
              showPossessionArrowNfl = false
            } else if (lastPlayStr.indexOf('End 2nd Quarter') > 0) {
              nflCurrSituation = 'HALFTIME'
              showPossessionArrowNfl = false
            } else if (lastPlayStr.indexOf('Extra Point') > 0 || lastPlayStr.indexOf('Field Goal') > 0) {
              nflCurrSituation = 'CHANGE OF POSSESSION'
              showPossessionArrowNfl = false
            } else {
              /* FIXME - what about end of regulation going into overtime - don't show down, distance */
              if (period !== 'OT' && period !== '2OT')
                nflCurrSituation = 'Q'
              nflCurrSituation += period + ' ' + getDownAndDistanceStr()
            }
          }
        }
      }
    }
  } else {
    lastPlayStr = lastNbaOutcomeStr
  }

  // console.log('nflCurrSituation = ', nflCurrSituation)

  let away_jersey, home_jersey
  if (gameType === 'NFL') {
    away_jersey = getJerseyForNflTeam(away, false)
    home_jersey = getJerseyForNflTeam(home, true)
  } else {
    away_jersey = getJerseyForTeam(away, gameType)
    home_jersey = getJerseyForTeam(home, gameType)
  }

  let showTotalUserCoins = (tournament?.score_system !== 'most_bets_won')
  // console.log('tournament = ', tournament)
  // console.log('showTotalUserCoins = ', showTotalUserCoins)

  return (
    <div className="game">
      <Grid container direction="row" alignItems="center" justify="space-around" style={{marginBottom: 10}}>
        <Grid item xs={2}>
          <Link style={{color: "white"}}>
            <ArrowBackIcon style={{marginLeft: 10}} onClick={(e) => handleArrowBack(e)}/>
          </Link>
        </Grid>
        <Grid item xs={8} className={classes.matchHeaderText}>
        {lastPlayStr}
        </Grid>
        <Grid item xs={2} >
          {showTotalUserCoins && <div style={{float: 'left', width: 60, margin: "auto", height: 14, borderRadius: 10, border: '1px solid rgba(253, 199, 52, 1)', flexDirection: 'row', paddingTop: 4, paddingBottom: 4}}>
            <Typography style={{float: 'left', textAlign: 'right', width: 40, /*backgroundColor: 'red',*/ fontFamily: 'Kanit', fontSize: 14, fontWeight: 500, color: '#FDC934', height: 14, lineHeight: "13px"}}>
              {
              (isNaN(total_coins))
              ? ('0')
              : ( Math.floor(total_coins))
              }</Typography>
            <CopyrightIcon style={{float: 'left', width: 20, height: 14, marginTop: 0, /*backgroundColor: 'green', */color: '#FDC934'}}/>
          </div>}
        </Grid>
      </Grid>
      <Grid container alignItems="center" justify="space-around" spacing={3} style={{marginBottom: 12, marginTop: 2}}>
          <div className="flag-wrapper-game">
            <Grid item className="jersey-wrapper" style={{marginLeft: 20/*not sure why 20 looks good*/}}>
              <Button style={{display: 'inline-block'}} onClick={() => {onNbaTeamPress(away)}}>
                {away_jersey && <img src={away_jersey} className={"jersey"}/>}
                {gameType !== 'NFL' && <div className={"teamNameInHeader"}>
                  {away}
                </div>}
              </Button>
            </Grid>
          </div>
        <Grid item>
             <div className="matchScore" >{leftScore}</div>
        </Grid>
        <Grid item>
             <div className="matchScore">{rightScore}</div>
         </Grid>
         <div className="flag-wrapper-game" style={{marginRight: 30}}>
           <Grid item className="jersey-wrapper">
             <Button style={{display: 'inline-block'}} onClick={() => {/*onNbaTeamPress(home)*/}}>
               {home_jersey && <img src={home_jersey} className={"jersey"}/>}
               {gameType !== 'NFL' && <div className={"teamNameInHeader"}>
                 {home}
               </div>}
             </Button>
           </Grid>
         </div>
      </Grid>
      {gameType === 'NFL' && game && showPossessionArrowNfl && <div className={possession_arrow_class}></div>}
      {/*gameType === 'NFL' && <div className="primline"></div>*/}
      <div className="primline"></div>
      <div className="primtext"> <span>{gameType === 'NFL' ? nflCurrSituation : period_str} </span> <span className="primQuarter"> {gameType == 'NFL' ? (game && game.quarter ? " - " + game.quarter + "Q" : ''): (nbaGameStatus.clock && nbaGameStatus.clock !== '' ? "-" + nbaGameStatus.clock : '')}</span></div>

    </div>
  );
};

export default MatchHeader;
