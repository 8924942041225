import React, { useEffect, useRef, useState } from "react";
import {Box, Container, FormControl, Grid, TextField, Typography} from "@material-ui/core";
import { useStyles } from "../styles";
import PlaysHeader from "../../ui/PlaysHeader";
import {TERMS_AND_CONDITIONS} from "../../TermsAndConditionsText";


function TermsOfService() {
    const classes = useStyles();
    return (
        <Box className={classes.body}>
            <PlaysHeader  showBackButton={false} showAccountIcon={false}/>
            <Grid
                container
                className={classes.terms}
            >
                {TERMS_AND_CONDITIONS.TEXT[0]}
                <ul>
                    {TERMS_AND_CONDITIONS.USER_TERMS_LI.map(item => {
                        return <li key={item}>{item}</li>;
                    })}
                </ul>
                {TERMS_AND_CONDITIONS.TEXT[1]}
                <ul>
                    {TERMS_AND_CONDITIONS.USER_FAQ_LI.map(item => {
                        return <li key={item}>{item}</li>;
                    })}
                </ul>
                {TERMS_AND_CONDITIONS.TEXT[2]}
                <ul>
                    {TERMS_AND_CONDITIONS.ACCEPTABLE_USE_POLICY_LI.map(item => {
                        return <li key={item}>{item}</li>;
                    })}
                </ul>
                {TERMS_AND_CONDITIONS.TEXT[3]}
            </Grid>
        </Box>
    );
}
export default TermsOfService;
