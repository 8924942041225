import {getNbaGameProbabilities} from "../hooks/useNbaProbs";
import {convertProbToOdds} from "../utils/probabilityUtils"
import FlagIcon from "@material-ui/icons/Flag";
import TwoPoint from "../components/icons/2-point.png";
import ThreePoint from "../components/icons/3-point.png";

export let nbaDataResponse = [];
export let selectedTeam = null;

export const getDefaultOptionsState = () => {
  return DEFAULT_OPTIONS_STATE.slice()
}

export const DEFAULT_OPTIONS_STATE = [
  { icon: <img alt={"2-point-shot"} className="MuiSvgIcon-root" src={TwoPoint}  style={{fontSize: "14px"}} />, name: "2-point shot", odds: "LOADING", type:"nextPossession"},
  { icon: <img alt={"3-point-shot"} className="MuiSvgIcon-root" src={ThreePoint} style={{fontSize: "14px"}} />, name: "3-point shot", odds: "LOADING", type:"nextPossession"},
  // { icon: <img alt={"Player-shot"} className="MuiSvgIcon-root" src={TShirt} style={{fontSize: "14px"}} />, name: "Player shot", odds: "0.0x - 0.0x", type:"nextPossession"},
  // { icon: <CompassCalibrationIcon style={{color: "#FDC934", fontSize: "20px"}} />, name: "Shooting zone", odds: "0.0x - 0.0x", type:"nextPossession"},
  { icon: <FlagIcon style={{color: "#FDC934", fontSize: "20px"}} />, name: "Possession result", odds: "LOADING", type:"nextPossession"}
];
export let possesionOptions = getDefaultOptionsState()

export const setSelectedTeam = (team) => {
  selectedTeam = team
}

export const resetNbaData = () => {
  // console.log('resetNbaData')
  nbaDataResponse = []
  possesionOptions = getDefaultOptionsState()
  setSelectedTeam(null)
}

const setMainPosibility = (items) => {
  possesionOptions = getDefaultOptionsState()
  items.forEach(element => {
    var foundIndex = possesionOptions.findIndex(x => x.name === element.name);
    possesionOptions[foundIndex] = element;
  });
  // console.log('possesionOptions[0].odds = ' + possesionOptions[0].odds)
  // console.log('getNbaOdds odds = ' + possesionOptions[0].odds)
};


const mappMainProbability =  (probabilityResponse) => {
  // console.log('mappMainProbability')
  let twoPtShot = [];
  let treePointShot = [];
  let shootingZone = [];
  let playResultCells = [];
  let playerShotCells = [];
  let plays = [];

  for (const property in probabilityResponse) {
    switch(property) {
      case 'two_pt_fg_cells':
        twoPtShot.push(probabilityResponse["two_pt_fg_cells"]);
        if(twoPtShot.length > 0) {
          twoPtShot[0].forEach((item) => {
            let prob = Number(item.percent / 100).toFixed(2);
            // let odds = ((1 - prob) / prob).toFixed(1);
            let odds = convertProbToOdds(prob)
            item["odds"] = parseFloat(odds);
          })
          let max = probabilityResponse["two_pt_fg_cells"].reduce((a, b) => a.odds > b.odds ? a : b, 0);
          let min = probabilityResponse["two_pt_fg_cells"].reduce((a, b) => a.odds < b.odds ? a : b, 0);
          if(max === 0) {
           max = {odds: 0 }
          }
          if(min === 0) {
            min = {odds: 0 }
          }
          plays.push({
            icon: <img alt={"2-point-shot"} className="MuiSvgIcon-root" src={TwoPoint}  style={{fontSize: "14px"}} />,
            name: "2-point shot",
            odds: min.odds + "x - " + max.odds + "x",
            type:"nextPossession"
          });
        }
        break;
      case 'three_pt_fg_cells':
        treePointShot.push(probabilityResponse["three_pt_fg_cells"]);
        if(treePointShot.length > 0) {
          treePointShot[0].forEach((item) => {
            let prob = Number(item.percent / 100).toFixed(2);
            // let odds = ((1 - prob) / prob).toFixed(1);
            let odds = convertProbToOdds(prob)
            item["odds"] = parseFloat(odds);
          })
          let max = probabilityResponse["three_pt_fg_cells"].reduce((a, b) => a.odds > b.odds ? a : b, 0);
          let min = probabilityResponse["three_pt_fg_cells"].reduce((a, b) => a.odds < b.odds ? a : b, 0);
          if(max === 0) {
            max = {odds: 0 }
           }
           if(min === 0) {
             min = {odds: 0 }
           }
          plays.push({
            icon: <img alt={"3-point-shot"} className="MuiSvgIcon-root" src={ThreePoint} style={{fontSize: "14px"}} />,
            name: "3-point shot",
            odds: min.odds + "x - " + max.odds + "x",
            type:"nextPossession"
          });
        }
        break;
      case 'shot_zone_cells':
        // shootingZone.push(probabilityResponse["shot_zone_cells"]);
        // if(shootingZone.length > 0) {
        //   shootingZone[0].forEach((item) => {
        //     let prob = Number(item.percent / 100).toFixed(2);
        //     let odds = ((1 - prob) / prob).toFixed(1);
        //     item["odds"] = parseFloat(odds);
        //   })
        //   let max = probabilityResponse["shot_zone_cells"].reduce((a, b) => a.odds > b.odds ? a : b, 0);
        //   let min = probabilityResponse["shot_zone_cells"].reduce((a, b) => a.odds < b.odds ? a : b, 0);
        //   if(max === 0) {
        //     max = {odds: 0 }
        //    }
        //    if(min === 0) {
        //      min = {odds: 0 }
        //    }
        //   plays.push({
        //     icon: <CompassCalibrationIcon style={{color: "#FDC934", fontSize: "20px"}} />,
        //     name: "Shooting zone",
        //     odds: min.odds + "x - " + max.odds + "x",
        //     type:"nextPossession"
        //   });
        // }
        break;
      case 'play_result_cells':
        playResultCells.push(probabilityResponse["play_result_cells"]);
        if(playResultCells.length > 0) {
          playResultCells[0].forEach((item) => {
            let prob = Number(item.percent / 100).toFixed(2);
            // let odds = ((1 - prob) / prob).toFixed(1);
            let odds = convertProbToOdds(prob)
            item["odds"] = parseFloat(odds);
          })
          let max = probabilityResponse["play_result_cells"].reduce((a, b) => a.odds > b.odds ? a : b, 0);
          let min = probabilityResponse["play_result_cells"].reduce((a, b) => a.odds < b.odds ? a : b, 0);
          if(max === 0) {
            max = {odds: 0 }
           }
           if(min === 0) {
             min = {odds: 0 }
           }

          plays.push({
            icon: <FlagIcon style={{color: "#FDC934", fontSize: "20px"}} />,
            name: "Possession result",
            odds: min.odds + "x - " + max.odds + "x",
            type:"nextPossession"
          })
        }
        break;
      case 'player_shot_cells':
        // playerShotCells.push(probabilityResponse["player_shot_cells"]);
        // if(playerShotCells.length > 0) {
        //   playerShotCells[0].forEach((item) => {
        //     let prob = Number(item.percent / 100).toFixed(2);
        //     let odds = ((1 - prob) / prob).toFixed(1);
        //     item["odds"] = parseFloat(odds);
        //   })
        //   let max =  probabilityResponse["player_shot_cells"].reduce((a, b) => a.odds > b.odds ? a : b, 0);
        //   let min =  probabilityResponse["player_shot_cells"].reduce((a, b) => a.odds < b.odds ? a : b, 0);
        //   if(max === 0) {
        //     max = {odds: 0 }
        //    }
        //    if(min === 0) {
        //      min = {odds: 0 }
        //    }
        //
        //   plays.push({
        //     icon: <img alt={"Player-shot"} className="MuiSvgIcon-root" src={TShirt} style={{fontSize: "14px"}} />,
        //     name: "Player shot",
        //     odds:min.odds + "x - " + max.odds + "x",
        //     type:"nextPossession"
        //   })
        // }
        break;
      default:
        break;
    }
  }
  setMainPosibility(plays);
  return probabilityResponse;
};

export const fetchNbaData = async (team) => {
  // console.log('getNbaOdds fetchNbaData = ', team)
  let dataResponse = [];
 // dataResponse = await getNbaGameProbabilities();
  dataResponse = await getNbaGameProbabilities(team);
  if (selectedTeam == null || team !== selectedTeam) {
    return
  }
  // console.log('about to call mappMainProbability')
  mappMainProbability(dataResponse.data);
  nbaDataResponse = dataResponse.data;
};
