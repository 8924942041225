import React, {useEffect, useRef, useState} from "react";
import { useStyles } from "../../layouts/styles";
import { Button, Grid,  } from "@material-ui/core";

import bucksLogo from "../../icons/Milwaukee-Bucks-Symbol-1.png";
import sunsLogo from "../../icons/Phoenix-Suns-logo.png";
import hawksLogo from "../../icons/hawks.png";
import clippersLogo from "../../icons/clippers.png";

import "../BetButton.css";

const MatchButton = ({ content, icon, game, onClick }) => {
  const [lastNbaOutcome, setLastNbaOutcome] = useState("");
  const classes = useStyles();
  let nbaLastOutcomeInterval = useRef();

  // console.log('MatchButton game = ', game)

  const fetchLastNbaOutcome = () => {
    // console.log('fetchLastNbaOutcome' + game['id'])
    // getLastPlay('0042000234', 4, setLastNbaOutcome)
    // getLastPlay('0042000303', 4, setLastNbaOutcome)
    // console.log('Game = ', game)

    // if (game['quarterRaw'] > 0)
    //   getLastPlay(game['id'], game['startDateEastern'],
    //       game['quarterRaw'], setLastNbaOutcome)
  }

  const pollLastNbaOutcome = () => {
    fetchLastNbaOutcome();
    nbaLastOutcomeInterval.current  = setInterval(() => {
      fetchLastNbaOutcome();
    }, 2000)
  }

  useEffect(() => {
    pollLastNbaOutcome()
  //   let lastOutcome = "";
  //   if(content) {
  //     lastOutcome = mappLastOutcomeData(content.last_outcome);
  //   }
  //   setLastOutcomeMsg(lastOutcome);
  //   localStorage.setItem('matchSelected', content)
    return function cleanup() {
      if (nbaLastOutcomeInterval && nbaLastOutcomeInterval.current) {
        clearInterval(nbaLastOutcomeInterval.current)
      }
    }
  }, [content]);

  let lastNbaOutcomeStr = game.lastOutcomeFormatted
  // let lastNbaOutcomeStr
  // if (lastNbaOutcome) {
  //   // console.log('lastNbaOutcome = ', lastNbaOutcome)
  //   if (lastNbaOutcome['formatted'] && lastNbaOutcome['formatted']['description']) {
  //     lastNbaOutcomeStr = lastNbaOutcome['formatted']['description']
  //   } else if (lastNbaOutcome['description']) {
  //     lastNbaOutcomeStr = lastNbaOutcome['description']
  //   }
  // }

  let period = game.quarter
  let period_str
  if (lastNbaOutcomeStr && lastNbaOutcomeStr.toUpperCase() === 'END OF 2ND QUARTER')
    period_str = 'HALFTIME'
  else {
    period_str = period
    if (period === 1)
      period_str = "1st Qtr"
    else if (period === 2)
      period_str = "2nd Qtr"
    else if (period === 3)
      period_str = "3rd Qtr"
    else if (period === 4)
      period_str = "4th Qtr"
    else if (period === 5)
      period_str = "Overtime"
  }

  let nbaAwayLogo = null, nbaHomeLogo = null

  let away = game.away
  // if (away === 'MIL') {
  //   nbaAwayLogo = bucksLogo
  // } else if (away === 'ATL') {
  //   nbaAwayLogo = hawksLogo
  // } else if (away === 'PHX') {
  //   nbaAwayLogo = sunsLogo
  // } else if (away === 'LAC') {
  //   nbaAwayLogo = clippersLogo
  // }

  let home = game.home
  // if (home === 'MIL') {
  //   nbaHomeLogo = bucksLogo
  // } else if (home === 'ATL') {
  //   nbaHomeLogo = hawksLogo
  // } else if (home === 'PHX') {
  //   nbaHomeLogo = sunsLogo
  // } else if (home === 'LAC') {
  //   nbaHomeLogo = clippersLogo
  // }
  // console.log('game.clock = ', game.clock)
  // console.log('game.id = ', game.id)
  return (
    <Button
      className={classes.matchButtonNba}
      startIcon={icon}
      onClick={(onClick)
      ? () => onClick(away + '-' + home, game)
        : undefined
      }
    >
    <div className="wrapper">
      <div >
        <Grid item>
          <p className={classes.matchBtnText}>{lastNbaOutcomeStr}</p>
        </Grid>
        <Grid container alignItems="center" justify="space-around" spacing={3}>
          <div className="flag-wrapper">
            <Grid item className="img-wrapper">
              {nbaAwayLogo !== null && <img alt={away}
                 style={{width: "50px", height: "50px"}}
                 className="team-logo"
                 src={nbaAwayLogo} />}
              {nbaAwayLogo == null && <div>{away}</div>}
            </Grid>
          </div>
          <Grid item>
              <div className="score">{game.away_points}</div>
          </Grid>
          <Grid item> : </Grid>
          <Grid item>
              <div className="score">{game.home_points}</div>
          </Grid>
          <div className="flag-wrapper">
            <Grid item className="img-wrapper">
              {nbaHomeLogo !== null && <img alt={home}
                   style={{width: "50px", height: "50px"}}
                   className="team-logo-raiders"
                   src={nbaHomeLogo} />}
              {nbaHomeLogo === null && <div>{home}</div>}
            </Grid>
          </div>
        </Grid>
      </div>
      {game.clock !== '' && <div className="primtext"> <span>{period_str}</span>&nbsp;&nbsp;<span className="primQuarter">{game.clock}</span></div>}
      {game.clock === '' && <div className="primtext"> <span>{period_str}</span></div>}
    </div>
    </Button>
  );
};
export default MatchButton;
// <Container className={classes.quota}>
// <div className="quotaText">0.3x</div>
// </Container>
