import {useStyles} from "../styles";
import {useCreateTournStyles} from "./CreateStyles";
import PlaysHeader from "../../ui/PlaysHeader";
import { openUploadWidget } from "../../../utils/CloudinaryService";

import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    Link,
    MenuItem,
    Radio,
    RadioGroup,
    styled,
    Switch,
    TextField,
    Typography
} from "@material-ui/core";
import {yellow} from "@material-ui/core/colors";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css'
import {createTournament, getGamesForTournaments} from "../../../hooks/useTournaments";
import {formatStartDate, utcDateStrToDate, validateHHHHMMDD} from "../../../utils/dateUtils";
import {useHistory} from "react-router-dom";
import constants from '../../../constants'

const sportOptions = [
    { id: constants.SPORT_FOOTBALL, label: constants.SPORT_FOOTBALL},
    { id: constants.SPORT_BASKETBALL, label: constants.SPORT_BASKETBALL},
]

const input_label_props = {
    style: {
        fontSize: "16px",
        color:" #FFF",
        opacity: "0.7",
        fontFamily: 'Kanit'
    }
}

const format_options = [
    {
        value: 'multiplayer',
        label: 'Multiplayer',
    },
    {
        value: 'qualifiers',
        label: 'Qualifiers',
    },
    {
        value: '1x1',
        label: '1x1',
    },
]

// const sport_options = [
//     {
//         value: 'nfl',
//         label: 'NFL',
//     },
//     {
//         value: 'nba',
//         label: 'NBA',
//     },
//     {
//         value: 'mlb',
//         label: 'MLB',
//     },
// ]

const min_bet_options = [
    {
        value: 1,
        label: '1',
    },
    {
        value: 5,
        label: '5',
    },
    {
        value: '10',
        label: 10,
    },
    {
        value: '25',
        label: 25,
    },
    {
        value: '50',
        label: 50,
    },
    {
        value: '100',
        label: 100,
    },
]

const score_system_options = [
    {
        value: 'most_bets_won',
        label: 'Most Calls Won',
    },
    {
        value: 'most_gains',
        label: 'Most Gains',
    },
    // {
    //     value: 'bets_won_x_gains',
    //     label: 'Bets Won x Gains',
    // },
]

const YellowSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: yellow[600],
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: yellow[600],
    },
}));

const TournamentCreate = ({token, setIsShowBottomBar}) => {
    const classes = useStyles()
    const tournCreateStyles = useCreateTournStyles()
    const history = useHistory()

    const [tournTitle, setTournTitle] = useState("");
    const [isSingleGame, setIsSingleGame] = useState(true);
    const [isDriveByDrive, setIsDriveByDrive] = useState(false);
    const [format, setFormat] = useState('');
    const [sport, setSport] = useState(null);
    // const [sportId, setSportId] = useState(null);
    const [gameId, setGameId] = useState('');
    const [isGameFieldVisible, setIsGameFieldVisible] = useState(false);
    const [isDriveVsPlayVisible, setIsDriveVsPlayVisible] = useState(false);
    const [numPlayers, setNumPlayers] = useState("20");
    const [startDate, setStartDate] = useState(null);
    const [startDateStr, setStartDateStr] = useState(null);
    const [showCalendar, setShowCalendar] = useState(false);
    // const [showTimePicker, setShowTimePicker] = useState(false);
    const [startTime, setStartTime] = useState(null);
    const [minBetAmt, setMinBetAmt] = useState("1");
    const [scoreSystem, setScoreSystem] = useState('most_gains');
    const [entryFee, setEntryFee] = useState('20');
    const [prize, setPrize] = useState('400');
    const [onlyInvitedPlayersCanJoin, setOnlyInvitedPlayersCanJoin] = useState(false);
    const [playersCanInviteMore, setPlayersCanInviteMore] = useState(false);
    const [gameOptions, setGameOptions] = useState([]);

    const [titleError, setTitleError] = useState(null)
    const [formatError, setFormatError] = useState(null)
    const [numPlayersError, setNumPlayersError] = useState(null)
    const [sportError, setSportError] = useState(null)
    const [gameError, setGameError] = useState(null)
    const [startDateError, setStartDateError] = useState(null)
    const [startTimeError, setStartTimeError] = useState(null)
    const [minBetError, setMinBetError] = useState(null)
    const [scoreSysError, setScoreSysError] = useState(null)
    const [entryFeeError, setEntryFeeError] = useState(null)
    const [prizeError, setPrizeError] = useState(null)
    const [imageId, setImageId] = useState(null)

    const onImageUpload = (public_id) => {
        // console.log('onImageUpload public_id = ', public_id)
        setImageId(public_id)
    }

    const uploadImageWidget = () => {
        let myUploadWidget = openUploadWidget(
          {
              cloudName: constants.CLD_CLOUD_NAME,
              uploadPreset: constants.CLD_UPLOAD_PRESET,
              // uploadPreset: props.upload_preset,
              // maxImageWidth: 600,
              // sources: ["local", "url", "camera"]
              sources: ["local", "camera"]
          },
          function (error, result) {
              if (!error && result.event === "success") {
                  onImageUpload(result.info.public_id);
              }
          }
        );
        myUploadWidget.open();
    };

    useEffect(() => {
        setIsShowBottomBar(false)
    }, [])

    function formatStartDay(date) {
        let m = date.getMonth() + 1
        if (m < 10)
            m = '0' + m
        let d = date.getDate()
        if (d < 10)
            d = '0' + d
        const ret = date.getFullYear() + '-' + m + '-' + d
        // console.log('start_day = ', ret)
        return ret
    }

    function formatStartTime(date) {
        // https://stackoverflow.com/questions/8888491/how-do-you-display-javascript-datetime-in-12-hour-am-pm-format
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        let strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    function onSingleGameRadioClick() {
        setIsSingleGame(true)
        setIsGameFieldVisible(true)
    }

    function onAllSeasonRadioClick() {
        setIsSingleGame(false)
        setIsGameFieldVisible(false)
    }

    function onDriveByDriveRadioClick() {
        setIsDriveByDrive(true)
    }

    function onPlayByPlayRadioClick() {
        setIsDriveByDrive(false)
    }

    function isHourInDesiredForm(str) {
        const n = Math.floor(Number(str));
        // console.log('n = ', n)
        return n !== Infinity && String(n) === str && n >= 1 && n <= 12;
    }

    function isMinuteInDesiredForm(str) {
        if (str === '00')
            return true
        const n = Math.floor(Number(str));
        // console.log('n = ', Boolean(String(n) === str))
        // console.log('String(n) = ', String(n))
        return n !== Infinity && (String(n) === str || (str.length > 1 && String(n) === str.charAt(1))) && n >= 0 && n <= 59;
    }

    const validateTimeStr = () => {
        const to_validate = startTime
        // console.log('startTime = "', startTime, '"')
        if (!to_validate || to_validate.trim() === '') {
            return false
        }
        const ampm_split = to_validate.trim().split(/\s+/)
        // console.log(ampm_split)
        if (ampm_split.length !== 2) {
            return false
        }
        // console.log('ampm_split = ', ampm_split)
        const digits_portion = ampm_split[0]
        const ampm_portion = ampm_split[1].toLowerCase()
        // console.log('ampm_portion = ', ampm_portion)
        if (ampm_portion !== 'am' && ampm_portion !== 'pm' && ampm_portion !== 'a' && ampm_portion !== 'p') {
            return false
        }
        const digits_split = digits_portion.split(':')
        // console.log('digits_split = ', digits_split)
        if (digits_split.length !== 2) {
            return false
        }
        const hour = digits_split[0]
        const mins = digits_split[1]

        if (!isHourInDesiredForm(hour) || !isMinuteInDesiredForm(mins)) {
            return false
        }
        return true
    }

    const validateStartDate = (date_str) => {
        // console.log('date_str = ', date_str)
        if (validateHHHHMMDD(date_str)) {
            const now = new Date()
            const date_validating = new Date()

            const hyphen_split = date_str.split("-")
            date_validating.setYear(parseInt(hyphen_split[0]))
            date_validating.setMonth(parseInt(hyphen_split[1]) - 1)
            date_validating.setDate(parseInt(hyphen_split[2]))
            // console.log('now hours = ', now.getHours())
            // console.log('now mins = ', now.getMinutes())
            // console.log('Date.parse(date_str) = ', Date.parse(date_str))
            // const date_validating = new Date(Date.parse(date_str))
            // console.log('date_validating hours = ', now.getHours())
            // console.log('date_validating mins = ', now.getMinutes())
            //
            // console.log('now = ', now)
            // console.log('date_validating = ', date_validating)

            if (now.getTime() > date_validating.getTime()) {
                setStartDateError('Start cannot be in the past')
            } else {
                setStartDateError(null)
            }
        } else {
            setStartDateError('Should be format: YYYY-MM-DD')
        }
    }

    const onCalendarChange = (newVal) => {
        console.log('newVal = ', newVal)
        setStartDate(newVal)
        const iso_string = newVal.toISOString()
        // console.log('iso_string = ', iso_string)
        const date_str = iso_string.slice(0,10)
        setStartDateStr(date_str)
        setShowCalendar(false)

        validateStartDate(date_str)
    }

    // const onTimeChange = (newVal) => {
    //     setStartTime(newVal)
    //     setShowTimePicker(false)
    // }

    const onStartDateStr = (newStartDateStr) => {
        setStartDateStr(newStartDateStr)
        setShowCalendar(false)

        validateStartDate(newStartDateStr)
    }

    const onChangeOnlyInvitedPlayersCanJoin = (e) => {
        // console.log('e = ', e)
        // console.log('blah1 = ', e.target.checked)
        // console.log('blah2 = ', e.target.value)
        setOnlyInvitedPlayersCanJoin(e.target.checked)
    }

    const onChangePlayersCanInviteMore = (e) => {
        setPlayersCanInviteMore(e.target.checked)
    }

    // FIXME repeated code from Plays.jsx
    const checkToken = () => {
        const userToken = localStorage.getItem("token");
        if (userToken) {
            let values = userToken.split(";");
            if (new Date(values[1]) < new Date()) {
                localStorage.removeItem("token");
                window.location = "/"
                console.log('Expired token')
                return false
            } else {
                // const token = JSON.parse(values[0]);
                // setToken(token);
            }
        } else {
            window.location = "/"
            console.log('No userToken')
            return false
        }
        return true
    }

    const submitTournamentForm = () => {
        if (!checkToken()) {
            // shouldn't get here
            return
        }
        let validationError = false // needed because the state updates e.g. setTitleError() may take a few ms
        if (!tournTitle || tournTitle.length === 0) {
            setTitleError('Required field.');
            validationError = true
        } else {
            setTitleError(null);
        }
        // if (!format || format === '') {
        //     setFormatError('Required field.')
        // } else {
        //     setFormatError(null)
        // }
        if (!numPlayers) {
            setNumPlayersError('Required field.')
            validationError = true
        } else {
            if (parseInt(numPlayers) <= 0) {
                setNumPlayersError('Must be greater than 0');
                validationError = true
            } else
                setNumPlayersError(null);
        }
        // if (!sport) {
        //     setSportError('Required field.')
        // } else {
        //     setSportError(null)
        // }
        if (!gameId || gameId === '') {
            setGameError('Required field.')
            validationError = true
        } else {
            setGameError(null)
        }
        if (!startDate) {
            setStartDateError('Required field.')
            validationError = true
        } else {
            setStartDateError(null)
        }
        // console.log('startTime = ', startTime)
        if (!startTime) {
            setStartTimeError('Required field.')
            validationError = true
        } else {
            if (!validateTimeStr()) {
                setStartTimeError('Must be in format \'HH:MM am\' or \'HH:MM pm\'')
                validationError = true
            } else {
                setStartTimeError(null);
            }
        }
        if (!minBetAmt) {
            setMinBetError('Required field.')
            validationError = true
        } else {
            setMinBetError(null)
        }
        if (!scoreSystem || scoreSystem === '') {
            setScoreSysError('Required field.')
            validationError = true
        } else {
            setScoreSysError(null)
        }
        if (!entryFee) {
            setEntryFeeError('Required field.')
            validationError = true
        } else {
            if (parseFloat(entryFee) < 0) {
                setEntryFeeError('Cannot be negative.');
                validationError = true
            } else
                setEntryFeeError(null);
        }
        if (!prize) {
            setPrizeError('Required field.')
        } else {
            if (parseFloat(prize) < 0) {
                setPrizeError('Cannot be negative.');
                validationError = true
            }
            else
                setPrizeError(null);
        }

        // if (titleError || numPlayersError || (isGameFieldVisible && gameError) || startDateError
        //     || startTimeError || minBetError || scoreSysError || entryFeeError || prizeError) {
        if (validationError) {
            // validation error(s)
            return
        }

        const startDateTime = startDateStr + ' ' + startTime
        console.log('startDateTime = ', startDateTime)
        const startDateTimeMillis = startDate.getTime() // comes back in UTC
        console.log('startDateTimeMillis = ', startDateTimeMillis)

        const formVals = {
            title: tournTitle,
            is_single_game: isSingleGame,
            is_play_by_play: !isDriveByDrive,
            format, // not shown right now
            total_participants: numPlayers,
            sport, // not shown right now
            game_id: gameId,
            startDateStr,
            startDateTime,
            startDateTimeMillis, // this is the important startDate value
            min_bet: minBetAmt,
            score_system: scoreSystem,
            entry_fee: entryFee,
            prize,
            only_invited_players: onlyInvitedPlayersCanJoin,
            players_can_invite: playersCanInviteMore,
            user_id: token.id,
            image_id: imageId
        };
        // console.log('formVals = ', formVals)
        // console.log('imageId = ', imageId)
        createTournament(formVals).then(response => {
            // console.log('Create Tournament response = ', response)
            history.push("/tournaments", {
                sport
            })
        }).catch(error => {
            console.log('Error: ', error)
            alert('Error: ' + error.message)
        })
    }

    const onSportSelected = (e) => {
        const sportSel = e.target.value
        setSport(sportSel)

        if (sportSel !== constants.SPORT_FOOTBALL) {
            setIsDriveVsPlayVisible(false)
        }

        getGamesForTournaments(sportSel).then(response => {
            // console.log('response = ', response)
            const new_options = []
            if (response && response.data) {
                for (let i = 0; i < response.data.length; ++i) {
                    const response_row = response.data[i]
                    const start_date = utcDateStrToDate(response_row.start_datetime_utc)
                    new_options.push({
                        game_id: response_row.game_id,
                        label: response_row.away + ' vs ' + response_row.home + ' - '
                          + formatStartDate(start_date),
                        start_date,
                        start_day_str: formatStartDay(start_date),
                        start_time_str: formatStartTime(start_date)
                    })
                }
            }
            setGameOptions(new_options);
            setIsGameFieldVisible(true)
            if (sportSel === constants.SPORT_FOOTBALL) {
                // setIsDriveVsPlayVisible(true)
            }
        })
    }

    const onGameSelected = (e) => {
        const game_id = e.target.value
        setGameId(game_id)
        for (let i = 0; i < gameOptions.length; ++i) {
            const opt = gameOptions[i]
            if (opt.game_id === game_id) {
                // onCalendarChange(opt.start_date)
                // console.log('newVal = ', newVal)
                setStartDate(opt.start_date)

                setStartDateStr(opt.start_day_str)
                setShowCalendar(false)

                validateStartDate(opt.start_day_str)

                setStartTime(opt.start_time_str)
            }
        }
    }


    return (<Box className={classes.body}>
        <PlaysHeader showBackButton={true} showAccountIcon={true} data={token}/>
        <Grid
            container
            direction="column"
            justify="space-around"
            spacing={2}
            className={classes.primLeaderboard}
            style={{fontFamily: 'Kanit', paddingBottom: 80}}
        >
            <Grid item>
                <Typography className={tournCreateStyles.sectionTitle} align="left" style={{
                    color: 'white',
                }}>CREATE A NEW TOURNAMENT</Typography>
            </Grid>
            <Grid item>
                <FormControl style={{width:'100%'}}>
                    <TextField
                        required
                        error={titleError !== null}
                        helperText={titleError}
                        className={classes.input }
                        style={{width:'100%'}}
                        variant="outlined"
                        label="Title"
                        type="text"
                        value={tournTitle}
                        onChange={(e) => setTournTitle(e.target.value)}
                        InputLabelProps={input_label_props}
                    />
                </FormControl>
            </Grid>
            <Grid item style={{flexDirection: 'row', fontFamily: 'Kanit', color: 'rgb(110, 110, 114)', height: 40}}>
                <Link style={{color: 'rgba(255, 255, 255, 0.7)', fontFamily: 'Kanit', marginLeft: 8, textDecoration: 'underline'}}
                 onClick={uploadImageWidget}>
                    Upload cover image
                </Link>&nbsp;(optional)
            </Grid>
            <Grid item style={{marginTop: 12}}>
                <Typography className={tournCreateStyles.sectionTitle} align="left" style={{
                    color: 'rgba(255, 255, 255, 0.7)',
                }}>TOURNAMENT SETTINGS</Typography>
            </Grid>
            <Grid item style={{display: 'none'}}>
                <RadioGroup
                    row
                    defaultValue="one_game"
                    style={{marginLeft: 8}}
                >
                    <FormControlLabel value="one_game"
                                      onChange={onSingleGameRadioClick}
                                      control={<Radio style={{color:"rgb(110, 110, 114)"}}/>}
                                      label="One Game"
                                      className={tournCreateStyles.radioBtnLbl}/>
                    <FormControlLabel value="all_season"
                                      onChange={onAllSeasonRadioClick}
                                      control={<Radio style={{color:"rgb(110, 110, 114)"}}/>}
                                      label="All Season"
                                      className={tournCreateStyles.radioBtnLbl}/>
                </RadioGroup>
            </Grid>

            <Grid item>
                <TextField
                  required
                  select
                  error={sportError !== null}
                  helperText={sportError}
                  className={classes.input }
                  style={{width:'100%'}}
                  variant="outlined"
                  label="Select sport"
                  type="text"
                  value={sport}
                  onChange={onSportSelected}
                  InputLabelProps={input_label_props}>
                    {sportOptions.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                          {option.label}
                      </MenuItem>
                    ))}
                </TextField>
            </Grid>

            {isGameFieldVisible && <Grid item>
                <TextField
                    required
                    select
                    error={gameError !== null}
                    helperText={gameError}
                    className={classes.input }
                    style={{width:'100%'}}
                    variant="outlined"
                    label="Select a game"
                    type="text"
                    value={gameId}
                    onChange={onGameSelected}
                    InputLabelProps={input_label_props}>
                    {gameOptions.map((option) => (
                        <MenuItem key={option.game_id} value={option.game_id}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>}

            <Grid item style={{marginBottom: 24}}>
                <Grid container>
                    <Grid className={tournCreateStyles.selectDefault}>
                        {/*<Typography className={tournCreateStyles.selectLabel} align="left">Format</Typography>*/}
                        <TextField
                            required
                            error={startDateError !== null}
                            helperText={startDateError}
                            className={classes.input }
                            style={{width:'100%'}}
                            variant="outlined"
                            label="Start Date"
                            type="text"
                            value={startDateStr}
                            onChange={(e) => onStartDateStr(e.target.value)}
                            InputLabelProps={{...input_label_props, shrink: Boolean(startDateStr)}}
                            onFocus={() => {setShowCalendar(true)}}
                        />
                    </Grid>
                    <Grid className={tournCreateStyles.selectDefault}>
                        <FormControl style={{width:'100%'}}>
                            <TextField
                                required
                                error={startTimeError !== null}
                                helperText={startTimeError}
                                className={classes.input }
                                style={{width:'100%', color: 'white'}}
                                variant="outlined"
                                label="Start Time"
                                type="text"
                                value={startTime}
                                onChange={(e) => setStartTime(e.target.value)}
                                InputLabelProps={{...input_label_props, shrink: Boolean(startTime)}}
                                placeholder={"example: 1:15 PM"}
                            />
                        </FormControl>
                    </Grid>

                </Grid>
            </Grid>

            {showCalendar && <Calendar
                onChange={onCalendarChange} value={startDate}
            />}

            <Grid item>
                <Typography className={tournCreateStyles.sectionTitle} align="left" style={{
                    color: 'rgba(255, 255, 255, 0.7)',
                }}>FANTASY EXPERIENCE</Typography>
            </Grid>
            {isDriveVsPlayVisible && <Grid item>
                <RadioGroup
                    row
                    defaultValue="drive_by_drive"
                    style={{marginLeft: 8}}
                >
                    <FormControlLabel value="drive_by_drive"
                                      onChange={onDriveByDriveRadioClick}
                                      control={<Radio style={{color:"rgb(110, 110, 114)"}}/>}
                                      label="Drive by Drive"
                                      className={tournCreateStyles.radioBtnLbl}/>
                    <FormControlLabel value="play_by_play"
                                      onChange={onPlayByPlayRadioClick}
                                      control={<Radio style={{color:"rgb(110, 110, 114)"}}/>}
                                      label="Play by Play"
                                      className={tournCreateStyles.radioBtnLbl}/>
                </RadioGroup>
            </Grid>}

            <Grid item>
                <TextField
                    required
                    select
                    error={minBetError !== null}
                    helperText={minBetError}
                    className={classes.input}
                    style={{width:'100%'}}
                    variant="outlined"
                    label="Minimum call amount"
                    type="text"
                    value={minBetAmt}
                    onChange={(e) => setMinBetAmt(e.target.value)}
                    InputLabelProps={input_label_props}>
                    {min_bet_options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            <Grid item>
                <TextField
                    required
                    select
                    error={scoreSysError !== null}
                    helperText={scoreSysError}
                    className={classes.input}
                    style={{width:'100%'}}
                    variant="outlined"
                    label="Score system"
                    type="text"
                    value={scoreSystem}
                    onChange={(e) => setScoreSystem(e.target.value)}
                    InputLabelProps={input_label_props}>
                    {score_system_options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            {/*<Grid item>
                <TextField
                    required
                    select
                    error={sportError !== null}
                    helperText={sportError}
                    className={classes.input }
                    style={{width:'100%'}}
                    variant="outlined"
                    label="Select a sport"
                    type="text"
                    value={sport}
                    onChange={(e) => setSport(e.target.value)}
                    InputLabelProps={input_label_props}>
                    {sport_options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>*/}

            <Grid item>
                <Typography className={tournCreateStyles.sectionTitle} align="left" style={{
                    color: 'rgba(255, 255, 255, 0.7)',
                    marginTop: 30,
                    marginBottom: 4
                }}>RULES</Typography>
            </Grid>

            <Grid item>
                <Grid container>
                    <Grid className={tournCreateStyles.selectThreeCol}>
                        <TextField
                            required
                            error={numPlayersError !== null}
                            helperText={numPlayersError}
                            className={classes.input }
                            style={{width:'100%', color: 'white'}}
                            variant="outlined"
                            label="Seats"
                            type="number"
                            value={numPlayers}
                            onChange={(e) => setNumPlayers(e.target.value)}
                            InputLabelProps={input_label_props}/>
                    </Grid>
                    <Grid className={tournCreateStyles.selectThreeCol}>
                        {/*<Typography className={tournCreateStyles.selectLabel} align="left">Format</Typography>*/}
                        <TextField
                            required
                            error={entryFeeError !== null}
                            helperText={entryFeeError}
                            className={classes.input }
                            style={{width:'100%'}}
                            variant="outlined"
                            label="Entry fee"
                            type="number"
                            value={entryFee}
                            onChange={(e) => setEntryFee(e.target.value)}
                            InputLabelProps={input_label_props}
                        />
                    </Grid>
                    <Grid className={tournCreateStyles.selectThreeCol}>
                        <FormControl style={{width:'100%'}}>
                            <TextField
                                required
                                error={prizeError !== null}
                                helperText={prizeError}
                                className={classes.input }
                                style={{width:'100%', color: 'white'}}
                                variant="outlined"
                                label="Prize money"
                                type="number"
                                value={prize}
                                onChange={(e) => setPrize(e.target.value)}
                                InputLabelProps={input_label_props}/>
                        </FormControl>
                    </Grid>

                </Grid>
            </Grid>

            {/*<Grid item>*/}
            {/*    <Typography className={tournCreateStyles.sectionTitle} align="left" style={{*/}
            {/*        color: 'rgba(255, 255, 255, 0.7)',*/}
            {/*        marginTop: 30*/}
            {/*    }}>PERMISSIONS</Typography>*/}
            {/*</Grid>*/}

            {/*<Grid item>*/}
            {/*    <Grid container style={{height: 56, paddingTop: 6, borderBottom: '1px solid rgba(255, 255, 255, 0.7)'}}>*/}
            {/*        <Grid item xs={8}>*/}
            {/*            <Typography style={{textAlign: 'left', paddingLeft: 8, height: 50, lineHeight: '50px', fontFamily: 'Kanit', fontSize: 16, fontWeight: 400, color: 'white'}}>Only invited players can join</Typography>*/}
            {/*        </Grid>*/}
            {/*        <Grid item xs={4} style={{textAlign: 'center'}}>*/}
            {/*            <YellowSwitch*/}
            {/*                value={onlyInvitedPlayersCanJoin}*/}
            {/*                onChange={onChangeOnlyInvitedPlayersCanJoin}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}

            {/*<Grid item>*/}
            {/*    <Grid container style={{height: 56, paddingTop: 6, borderBottom: '1px solid rgba(255, 255, 255, 0.7)'}}>*/}
            {/*        <Grid item xs={8}>*/}
            {/*            <Typography style={{textAlign: 'left', paddingLeft: 8, height: 50, lineHeight: '50px', fontFamily: 'Kanit', fontSize: 16, fontWeight: 400, color: 'white'}}>Allow others to invite new players</Typography>*/}
            {/*        </Grid>*/}
            {/*        <Grid item xs={4} style={{textAlign: 'center'}}>*/}
            {/*            <YellowSwitch*/}
            {/*                value={playersCanInviteMore}*/}
            {/*                onChange={onChangePlayersCanInviteMore}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}

            <Grid item>
                <Button onClick={submitTournamentForm} className={tournCreateStyles.createTournamentBtn}>
                    CREATE TOURNAMENT
                </Button>
            </Grid>

        </Grid>
    </Box>)
}
export default TournamentCreate;
