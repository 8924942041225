import { Badge, Button, Grid } from "@material-ui/core";
import React, {useEffect, useState, useRef} from 'react';
import { useStyles } from "../layouts/styles";

import "./BetButton.css";
import CopyrightIcon from "@material-ui/icons/Copyright";

// import CoinIcon from "../../icons/coin.png"


const PotentialRetunAlert = ({ visible, duration, onDurationEnd, betAmount, setShowPotentialReturn, tournament}) => {
    const [isVisible, setVisibility] = useState(null);
    const classes = useStyles();

    useEffect(() => {
        setVisibility(visible);
    }, [visible]);

    if (!isVisible) return null;

    if (duration) {
        setTimeout(() => {
            setVisibility(false);
            setShowPotentialReturn(false);

            if (onDurationEnd) {
                onDurationEnd(false);
            }
        }, duration);
    }

    const handleDismiss = (e) => {
        e.preventDefault();
        setShowPotentialReturn(false);
    };

    const is_most_bets_won_scoring = (tournament?.score_system === 'most_bets_won')

  return (
        <Button
            className={classes.potentialPayoutAlert}
        >
            <Grid container spacing={12} justify="space-between" alignItems="center">
                <Grid item xs={7} style={{flexDirection: 'row'}}>
                    <div className="betItem" style={{float: 'left'}}>
                        {is_most_bets_won_scoring ? 'Your' : 'Your ' + betAmount}
                    </div>
                    {!is_most_bets_won_scoring && <CopyrightIcon style={{float: 'left', height: 15, marginTop: 4}}/>}
                    <div className="betItem" style={{float: 'left'}}>
                        call was placed
                    </div>
                </Grid>
                <Grid item xs={5} onClick={handleDismiss}>
                    <div  className="betItemDismiss">
                        DISMISS
                    </div>

                </Grid>
            </Grid>
        </Button>
  ); };
export default PotentialRetunAlert;
