import axios from "axios";
import constants from '../constants'

//for development purpose, will be refactor - bind team name to ali url
// export function getNbaGameProbabilities() {
//     return axios.get(constants.API_HOST + '/app/nba/probabilities.json?team=gsw');
//   }

export function getNbaGameProbabilities(team) {
  // console.log('NBA probabilities = ', team)
  return axios.get(constants.API_HOST + '/app/nba/probabilities.json', {
    params: {
      team: team.toLowerCase()
    }
  });
}
