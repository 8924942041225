import {getRunPassProbabilityData} from "../hooks/useProb";
import {targetPlayData} from './getPlaysOdds';
import constants from "../constants";
import {convertProbToOdds} from "../utils/probabilityUtils";
export let players = [];
let betType = "";
export const getRunPassData = async (gameId, item, team = null, setRunPlayers = null, setPassPlayers = null,
    setPlayerTargets = null, setPositionTargets = null, setPlayResults = null) => {
    let uri = "";
    let data = [];
    if(betType !== item.name) {
      players = [];
      betType = item.name;
    }

    switch (item.name) {
      case "Run play":
        // let runProbability
        // if (plays) {
        //   plays.forEach(play => {
        //     if (play.name === "Run play") {
        //       // console.log('play.odds = ', play.odds)
        //       // parentOdd = parseFloat(play.odds.slice(0, -1))
        //       runProbability = play.probability
        //     }
        //   });
        // }
        uri = constants.API_HOST + "/app/run_probabilities.json";
        // console.log('Get Run data')
        getRunPassProbabilityData(uri, { team, game_id: gameId })
            .then((response) => {
              if (response?.data) {
                // console.log('Run data response.data = ', response.data)
                data = mappRunPassData(response.data);
                // console.log('parentOdd = ', parentOdd)

                // calculate parlay odds as odds for specific player is already assuming
                // play = "Pass"
                if (data) {
                  data.forEach(item => {
                    // item.odds = ((1 + parentOdd) * (1 + parseFloat(item.odds)) - 1).toFixed(2);
                    let conditionalProb = item.probability
                    // console.log(item)
                    // item.odds = (1.0 / conditionalProb).toFixed(1);
                    item.odds = convertProbToOdds(conditionalProb);
                    // console.log('item.odds = ', item.odds)
                  });
                }
                players = data
                if (setRunPlayers)
                  setRunPlayers(data)
                // setPlayers(data);
                return data;
              }
            }).catch((error) => {
              console.log("Error getting the game status:: ", error);
            });
        break;
      case "Pass play":
        // let passProbability
        // if (plays) {
        //   plays.forEach(play => {
        //     if(play.name === "Pass play") {
        //       // parentOdd = parseFloat(play.odds.slice(0, -1))
        //       passProbability = play.probability
        //     }
        //   });
        // }
        uri = constants.API_HOST + "/app/pass_probabilities.json";
        // const start_time_ms = Date.now()
        getRunPassProbabilityData(uri, { team, game_id: gameId })
            .then((response) => {
                // console.log('Pass Response took ', (Date.now() - start_time_ms), " ms")
              if (response?.data) {
                data = mappRunPassData(response.data);
                // calculate parlay odds as odds for specific player is already assuming
                // play = "Pass"
                if (data) {
                  data.forEach(item => {
                    // item.odds = ((1 + parentOdd) * (1 + parseFloat(item.odds)) - 1).toFixed(2);
                    let conditionalProb = item.probability
                    item.odds = convertProbToOdds(conditionalProb);
                  });
                }
                players = data
                if (setPassPlayers) {
                    // console.log('setPassPlayers data = ', data)
                    setPassPlayers(data)
                } else {
                    // console.log('NO setPassPlayers')
                }

                return data;
              }
            }).catch((error) => {
              console.log("Error getting the game status:: ", error);
            });
        break;
      case "Position target":
        // console.log('Position target targetPlayData = ', targetPlayData)
        if (targetPlayData) {
          targetPlayData.forEach(item => {
            if (item.type === "POSITION" || item.type === "TARGETED POS") {
              data.push(item);
            }
          });
        }
        data = data.sort((a, b) => {return a.odds - b.odds});
        if (setPositionTargets) {
          console.log('have setPositionTargets')
          setPositionTargets(data)
        } else {
          console.log('no setPositionTargets')
        }
        players = data
        return data;
      case "Player target":
        // console.log('Player target targetPlayData = ', targetPlayData)
        if (targetPlayData) {
          targetPlayData.forEach(item => {
            if (item.type === "TARGET" || item.type === "TARGETED PLAYER") {
              data.push(item);
            }
          });
        }
        data = data.sort((a, b) => {return a.odds - b.odds});
        players = data

        if (setPlayerTargets)
          setPlayerTargets(data)

        return data;
      case "Play Result":
        if (targetPlayData) {
          targetPlayData.forEach(item => {
            if (item.type !== "TARGET" && item.type !== "POSITION"
              && item.type !== "TARGETED PLAYER" && item.type !== "TARGETED POS") {
              delete item.name;
              item.name = item.type;
              data.push(item);
            }
          });
        }
        data = data.sort((a, b) => {return a.odds - b.odds});
        if (setPlayResults)
          setPlayResults(data)
        players = data
        return data;
      default:
        data = [];
        players = data
    }
  }

  const mappRunPassData = (response) => {
    let mappedPlayers = [];
    if (response) {
      let i = 0;
      response.forEach(item => {
        let mappedItem = {}
        let probability = parseFloat(item.AvgDVPercent) / 100
        // if(probability === 0)
        //   probability = 1
        // let odds = ((1 - probability) / probability).toFixed(1);
        // mappedItem.odds = (1.0 / probability);
        mappedItem.odds = convertProbToOdds(probability);
        mappedItem.id = i;
        mappedItem.name = item.DVItem;
        mappedItem.probability = probability;
        mappedPlayers.push(mappedItem);
        i++;
      })
    }
    return mappedPlayers;
  }
