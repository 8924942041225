import { Avatar,Box, Grid, Typography } from "@material-ui/core";
import SportsFootballIcon from "@material-ui/icons/SportsFootball";
import React, { useEffect, useRef, useState } from "react";
import { getLeaderboardData } from "../../../hooks/useLeaderboard";
import { getGameInfo } from "../../../hooks/useGame";
import LeaderButton from "../../ui/LeaderButton";
import PlaysHeader from "../../ui/PlaysHeader";
import { useStyles } from "../styles";
import "./Leaderboard.css";
import CopyrightIcon from "@material-ui/icons/Copyright";

function Leaderboard({token, setIsShowBottomBar}) {
  const [showGames, setShowGames] = useState(true);
  const [gameStatus, setGameStatus] = useState({});
  const [tournamentId, setTournamentId] = useState(0)
  const [tournamentTitle, setTournamentTitle] = useState(null)
  const [tournScoreSystem, setTournScoreSystem] = useState(null)
  const [leaderboardData, setLeaderboardData] = useState([]);

  const classes = useStyles();

  let interval = useRef();

  const mappLeaderboardData = (response) => {
    // response = response.sort(function(a, b) { return parseFloat(b.total_coins) - parseFloat(a.total_coins)});
    let mappedData = [];
    let i = 1;
    response.forEach(element => {
      let object = {};
      object.id = i;
      object.name = element.username;
      object.bets = element.bets_won;
      // object.bets = "0";
      object.won = element.total_coins;
      mappedData.push(object);
      i++;
    });
    setLeaderboardData(mappedData);
  }

  const getGameStatusInfo = (queryParams) => {
    gameStatusFetch(queryParams);
    interval.current  = setInterval(() => {
      gameStatusFetch(queryParams);
    }, 1000)
  }

  const getLeaderboardInfo = (tournament_id) => {
    leaderboardInfoFetch(tournament_id);
    interval.current  = setInterval(() => {
      leaderboardInfoFetch(tournament_id);
    }, 2000)
  }

  const gameStatusFetch = (queryParams) => {
    getGameInfo(queryParams)
    .then((response) => {
      if (response?.data)
        setGameStatus(response.data);
    }).catch((error) => {
      console.log("Error getting the game status:: ", error);
    });
  }

  const leaderboardInfoFetch = (tournament_id) => {
    // console.log('leaderboardInfoFetch, tournament_id = ', tournament_id)
    getLeaderboardData(tournament_id).then(response => {
      if (response?.data) {
        mappLeaderboardData(response.data);
      }
    }).catch(error => {
      console.log("Error in getting leaderboard data: ", error);
    });
  }

  useEffect(() => {
    setIsShowBottomBar(true)
    return () => {
      setIsShowBottomBar(false)
    }
  }, [])

  useEffect(() => {
    // let queryParams = { params: { user_id: token.id } }
    let tournamentLocal = localStorage.getItem("tournament");
    let tournament_id = null
    if (tournamentLocal) {
      let tournamentParsed = JSON.parse(tournamentLocal);
      if (tournamentParsed) {
        console.log('Calls tournamentParsed = ', tournamentParsed);
        // console.log('tournamentParsed.id ', tournamentParsed.id)

        setTournamentId(tournamentParsed.id)
        setTournamentTitle(tournamentParsed.title)
        setTournScoreSystem(tournamentParsed.score_system)
        tournament_id = tournamentParsed.id
      }
    }
    getLeaderboardInfo(tournament_id);
    // getGameStatusInfo(queryParams);
    return function cleanup() {
      if (interval)
        clearInterval(interval.current);
    }
  }, [token]);

  if (!tournamentId) {
    return (
        <Box className={classes.body}>
          <PlaysHeader showBackButton={false} showAccountIcon={true}/>
          <Grid
              container
              direction="column"
              justify="space-around"
              spacing={2}
              className={classes.primLeaderboard}
          >
            <Grid item>
              <Typography align="left">PLEASE SELECT A TOURNAMENT</Typography>
            </Grid>
          </Grid>
        </Box>
    )
  }

  return (
    <Box className={classes.body}>
      <PlaysHeader showBackButton={false} showAccountIcon={true}/>
      <Grid
        container
        direction="column"
        justify="space-around"
        spacing={2}
        className={classes.primLeaderboard}
      >
        <Grid item>
          {tournamentTitle !== null && <Typography align="left">{tournamentTitle}</Typography>}
        </Grid>
        <Grid item>
          <Typography align="left">LEADERBOARD</Typography>
        </Grid>
        <Grid item>
      <Grid container spacing={4} justify="left" alignItems="center" style={{height: 30, marginTop: 10}}>
        <Grid item xs={tournScoreSystem === 'most_bets_won' ? 9: 6} style={{height: 30, paddingTop: 4}}>
          <Typography style={{
            color: '#FDC934',
            fontFamily: 'Kanit',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '12px',
            lineHeight: '100%',
            letterSpacing: '0.1em'
          }} align="left">
            RANK</Typography>
        </Grid>
        <Grid item xs={3} style={{height: 30, paddingTop: 4}}>
          <Typography style={{
            color: '#FDC934',
            fontFamily: 'Kanit',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '12px',
            lineHeight: '100%',
            letterSpacing: '0.1em',
          }} align="center">
            CALLS WON</Typography>
        </Grid>
        {tournScoreSystem !== 'most_bets_won' && <Grid item xs={3} style={{flexDirection: 'row', height: 30, paddingTop: 4}}>
          <Typography style={{
            color: '#FDC934',
            fontFamily: 'Kanit',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '12px',
            lineHeight: '100%',
            letterSpacing: '0.1em',
            float: 'left'
          }} align="left">
            TOTAL</Typography>
          <CopyrightIcon style={{float: 'left', height: 13, color: '#FDC934'}}/>
        </Grid>}
      </Grid>
        </Grid>
        <Grid className={classes.leaderboardContainer}>
          { leaderboardData.map(item => (
        <Grid item className={classes.playerLeaderBoard}>
             <LeaderButton
               className={classes.button}
               className={(item.name === token.username) ? classes.activeButton : classes.button}
               content={item}
               icon={<SportsFootballIcon style={{color: '#FDC934'}}/>}
               setShowBets={setShowGames}
               badgeContent={item.won}
               spanClassName={(item.name === token.username) ? classes.activeBtnSpan : classes.btnLeaderSpan}
               betClassName={(item.name === token.username) ? classes.leaderBetActive : classes.leaderBets}
               tournScoreSystem={tournScoreSystem}
             />
        </Grid>
          )) }
        </Grid>
      </Grid>

    </Box>
  );
}
export default Leaderboard;
