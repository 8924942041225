import { Badge, Button, Grid } from "@material-ui/core";
import React, {useEffect, useState, useRef} from 'react';
import { useStyles } from "../layouts/styles";

import "./BetButton.css";


const PotentialRetunAlert = ({ visible, duration, onDurationEnd, betErrorMsg, setShowBetErrorAlert}) => {
  const [isVisible, setVisibility] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    setVisibility(visible);
  }, [visible]);

  if (!isVisible) return null;

  if (duration) {
    setTimeout(() => {
      setVisibility(false);
      setShowBetErrorAlert(false);

      if (onDurationEnd) {
        onDurationEnd(false);
      }
    }, duration);
  }

  const handleDismiss = (e) => {
    e.preventDefault();
    setShowBetErrorAlert(false);
  };


  return (
    <Button
      className={classes.betErrorAlert}
    >
      <Grid container spacing={12} justify="space-between" alignItems="center">
        <Grid item xs={7} style={{flexDirection: 'row'}}>
          <div className="betItem" style={{float: 'left'}}>
            {betErrorMsg}
          </div>
        </Grid>
        <Grid item xs={5} onClick={handleDismiss}>
          <div className="betItemDismiss" style={{color: 'white'}}>
            DISMISS
          </div>

        </Grid>
      </Grid>
    </Button>
  ); };
export default PotentialRetunAlert;
