import { Magic } from "magic-sdk"

// Setting network to point to Chiliz production
export const magic = new Magic('pk_live_4E42AB01BCFF3A8B', {
    network: {
        rpcUrl: 'https://rpc.ankr.com/chiliz',
        chainId: 88888,
    }
});

// Ethereum
// export const magic = new Magic('pk_live_4E42AB01BCFF3A8B');
