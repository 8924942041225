import { makeStyles } from "@material-ui/core";
export const useTournDetailsStyles = makeStyles((theme) => ({
    tournTitle: {
        fontSize: 18,
        lineHeight: '22px',
        letterSpacing: '0.02em',
        fontFamily: 'Kanit',
        height: 22,
        color: '#FDC934'
    },
    tournType: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '16px',
        letterSpacing: '0.1em',
        fontFamily: 'Kanit',
        height: 16,
        marginTop: 12,
        color: 'rgba(255, 255, 255, 0.5)'
    },
    main: {
        paddingLeft: 16,
        paddingRight: 16
    },
    rules: {
        marginTop: 24
    },
    rulesHeader: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '16px',
        letterSpacing: '0.1em',
        fontFamily: 'Kanit',
        height: 16,
        marginTop: 12,
        color: 'white'
    },
    rulesList: {

    },
    rulesItem: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '16px',
        // letterSpacing: '0.1em',
        fontFamily: 'Kanit',
        // height: 16,
        marginTop: 3,
        color: 'rgba(255, 255, 255, 0.6)'
    }
}))
