import { makeStyles } from "@material-ui/core";
export const useCreateTournStyles = makeStyles((theme) => ({
    sectionTitle: {
        fontSize: 14,
        lineHeight: '17px',
        letterSpacing: '0.1em',
        marginLeft: 10,
        fontFamily: 'Kanit'
    },
    radioBtn: {
        color:"rgb(110, 110, 114)"
    },
    radioBtnLbl: {
        color: 'white',
        fontFamily: 'Kanit',
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 0
    },
    selectLabel: {
        color: 'white',
        fontFamily: 'Kanit',
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '36px',
        height: 36,
        paddingLeft: 12
    },
    selectDefault: {
        height: 50,
        // borderBottom: '1px solid rgba(255, 255, 255, 0.7)',
        // width: 'calc(50% - 24px)',
        // marginLeft: 12,
        // marginRight: 12,
        width: 'calc(50% - 6px)',
        marginRight: 6,
        cursor: 'pointer'
    },
    selectThreeCol: {
        height: 50,
        width: 'calc(33% - 6px)',
        marginRight: 6,
        cursor: 'pointer'
    },
    selectOptionsContainer: {
        width: 'calc(50% - 24px)',
        marginLeft: 12,
        marginRight: 12,
        cursor: 'pointer',
        backgroundColor: 'rgb(45, 52, 54)',
        borderRadius: 4
    },
    createTournamentBtn: {
        marginTop: 12,
        fontFamily: "Kanit",
        fontWeight: 300,
        letterSpacing: 0.02,
        backgroundColor: 'rgb(255, 197, 31)',
        color: 'rgba(255, 255, 255, 0.7)',
        borderRadius: 4,
        width: '100%',
        height: 60,
        "&:hover": {
            background: "rgb(255, 197, 31)"
        }
    }
}))
