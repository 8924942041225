import React, {useEffect, useState}from "react";
import { useStyles } from "../layouts/styles";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid, Badge } from "@material-ui/core";

import atlLogo from "../icons/nfl/atl.png";
import cleLogo from "../icons/nfl/cle.png";
import dalLogo from "../icons/nfl/dal.png";
import denLogo from "../icons/nfl/den.png";
import jaxLogo from "../icons/nfl/jax.png";
import lacLogo from "../icons/nfl/lac.png";
import nygLogo from "../icons/nfl/nyg.png";
import sfLogo from "../icons/nfl/sf.png";
import wasLogo from "../icons/nfl/was.png";

import "./BetButton.css";

const StyledBadge = withStyles((theme) => ({
  badge: {
    top: "50%",
    radius: 4,
    width: 42,
    height: 24,
    padding: 3,
    borderRadius: 4,
    background: "rgba(31, 38, 48, 0.2)",
    border: "1px solid rgba(255, 255, 255, 0.2)",
  },
}))(Badge);

const MatchButton = ({ content, setShowBets, icon, badgeContent, gameType, onClick, lastOutcome }) => {
  const [lastOutcomeMsg, setLastOutcomeMsg] = useState("");
  const classes = useStyles();

  useEffect(() => {
    localStorage.setItem('matchSelected', content)
  }, [content]);

  useEffect(() => {
    let lastOutcomeMsg = "";
    // console.log('content = ', content)
    if (lastOutcome) {
      lastOutcomeMsg = mappLastOutcomeData(lastOutcome);
    }
    setLastOutcomeMsg(lastOutcomeMsg);
  }, [lastOutcome]);

  const getGameQuarterWithSuffix = (num) => {
    return ["st", "nd", "rd"][((((num + 90) % 100) - 10) % 10) - 1] || "th";
  };

  const mappLastOutcomeData = (lastOutcome) => {
    // let lstOutcomeMsg = (lastOutcome? "last play: " + lastOutcome.rpk[0].toUpperCase() + lastOutcome.rpk.slice(1).toLowerCase() + " Play, " : "");
    let lstOutcomeMsg = ''
    if (lastOutcome && lastOutcome.rpk && lastOutcome.rpk.length > 0 && lastOutcome.rpk[0])
      // lstOutcomeMsg = "Last Play: " + lastOutcome.rpk[0].toUpperCase() + lastOutcome.rpk.slice(1).toLowerCase() + " Play, ";
      lstOutcomeMsg = "Last Play: " + lastOutcome.rpk + ", ";

    if (lastOutcome && lastOutcome.tgt && lastOutcome.tgt !== "")
      lstOutcomeMsg += lastOutcome.tgt;
    else if(lastOutcome && lastOutcome.pos && lastOutcome.pos !== "")
      lstOutcomeMsg += lastOutcome.pos + " ";

    for (const property in lastOutcome) {
      switch(property) {
        case 'firstdn':
          if(lastOutcome.firstdn === 1) {
            lstOutcomeMsg += ", First Down";
          }
          break;
        case 'penalty':
          if(lastOutcome.penalty === 1) {
            lstOutcomeMsg += ", Penalty";
          }
          break;
        case 'touchdown':
          if(lastOutcome.touchdown === 1) {
            lstOutcomeMsg += ", Touchdown";
          }
          break;
        case 'turnover':
          if(lastOutcome.turnover === 1) {
            lstOutcomeMsg += ", Turnover";
          }
          break;
        default:
          break;
      }
    }
    return lstOutcomeMsg;
  };

  const getDownAndDistanceStr = () => {
    return content && content.down ? content.down + getGameQuarterWithSuffix(Number(content.down)) + " and "  + content.distance + " on " + content.field_position : ""
  }

  let primText =  content.down + getGameQuarterWithSuffix(Number(content.down)) + " and "  + content.distance + " on " + content.field_position;  // + " - " + content.quarter + "Q";
  // console.log('SF Logo = ' + `../icons/nfl/${content.away_team.toLowerCase()}.png`)

  // let nflAwayLogo = nygLogo
  // let nflHomeLogo = wasLogo
  const useTomsLastPlay = true
  let lastPlayStr
  let currGameSituation = "" // e.g. down and distance + quarter
  if (useTomsLastPlay) {
    lastPlayStr = content && content.last_play ? 'Last Play: ' + content.last_play : ''
    if (content && content.last_play_was_td) {
      currGameSituation = 'PAT'
    } else if ((lastPlayStr.toLowerCase().indexOf('field goal') >= 0
            && lastPlayStr.toLowerCase().indexOf('field goal missed') === -1)
      || lastPlayStr.indexOf('Extra Point') >= 0) {
      currGameSituation = 'SWITCHING POSSESSION'
    } else if (lastPlayStr.toLowerCase().indexOf('end 2nd quarter') >= 0) {
      currGameSituation = 'HALFTIME'
    } else {
      currGameSituation = getDownAndDistanceStr()
    }
  } else {
    lastPlayStr = lastOutcomeMsg

    // NEEDS FINISHING TO DETECT TOUCHDOWNS/FIELDGOALS
    const last_play_was_td = false
    if (last_play_was_td) {
      currGameSituation = 'SWITCHING POSSESSION'
    } else {
      currGameSituation = getDownAndDistanceStr()
    }
  }

  return (
    <Button
      className={gameType === "NFL"? classes.matchButton : classes.matchButtonNba}
      startIcon={icon}
      onClick={(onClick)
      ? () => onClick(content)
        : undefined
      }
    >
    <div className="wrapper">
      <div >
        <Grid item>
          <p className={classes.matchBtnText}>{lastPlayStr}</p>
        </Grid>
        <Grid container alignItems="center" justify="space-around" spacing={3}>
          <div className="flag-wrapper">
            <Grid item className="score">
              {content.away_team}
            </Grid>
          </div>
          <Grid item>
              <div className="score">{content.defense_team === content.away_team ? content.defense_score : content.offense_score}</div>
          </Grid>
          <Grid item> : </Grid>
          <Grid item>
              <div className="score">{content.defense_team === content.away_team ?  content.offense_score : content.defense_score}</div>
          </Grid>
          <div className="flag-wrapper">
            <Grid item className="score">
              {content.home_team}
            </Grid>
          </div>
        </Grid>
      </div>
      {gameType === "NFL" && <div className={content.offense_team && content.offense_team.length > 0 ? (content.offense_team === content.away_team ? "ballpossession-indicator-left" : "ballpossession-indicator-right") : null}></div>}
      {gameType === "NFL" && <div className="primline"></div>}
      <div className="primtext"> <span>{currGameSituation}</span><span className="primQuarter"> {content && content.quarter ? " - " + content.quarter + "Q": ""}</span></div>
    </div>
    </Button>
  );
};
export default MatchButton;
// <Container className={classes.quota}>
// <div className="quotaText">0.3x</div>
// </Container>
