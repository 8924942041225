import {
  getGameProbabilities,
  getRunPassProbabilityData,
} from "../hooks/useProb";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import CompassCalibrationIcon from "@material-ui/icons/CompassCalibration";
import FlagIcon from "@material-ui/icons/Flag";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import constants from "../constants";
import {convertProbToOdds} from "../utils/probabilityUtils";
export const targetPlayData = [];

const getLoadingBetButtons = () => {
  return [
    { icon: <DirectionsRunIcon />,      name: "Run play",        odds: "LOADING"},
    { icon: <CallSplitIcon />,          name: "Pass play",       odds: "LOADING"},
    { icon: <CompassCalibrationIcon />, name: "Position target", odds: "LOADING"},
    { icon: <RadioButtonCheckedIcon />, name: "Player target",   odds: "LOADING"},
    { icon: <FlagIcon />, name: "Play Result", odds: "LOADING"}
  ]
}

export let plays = getLoadingBetButtons()

const setBetButtonsToNoResults = () => {
  plays.forEach(element => {
    element.odds = "N/A"
  })
  targetPlayData.length = 0;
}

const setMainPosibility = (items) => {
  if (items?.length > 0) {
    items.forEach(element => {
      var foundIndex = plays.findIndex(x => x.name === element.name);
      plays[foundIndex] = element;
    });
  }
};

const renderPlays = (maxRunOdd, maxPassOdd, gameRunOdds, gamePassOdds,
  runProb, passProb) => {
  let plays = [];
  let runOddsStr = gameRunOdds.toString()
  let passOddsStr = gamePassOdds.toString()
  if (runOddsStr && !runOddsStr.endsWith("x"))
    runOddsStr += 'x'
  if (passOddsStr && !passOddsStr.endsWith("x"))
    passOddsStr += 'x'
  if (maxRunOdd && maxRunOdd !== "-Infinity" && maxRunOdd !== gamePassOdds) {
    runOddsStr = runOddsStr + " - " + maxRunOdd + "x"
  }
  if (maxPassOdd && maxPassOdd !== "-Infinity" && maxPassOdd !== gamePassOdds) {
    passOddsStr = passOddsStr + " - " + maxPassOdd + "x"
  }

  // console.log('runOddsStr = ', runOddsStr)
  plays.push({
    icon: <DirectionsRunIcon />,
    name: "Run play",
    odds: runOddsStr,
    probability: runProb
    // odds: "5.0x-10.0x",
  });
  plays.push({
    icon: <CallSplitIcon />,
    name: "Pass play",
    odds: passOddsStr,
    probability: passProb
  });
  return plays;
};

const getMinProbability = (probabilities, prob_fam) => {
  if (!probabilities || probabilities.length === 0)
    return null

  let min_probability = null
  probabilities.forEach((array) => {
    if (array) {
      array.forEach(item => {
        // if (prob_fam === 'TARGETED RECEIVER' && item.ProbFam === prob_fam) {
        //   console.log(item.DVItem + ': ' + item.AvgDVPercent)
        // }
        if (item.ProbFam === prob_fam && (!min_probability || min_probability > item.AvgDVPercent)) {
          min_probability = item.AvgDVPercent
        }
      })
    }
  })

  if (!min_probability) {
    return null
  }
  return min_probability / 100
  // console.log('min_probability = ', min_probability)
  //
  // let probability_decimal = parseFloat(min_probability) / 100
  // return ((1 - probability_decimal) / probability_decimal).toFixed(1)
}

// const getOddsRange = async (uri, parentOdd) => {
//   let probs = [];
//   let maxOdd = await getRunPassProbabilityData(uri)
//     .then((response) => response.data)
//     .then((data) => {
//       data.forEach((item) => {
//         let passProbs = Number(parseFloat(item.AvgDVPercent) / 100).toFixed(2);
//         let gamePassOdds = ((1 - passProbs) / passProbs).toFixed(1);
//         probs.push(gamePassOdds);
//       });
//       let maxOdd = Math.max.apply(Math, probs);
//       let odds = (
//         (1 + parseFloat(parentOdd)) * (1 + parseFloat(maxOdd)) -
//         1
//       ).toFixed(2);
//       return odds;
//     })
//     .catch((error) => {
//       console.log("Error getting the game status:: ", error);
//     });
//   return maxOdd;
// };

const setUpRunPassOddsOptions = (probabilityResponse) => {
  let mainOdds = [];

  if (probabilityResponse?.length > 0) {
    probabilityResponse.forEach((outerItem) => {
      if (outerItem && outerItem.length > 0) {
        outerItem.forEach((innerItem) => {
          if (innerItem.ProbFam === "RUN/PASS") {
            if (innerItem.DVItem === "RUN" || innerItem.DVItem === "Run"
              || innerItem.DVItem === "PASS" || innerItem.DVItem === "Pass") {
              // console.log('RUN probability = ', innerItem.AvgDVPercent)
              let probabilityDiv100 = parseFloat(innerItem.AvgDVPercent) / 100
              let odds = (1 / probabilityDiv100).toFixed(1);
              mainOdds.push({
                dvItem: innerItem.DVItem.toUpperCase(),
                gameOdd: odds,
                probability: probabilityDiv100,
                avgDVPercent: innerItem.AvgDVPercent
              });
            }
          }
        });
      }
    });
  }
  return mainOdds;
};

export const fetchData = async (gameId) => {
  // console.log('fetchData gameId = ', gameId)
  if (!gameId)
    return
  let dataResponse = [];
  let data = [];
  let maxRunOdd = 0;
  let maxPassOdd = 0;
  let runGameOdd = 0;
  let passGameOdd = 0;
  let targetResultData = [];
  dataResponse = await getGameProbabilities(gameId);
  // console.log('prob response = ', dataResponse)
  const gameProb = setUpRunPassOddsOptions(dataResponse);
  // console.log('gameProb = ', gameProb)
  if (!gameProb || gameProb.length === 0) {
    setBetButtonsToNoResults()
    return
  }
  let runProb = null
  let passProb = null
  gameProb.forEach((game) => {
    if (game.dvItem === "RUN" || game.dvItem === "Run") {
    //   maxRunOdd = await getOddsRange(
    //       constants.API_HOST + "/app/run_probabilities.json",
    //     game.gameOdd
    //   );
      runGameOdd = game.gameOdd;
      runProb = game.probability;
    } else {
      // maxPassOdd = await getOddsRange(
      //     constants.API_HOST + "/app/pass_probabilities.json",
      //   game.gameOdd
      // );
      passGameOdd = game.gameOdd;
      passProb = game.probability;
    }
    // data = renderPlays(maxRunOdd, maxPassOdd, runGameOdd, passGameOdd);
    // // data = renderPlays(10, 9, 1.1, 2.2);
    // setMainPosibility(data);
  });
  let minRusherProb = getMinProbability(dataResponse, 'RUSHER')
  let minReceiverProb = getMinProbability(dataResponse, 'TARGETED RECEIVER')

  // console.log('passGameOdd = ', passGameOdd)
  // console.log('maxPassOdd = ', maxPassOdd)

  // if (runGameOdd && maxRunOdd > 0)
  //   maxRunOdd = ((1 + runGameOdd) * (1 + maxRunOdd) - 1).toFixed(1)
  // if (passGameOdd && passGameOdd > 0)
  //   maxPassOdd = ((1 + passGameOdd) * (1 + maxPassOdd) - 1).toFixed(1)
  if (minRusherProb && runProb) {
    // console.log('minRusherProb = ' + minRusherProb)
    // console.log('runProb = ' + runProb)
    const totalRusherProb = minRusherProb
    // console.log('totalRusherProb = ' + totalRusherProb)
    maxRunOdd = (1.0 / totalRusherProb).toFixed(1)
  }
  if (minReceiverProb && passProb) {
    const totalPasserProb = minReceiverProb
    maxPassOdd = (1.0 / totalPasserProb).toFixed(1)
  }

  data = renderPlays(maxRunOdd, maxPassOdd, runGameOdd, passGameOdd,
      runProb, passProb);
  // console.log('data = ', data)
  // data = renderPlays(10, 9, 1.1, 2.2);
  setMainPosibility(data);

  // these functions are very poorly named
  targetResultData = collectNonRunPassProb(dataResponse);
  let playsTarget = renderTargetPositionPlays(targetResultData);
  setTargetPlayPosibility(targetResultData);
  setMainPosibility(playsTarget);
};

  const setTargetPlayPosibility = (response) => {
    let i = 0;
    targetPlayData.length = 0;
    // targetPlayData is an array that stores all in-section
    // betting options for Position Target, Player Target, and
    // Play Result sections
    if (response) {
      response.forEach(item => {
        let mappedItem = {}
        mappedItem.id = i;
        mappedItem.name = item.DVItem;
        mappedItem.odds = item.odds;
        mappedItem.AvgDVPercent = item.AvgDVPercent;
        mappedItem.type = item.ProbFam;

        targetPlayData.push(mappedItem);
        i++;
      });
    }
  }

  const renderTargetPositionPlays =  (probabilityResponse) => {
    let plays = [];

    if (probabilityResponse) {
      let playsPosition = [];
      let playsTarget = [];
      let playsResult = [];
      probabilityResponse.forEach(function(obj){
        if(obj.ProbFam === "POSITION" || obj.ProbFam === "TARGETED POS") {
          playsPosition.push(obj)
        }
        else if(obj.ProbFam === "TARGET" || obj.ProbFam === "TARGETED PLAYER") {
          playsTarget.push(obj);
        }
        else {
          playsResult.push(obj);
        }
      });

      let lowOdds, highOdds, oddsStr
      oddsStr = 'N/A'
      if (playsPosition.length > 0) {
        lowOdds = playsPosition[playsPosition.length -1].odds.toFixed(1)
        highOdds = playsPosition[0].odds.toFixed(1)

        if (lowOdds === highOdds) {
          oddsStr = lowOdds + 'x'
        } else {
          oddsStr = lowOdds + 'x - ' + highOdds + 'x'
        }
      }
      plays.push({
        icon: <CompassCalibrationIcon />,
        name: "Position target",
        odds:  oddsStr,
      })

      oddsStr = 'N/A'
      if (playsTarget.length > 0) {
        lowOdds = playsTarget[playsTarget.length -1].odds.toFixed(1)
        highOdds = playsTarget[0].odds.toFixed(1)

        if (lowOdds === highOdds) {
          oddsStr = lowOdds + 'x'
        } else {
          oddsStr = lowOdds + 'x - ' + highOdds + 'x'
        }
      }
      plays.push({
        icon: <RadioButtonCheckedIcon />,
        name: "Player target",
        odds: oddsStr,
      })

      oddsStr = 'N/A'
      if (playsResult.length > 0) {
        lowOdds = playsResult[playsResult.length -1].odds.toFixed(1)
        highOdds = playsResult[0].odds.toFixed(1)

        if (lowOdds === highOdds) {
          oddsStr = lowOdds + 'x'
        } else {
          oddsStr = lowOdds + 'x - ' + highOdds + 'x'
        }
      }
      plays.push({
        icon: <FlagIcon />,
        name: "Play Result",
        // odds: playsResult[playsResult.length -1].AvgDVPercent + "x" + " - " + playsResult[0].AvgDVPercent + "x",
        odds: oddsStr,
      })
    }

    return plays;
  }
  const collectNonRunPassProb = (probabilityResponse) => {
    // let playResults = ['TOUCHDOWN', 'FIRST DOWN', 'PENALTY', 'TURNOVER', '3DN CONVERSION'];
    let playResultData = [];
    if (probabilityResponse) {
      let playResults = {
        'TOUCHDOWN': true,
        'FIRST DOWN': true,
        'PENALTY': true,
        'TURNOVER': true,
        '3DN CONVERSION': true,
        'GAIN': true,
      };
      probabilityResponse.forEach((outerItem) => {
        if (outerItem && outerItem.length > 0) {
          outerItem.forEach((innerItem) => {
            if (innerItem.ProbFam === "POSITION" || innerItem.ProbFam === "TARGET"
              || innerItem.ProbFam === "TARGETED PLAYER" || innerItem.ProbFam === "TARGETED POS"
              || (playResults[innerItem.ProbFam] && innerItem.DVItem === "y")) {
              let targetProbability = innerItem.AvgDVPercent / 100.0
              // let targetOdds = ((1 - targetProbability) / targetProbability).toFixed(1);
              // let targetOdds = (1.0 / targetProbability).toFixed(1);
              let targetOdds = convertProbToOdds(targetProbability);
              // innerItem.AvgDVPercent = targetOdds;
              innerItem.odds = targetOdds;
              playResultData.push(innerItem);
              playResultData = playResultData.sort((a, b) => {
                return b.odds - a.odds
              });
            }
          });
        }
      });
    }
    return playResultData;
  };
