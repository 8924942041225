import React, {useEffect, useState, useRef} from 'react';
import {Box, Typography, Grid, Container, Button} from "@material-ui/core";
import { useStyles } from "../../layouts/styles";

import "../PauseScreen.css";

const PauseScreenNBA = ({onHideBetForTeamPaused, nbaTeam}) => {
    const classes = useStyles();
    const haveNbaTeam = Boolean(nbaTeam)

    return (
        <Box className={classes.pauseScreen}>
            <Container className={classes.pausedPlay}></Container>
            <Grid
                container
                direction="column"
                justify="space-evenly"
                spacing={2}
                className={classes.prim}
            >
                <Grid item>
                    <Typography align="left">PLEASE WAIT{haveNbaTeam && ' FOR NEXT ' + nbaTeam.toUpperCase() + " POSSESSION"}</Typography>
                </Grid>
            </Grid>
            <Box className={classes.gamePaused}>
                <Grid container direction="column" spacing={2} style={{alignItems: "center"}}>
                    <Grid item className={classes.msgH1}>
                        {/*<Typography className="msgGamePaused">Game is currently being played...</Typography>*/}
                        <Typography className="msgGamePausedSml">Players can select one outcome</Typography>
                        <Typography className="msgGamePausedSml">per possession, per team.</Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box className={classes.linkToGame}>
                <Button onClick={onHideBetForTeamPaused}>
                    <Grid>
                        <Typography className="msgGamePaused" style={{textDecoration: "underline"}}>Back</Typography>
                    </Grid>
                </Button>
            </Box>
        </Box>
    ); };
export default PauseScreenNBA;
