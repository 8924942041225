import {Badge, ButtonBase } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React, {useEffect, useState, useRef} from 'react';
import { useStyles } from "../layouts/styles";

import "./BetButton.css";

const NormalBedge = withStyles((theme) => ({
  anchorOriginTopRightRectangle: {
    // top: "50%",
    radius: 4,
    minWidth: 42,
    width: 60,
    height: 24,
    padding: 3,
    borderRadius: 4,
    color: "#FDC934",
    background: "rgba(31, 38, 48, 0.2)",
    border: "1px solid rgba(255, 255, 255, 0.2)",
    fontFamily: "Kanit",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "140%",
    textAlign: "center",
    letterSpacing: "0.04em",
    cursor: "pointer",
    top: 6,
    right: 12,
    transform: 'none'
  },
}))(Badge);

const NormalBedgeDark = withStyles((theme) => ({
  anchorOriginTopRightRectangle: {
    // top: "50%",
    radius: 4,
    minWidth: 42,
    width: 60,
    height: 36,
    padding: 3,
    borderRadius: 4,
    color: "black !important",
    background: "rgba(31, 38, 48, 0.2)",
    border: "1px solid rgba(255, 255, 255, 0.2)",
    fontFamily: "Kanit",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "140%",
    textAlign: "center",
    letterSpacing: "0.04em",
    cursor: "pointer",
    top: 0,
    right: 10,
    transform: 'none'
  },
}))(Badge);

const StyledBadge = withStyles((theme) => ({
  anchorOriginTopRightRectangle: {
    // top: "50%",
    radius: 4,
    minWidth: 42,
    width: 120,
    height: 24,
    padding: 3,
    borderRadius: 4,
    color: "#FDC934",
    background: "rgba(31, 38, 48, 0.2)",
    border: "1px solid rgba(255, 255, 255, 0.2)",
    fontFamily: "Kanit",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "140%",
    textAlign: "center",
    letterSpacing: "0.04em",
    cursor: "pointer",
    top: 30
  },
}))(Badge);

const StyledBadgeDark = withStyles((theme) => ({
  anchorOriginTopRightRectangle: {
    // top: "50%",
    radius: 4,
    minWidth: 42,
    width: 120,
    height: 24,
    padding: 3,
    borderRadius: 4,
    color: "black !important",
    background: "rgba(31, 38, 48, 0.2)",
    border: "1px solid rgba(255, 255, 255, 0.2)",
    fontFamily: "Kanit",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "140%",
    textAlign: "center",
    letterSpacing: "0.04em",
    cursor: "pointer",
    top: 30
  },
}))(Badge);

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function getButtonLabel(item, content, type) {
  // console.log('getButtonLabel item = ', item, ', content = ', content, ', type = ', type)
  if (item && type === 'small')
    return (<div style={{flexDirection: 'row', height: 32}}><div className="betItem" style={{color: 'rgba(255,255,255,0.8)', fontWeight: 400, paddingLeft: 14, paddingTop: 14}}>
          {item.name}
    </div>{item.teamTricode && <div className="betItem" style={{fontWeight: 500, float: 'left', marginLeft: 8}}>
      ({item.teamTricode})
    </div>}</div>)
  return (<div className="betItem" style={type === 'small'? {fontWeight: 400, paddingLeft: 14, paddingTop: 14} : {height:80, lineHeight: '80px', paddingLeft: 20}}>
    {content}
  </div>)
}

const BetButton = ({ id, team, mainShotZoneOpt, onClick, gameType, className, item, setType, type, content, setShowBets,
                     badgeContent, getRunPassData, getNbaData, spanClassName, setNextPossessionOptionClicked,
                   setRunPlayers, setPassPlayers, setPositionTargets, setPlayerTargets, setPlayResults, gameId}) => {
  // console.log('setPassPlayers = ', setPassPlayers)
  const [badgeContentValue, setBadgeContentValue] = useState(badgeContent);
  const prevBadgeContentValue= usePrevious(badgeContentValue);
  const classes = useStyles();
   // let interval = useRef();
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  const icon = item?.icon

  /** derived from https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs */
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  useEffect(() => {
    function handleResize() {
      const dims = getWindowDimensions()
      // console.log(dims)
      setWindowDimensions(dims);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  /** end derived from StackOverflow */

  useEffect(() => {
    if( badgeContent !== "0x" && badgeContent !== '0x - 0x'){
      setBadgeContentValue(badgeContent);
    }
  }, [badgeContent]);

  useEffect(() => {
    if (
      prevBadgeContentValue &&
      JSON.stringify(prevBadgeContentValue) !== JSON.stringify(badgeContentValue) &&
      badgeContent !== "0x"
    ) {
      setBadgeContentValue(badgeContent);
    }
  }, [badgeContent]);

  // const getNBAGameProbabilities = (team) => {
  //   fetchNbaData(team);
  //   interval.current = setInterval(() => {
  //     fetchNbaData(team);
  //   }, 1000);
  // };

  let gridXs = (badgeContentValue? 7 : 12);
  // console.log('Icon = ', icon)
  // console.log('type = ', type)
  let betNameSecCustomStyle = { width: '70%' }
  if (type !== 'small' && badgeContentValue) {
    if (windowDimensions) {
      if (windowDimensions.width < 500) {
        if (windowDimensions.width < 400) {
          betNameSecCustomStyle = {
            width: '58%'
          };
        } else {
          betNameSecCustomStyle = {
            width: '60%'
          };
        }
      }
    }
  }

  return (
    <ButtonBase
      disable={"true"}
      onClick={(onClick)
        ? onClick
        : (e) => {
          e.preventDefault();
          if (badgeContentValue) {
            // console.log('BetButton onClick')
            if (setType && item) {
              setType(item);
              if (!item.type) {
                // console.log('getRunPassData onclick, team = ', team)
                getRunPassData(gameId, item, team, setRunPlayers, setPassPlayers, setPlayerTargets, setPositionTargets, setPlayResults);
              }
              else {
                getNbaData(item);
                setNextPossessionOptionClicked(true)
              }
            }
            setShowBets(false);
          }
      }}
      className={mainShotZoneOpt && mainShotZoneOpt.isClicked && mainShotZoneOpt.playerId !=="" && id === mainShotZoneOpt.mainShotId ? classes.activeButton : className}
      id={id}
    >
      <div style={type === 'small' ? {width: "100%", height: 80} : {width: "100%", height: 76, display: 'flex'}}>
        <div className={type === 'small'? "betSmall" : (badgeContentValue) ? "betNameSec" : "possessionCls"} style={betNameSecCustomStyle}>
          {icon ? <div style={{paddingRight: 20, height: 24, alignItems: 'center'}}>{icon}</div> : null}
          {getButtonLabel(item,content,type)}
        </div>
        {badgeContentValue &&
        <div className={type === 'small'? "betOdd" : "betOddBig"}>
          {(type === 'small' && className.includes("activeButton")) &&
          <NormalBedgeDark style={{height: 44, width: '100%'}} classes={{ anchorOriginTopRightRectangle:( mainShotZoneOpt && mainShotZoneOpt.isClicked && mainShotZoneOpt.playerId !=="" && id === mainShotZoneOpt.mainShotId )? classes.activeBtnSpan : spanClassName}}  badgeContent={badgeContentValue}></NormalBedgeDark>
          }
          { (type === 'small' && !className.includes("activeButton")) &&
          <NormalBedge style={{height: 44, width: '100%'}} classes={{ anchorOriginTopRightRectangle: ( mainShotZoneOpt && mainShotZoneOpt.isClicked && mainShotZoneOpt.playerId !=="" && id === mainShotZoneOpt.mainShotId )? classes.activeBtnSpan : spanClassName}} badgeContent={badgeContentValue}></NormalBedge>
          }
          { (type === 'big' && !className.includes("activeButton")) &&
          <StyledBadge classes={{ anchorOriginTopRightRectangle: spanClassName}} badgeContent={badgeContentValue}></StyledBadge>
          }
          { (type === 'big' && className.includes("activeButton")) &&
          <StyledBadgeDark classes={{ anchorOriginTopRightRectangle: spanClassName}} badgeContent={badgeContentValue}></StyledBadgeDark>
          }
          { (type === 'bigGame' && !className.includes("activeButton")) &&
          <StyledBadge classes={{ anchorOriginTopRightRectangle: spanClassName}} badgeContent={badgeContentValue}></StyledBadge>
          }
          { (type === 'bigGame' && className.includes("activeButton")) &&
          <StyledBadgeDark classes={{ anchorOriginTopRightRectangle: spanClassName}} badgeContent={badgeContentValue}></StyledBadgeDark>
          }

        </div>
        }
      </div>
    </ButtonBase>
  ); };
export default BetButton;
