export const  PRIVACY_POLICY = {
    TEXT_FIRST: `Privacy Policy
    
    Updated April 1, 2021. 
    At Play Caller Sports Gaming LLC (“Play Caller”, “we”, “us” or “our”), we take your privacy and the security of your information very seriously.

    This Privacy Policy Statement (“Privacy Policy”) covers Play Caller -owned and -operated websites, including playcaller.io (“Site” or “Sites”), and any services offered by Play Caller  including technology services that enable independent game developers to host tournaments and provide competitive gaming activity to end-users, and any use of the Play Caller  SDK, including all Play Caller  Public Forums, and the Developer Console (collectively, the “Services”). This Privacy Policy is incorporated into, and is part of, our Terms of Servicewhich govern your access to the Site and your use of the Site and/or Services. Unless otherwise defined herein, capitalized terms shall have the meaning assigned to such terms in the Terms of Service.
    
    If you have any questions regarding this Privacy Policy, please contact us at support@playcaller.io.
    
    For the purposes of EU data protection laws (“Data Protection Law”), Play Caller is a data controller (i.e., the company that is responsible for, and controls the processing of your personal data).
    
    This Privacy Policy describes the categories of information we gather from people visiting our Site and from individual users (“you” or “users”) interacting with the Services, and how we use, transfer, and secure such information. By accessing any part of our Site or using any of the Services, you agree to be bound by this Policy. This Policy does not govern information we receive from third parties. If you do not agree to the terms of this Policy, please do not use the Site, or any of our Services. Each time you use any Sites or Services, the current version of this Privacy Policy will apply. Accordingly, when you use any Sites or Services, you should check the date of this Policy (which appears at the top) and review any changes since you last reviewed the Policy.
    

    1. SPECIAL NOTICE REGARDING MINORS UNDER 18 YEARS OF AGE
    Play Caller respects the privacy of children. The Services are not directed towards young children or minors under the age of 18. Please do not use the Services if you are under the age of 18. Play Caller is intent on complying with applicable laws and regulations regarding minors, including the United States’ Children’s Online Privacy Protection Act. Specifically, Play Caller will not knowingly collect and maintain any personally identifiable information from individuals under 18.
    
    If you are a parent or guardian who has discovered that your child has submitted personally identifiable information, Play Caller will make reasonable efforts to delete the information from its database at your request. To request the deletion of your child’s information, please send an email to suppor@playcaller.io.
    

    2. CATEGORIES OF INFORMATION WE COLLECT
    We may collect two types of information from users of our Sites and Services: “Personal Information”, which is information is about, or relates to, an identified or identifiable individual, or that can be linked to that individual (such as name, email address, etc.), and “Aggregate Information”, which is information that cannot be used to identify you (such as frequency of visits to the Site, etc.)
    
    We collect a few categories of Personal Information:
    

    2.1  Personal Information you voluntarily provide us, which may include:
    
    2.1.1 In the course you acting as a job applicant to, an employee of, director of, officer of, or contractor of Play Caller, you may provide certain Personal Information to Play Caller  in your applicable role.  Said Personal Information could include email address, phone number, name, SSN, bank information and government identification numbers. For the purposes of the California Consumer Privacy Act (“CCPA”) this section provides the disclosure of categories of Personal Information we collect regarding the same.
    2.1.2 Your email address and password to allow you to create a Play Caller account and use the Services. Your email address may also be used for marketing and promotional emails as allowed by applicable law.
    2.1.3 Username, if provided by you, to allow you to use the Services. Your username, name, password and email address are collectively referred to as your “Account Information”.
    2.1.4 Birth date to verify that you are over the age of 18.
    2.1.5 Mailing address to allow physical prize fulfillment.
    2.1.6 Phone number to allow us to contact you, allow for the use of the Services, and to prevent fraud.
    2.1.7 Your mobile device’s unique device ID to prevent fraud. 
    2.1.8 Any Personal Information you choose to add to your profile, such as an image. This Personal Information is used to enhance your use of the Services.
    2.1.9 You may provide credit card information or your Paypal username to use certain features of the Services. Play Caller will use third-party credit card processing companies to process your transactions. Play Caller will not have access to your credit card data.
    2.1.10 Certain Personal Information, such as first name, last name, shipping address, phone number, and Social Security number, to verify your identity when you withdraw cash from your account and by us and certain third-party service providers to monitor for fraudulent activity and verify that you can legally participate in cash tournaments and withdraw funds.
    2.1.11 Personal Information you may choose to submit to us through surveys, sending questions or comments, providing a resume, or other similar activities. This information may be used to enhance the Services, to market to you or others, and to allow us to make appropriate hiring decisions if submitted for that purpose.
     
    2.2 Personal Information that is passively collected when you use the Sites or Services:
    
    2.2.1 Our system logs may record certain information about visitors to our Sites, including the web request, internet protocol (“IP”) address, device and mobile ad identifiers, browser information, interaction with the Sites, pages viewed, app usage, and other such information; we may collect similar information from emails you receive from us that can help us track which emails are opened and which links are clicked by recipients.  Where these elements meet the definition of Personal Information, we will treat them as such. 
    2.2.2 We use certain cookies, pixel tags and other technologies to help us understand how you use the Sites and enable us to personalize your experience. To learn more about cookies, please visit http://www.allaboutcookies.org/. When you visit our website, we and our third-party service providers may receive and record Personal Information that you have provided and your digital signature, such as your IP address. The technologies we use to track your movements around our website include cookies, tracking scripts and pixels, and tagging technologies, which we may employ to understand your preferences, improve your experience on our website, etc. You can control the use of cookies at the individual browser level. We may ask advertisers or other third-party partners to serve ads or services to your devices, which rely on cookies or similar technologies placed on our Sites. 
    2.2.3 With your consent and in connection with your use of the Play Caller SDK, we may use GPS technology (or other similar technology) to determine your current location in order to determine the state you are located within to establish which aspects of the Services you are permitted to use. You may withdraw your consent to our collection of your location information at any time, provided that you are not participating in cash competitions. If you choose to participate in a cash competition, you may not opt out of the collection of this type of geolocation data. 
    2.2.4 A cache is a component of your mobile device that transparently stores data for recordkeeping purposes. Cache data identifies your mobile device. Most browsers and applications are initially set up to accept such data. If you prefer, you can program your mobile device so that it does not store this information while utilizing the Play Caller SDK. Certain features may only be available through the collection of such data. We may use such data to assist in data collection and to track usage and trends to help us better serve you. We also permit third parties who have entered into confidentiality agreements to access such data to help us monitor use of the Play Caller SDK for fraud or for any other activity that does not comply with applicable laws. 
     
    2.3 Aggregate Information Collected
    
    2.3.1 Aggregate Information is information that does not identify you. Aggregate Information may be collected when you visit our Site or interact with our Services independent of any information you voluntarily enter. We may collect, use, store, and transfer Aggregate Information without restriction. 
    2.3.2 When you visit our Site, some information is automatically collected. Such information could include your operating system, the site from which you linked to us (“referring page”), the name of the website you chose to visit immediately after ours (called the “exit page”), information about other websites you have recently visited, browser (software used to browse the internet) type and language, your site activity, and the time and date of your visit. Further, we automatically collect certain aggregate information from your use of our Services. This information could include the date and time of your visit, the phone network associated with your mobile device, your mobile device’s operating system or platform, the type of mobile device you use, and the features of our Services which you accessed. Although we do our best to honor the privacy preferences of our visitors, we are not able to respond to Do Not Track signals from your browser at this time.
     
    3. SHARING OF PERSONAL INFORMATION COLLECTED FROM YOU
    
    3.1 Play Caller may transfer Personal Information to third parties for the purpose of providing the Services. We may disclose Personal Information to our appropriate affiliates, including third-party service providers, to provide you with the Services. These third-party service providers are not authorized to retain, share, store or use the Personal Information for any purposes other than to provide the services they have been hired to provide.
    3.2 Certain Personal Information is transmitted to a third-party service provider for the purpose of processing any payments. The transmission of your payment information is automatic, and at no point do we, or any of our agents or employees, have the ability to review, access or retain such payment information. For more information on how your payment information is processed, please see the terms and conditions of our third-party service provider at https://www.fisglobal.com/terms-of-use and their privacy policy available at https://www.fisglobal.com/privacy. If you elect to process payments using your Paypal account, your payments will be subject to Paypal’s terms of service, located at https://www.paypal.com/us/webapps/mpp/ua/useragreement-full?locale.x=en_US, and Paypal’s Privacy Policy available at https://www.paypal.com/us/webapps/mpp/ua/privacy-full.
    3.3 Play Caller  may also elect to transfer your Personal Information to third parties under special circumstances to: (i) to comply with a legal requirement, judicial proceeding, court order, or legal process served on Play Caller ; (ii) to investigate a possible crime, such as fraud or identity theft; (iii) in connection with the sale, purchase, merger, reorganization, liquidation or dissolution of Play Caller ; (iv) when we believe it is necessary to protect the rights, property, or safety of Play Caller  or other persons; or (v) as otherwise required or permitted by law, including any contractual obligations of Play Caller .
     
    4. LEGAL BASIS FOR PROCESSING IN THE EU
    In the EU, the purposes for which we process your personal data are:
    
    4.1 Where we need to perform the contract we are about to enter into or have entered into with you for the Service;
    4.2 Where it is necessary for our legitimate interests (or those of a third party), and your interests and fundamental rights do not override those interests; and
    4.3 Where we need to comply with a legal or regulatory obligation in the EU.
    Please contact suppoer@playcaller.io if you need details about the specific legal basis on which we are relying to process your personal data where more than one legal basis has been set out.
     
    5. YOUR RIGHTS IN RESPECT OF YOUR PERSONAL INFORMATION IF YOU ARE A RESIDENT IN THE EU AND SWITZERLAND
    If you are located in the EU or Switzerland, you have the following rights in respect of your personal data that we hold:
    
    5.1 Right of access. The right to obtain access to your personal data.
    5.2 Right to rectification. The right to obtain rectification of your personal data without undue delay where that personal data is inaccurate or incomplete.
    5.3 Right to erasure. The right to obtain the erasure of your personal data without undue delay in certain circumstances, such as where the personal data is no longer necessary in relation to the purposes for which it was collected or processed.
    5.4 Right to restriction. The right to obtain the restriction of the processing undertaken by us on your personal data in certain circumstances, such as where the accuracy of the personal data is contested by you, for a period enabling us to verify the accuracy of that personal data.
    5.5 Right to portability. The right to portability allows you to move, copy or transfer personal data easily from one organization to another.
    5.6 Right to object. You have a right to object to processing based on legitimate interests and direct marketing.
    If you wish to exercise one of these rights, please contact us using the contact details at the end of this Privacy Policy. You also have the right to lodge a complaint to your local data protection authority. Further information about how to contact your local data protection authority is available at http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
     
    6. ACCOUNT SECURITY
    The security of your account relies on your protection of your password and mobile device. You are responsible for maintaining the security of your password and mobile device. You are solely responsible for any and all activities that occur under your account. You may not share your password with anyone. We will never ask you to send your password or other sensitive information to us in an email, though we may ask you to enter this type of information on the Play Caller SDK interface.
    Any email or other communication requesting your password or asking you to provide sensitive account information via email should be treated as unauthorized and suspicious and should be reported to us immediately at suppor@playcaller.io If you do share your password or your mobile device with a third party for any reason, the third party will have access to your account and your Personal Information, and you may be responsible for actions taken using your password. If you believe someone else has obtained access to your password, please change it immediately. If you believe that an unauthorized access has already occurred please report it immediately at support@playcaller.io.
     
    7. DATA STORAGE AND TRANSFER
    Your information collected through our Service may be stored and processed in the United States or any other country in which Play Caller or its affiliates or service providers maintain facilities. If you are located in the European Union or other regions with laws governing data collection and use that may differ from U.S. law, please note that we may transfer information, including your Personal Information, to a country and jurisdiction that does not have the same data protection laws as your jurisdiction, and we will take all steps reasonably necessary to ensure that any Personal Information are treated securely and in accordance with this Privacy Policy and applicable law. Such transfers are made pursuant to appropriate safeguards as provided by applicable law. If you wish to enquire further about these transfers, please contact us using the details set out at the end of this Privacy Policy.
    For individuals based in the EU or Switzerland, we store personal data for as long as necessary to fulfill the purposes for which we collect the data (see above under “Categories of Information We Collect”), except if otherwise required by law.
     
    8. POSTINGS TO PUBLIC FORUMS
    All postings in Play Caller public forums will generally be available to, and freely accessible by, other users of the Play Caller public forums as well as the internet general public. Therefore, users should not expect that any information they post will be kept private or confidential and are encouraged to exercise discretion when using the public forums. Additionally, your username, which you can change, will always be included in conjunction with your postings in the public forums. Play Caller is not responsible for events arising from the distribution of any information you choose to publicly post or share through the forums. All information or materials uploaded by you while utilizing the Services is subject to our Terms of Service.
     
    9. MARKETING
    From time to time, we may contact you with relevant information about our Services. Most messages we send will be by email. For some messages, we may use Personal Information we collect about you to help us determine the most relevant information to share with you.
    If you do not want to receive such messages from us, you will be able to tell us by selecting certain boxes on forms we use when we first collect your contact details. You can change your marketing preferences by clicking on the unsubscribe link at the bottom of our emails.
     
    10. MAINTAINING AND UPDATING YOUR ACCOUNT INFORMATION
    As part of your use of the Services, you are responsible for maintaining and updating, as applicable, your Account Information with current and complete information. You may edit and delete portions of your Account Information. To do this, select “My Account” in the main menu and choose which information to edit or delete. You can also delete your Play Caller account entirely. To do this, select “Help/Customer Service” in the main menu. Further select “Account Inquiry” and request for your account to be deleted.
    After receiving any requested changes to your Account Information or Personal Information, we will make reasonable efforts to ensure that all of your Personal Information stored in databases we actively use to operate the Services will be updated, corrected, changed, or deleted as appropriate, as soon as reasonably practicable. However, we reserve the right to retain in our archival files any information we remove from or change in our active databases, subject to any obligations under any applicable law. Where permissible, we may retain such information to resolve disputes, troubleshoot problems, and enforce our Terms of Service. In addition, it may not be technologically possible to remove every record of the information you have previously provided us, as a copy may exist in a non-erasable form that will be difficult or impossible for us to locate.
     
    11. GOVERNING LAW; NOTIFICATION OF CHANGES
    This Privacy Policy is governed by the laws of California without giving effect to any principles of conflict of law.  Notification of changes to this Privacy Policy will be handled as described within “Section 13, Effective Date; Policy Changes” as provided below.
     
    12. CALIFORNIA PRIVACY RIGHTS
    Pursuant to Section 1798.83 of the California Civil Code, residents of California have the right to request from a business, with whom the California resident has an established business relationship, certain information with respect to the types of Personal Information the business shares with third parties for direct marketing purposes by such a third party and the identities of the third parties with whom the business has shared such information during the immediately preceding calendar year. If you are a California resident and want a copy of this notice, please submit an email request to support@playcaller.io. In your request, please specify that you want a “Your Play Caller Section 1798.83 California Privacy Rights Notice.” Please allow 30 days for a response.
    California Consumer Privacy Act Rights. Under the California Consumer Privacy Act (“CCPA”), California residents have certain rights regarding their Personal Information. If you would like to exercise these rights on or after January 1, 2020, please contact us using the email address, toll-free phone number, or physical mailing address listed within the “Contact Information” section below. For your own privacy and security, at our discretion, we may require you to prove your identity before providing the requested information, as provided for by the CCPA. It may take us some time to respond to your request, but we will do so within the requirements of the CCPA.
    This Policy provides you disclosure regarding the Personal Information we collect from you and the purposes for doing so. Please see “Categories of Information We Collect” above. The CCPA grants you specific rights, including the following:
    
    12.1 Right to request disclosure as to Personal Information we have collected about you.
    12.1.1 Upon a verifiable request, made through one of the methods provided within the “Contact Information” section below, we will disclose to you the items listed below, one or more of which may be provided by reference to this Policy:
    `,
    TEXT_SECOND:`12.1.2 Right to opt out of the sale of your information. To exercise this right, please visit https://www.playcaller.io/do-not-sell-my-information/
    12.1.3 Right to request deletion: upon a verifiable request, made through one of the methods provided within the “CCPA Contact Information” section below, we will delete personal information we have regarding you and direct our service providers to delete your personal information from their records, to the extent provided by the CCPA.
    •	Right to be free from discrimination: Play Caller will not discriminate against you for exercising any of your rights under the CCPA.
    
    CCPA Contact Information:
    Play Caller can be reached by email at support@playcaller.io; or by mail at 228 Hamilton Avenue, 3rd Floor, Palo Alto, CA 94301, Attention: Privacy Compliance.
    
    What We May Need From You
    We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.
    
    Time Limit to Respond
    We will respond to all legitimate requests within forty-five days, or as permitted by the CCPA.
     
    13. EFFECTIVE DATE; POLICY CHANGES
    Each time you use the Play Caller  SDK, the current version of the Privacy Policy will apply. Accordingly, when you use the Play Caller  SDK, you should check the date of this Privacy Policy (which appears at the top of the Privacy Policy) and review any changes since the last version. Our business changes frequently and this Privacy Policy is subject to change from time to time. Unless stated otherwise, our current Privacy Policy applies to all information that we have about you. We will not materially change our policies and practices to make them less protective of customer information collected in the past without the consent of affected customers.
     
    CONTACT US
    To contact us with your questions or comments regarding this Privacy Policy or the information collection and dissemination practices of the Play Caller SDK, please email us at support@playcaller.io.
    To see the Terms of Service that apply to the Services please visit our Terms of Use. 
    
    If you would like to exercise your right to opt out of the sale your Personal Information under the CCPA, please contact us at:
    
    Play Caller can be reached by email at support@playcaller.io; or by mail at 228 Hamilton Avenue, 3rd Floor, Palo Alto, CA 94301, Attention: Privacy Compliance.
    
    `,
    LI_ELEMENTS: [
       `The categories of Personal Information Play Caller has collected about you.`,
       `The categories of sources from which the Personal Information was collected.`,
       `The business purpose behind collecting the Personal Information.`,
       `The categories of third parties with whom Play Caller has shared the information.`,
       `The specific pieces of Personal Information Play Caller has collected about you.`
    ]
}