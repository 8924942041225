export const configureNFLJsonData = (type, bet, tgt, odds, tournament_id, team, last_situation_id) => {
    let betPostData = {};
    if (type.name  === "Run play" || type.name === "Pass play") {
        let rpk = type.odds.split('-');
        rpk = rpk[0].trim();
        betPostData = {
        // user_id: user_id,
        amount: bet,
        rpk: type.name === 'Run play' ? "RUN" : "PASS",
        rpk_odds: rpk.replace("x", ''),      //type.odds.replace("x", ''),
        tgt: tgt ? tgt : "",
        tgt_odds: odds ? odds : 0.0
        }
    }
    else if (type.name === 'Position target') {
        betPostData = {
        // user_id: user_id,
        amount: bet,
        pos: tgt,
        pos_odds: odds ? odds : 0.0
        }
    }
    else if (type.name === "Player target") {
        betPostData = {
        // user_id: user_id,
        amount: bet,
        tgt: tgt,
        tgt_odds: odds ? odds : 0.0
        }
    }
    else if (type.name === "Play Result") {
        switch (tgt) {
        case "TOUCHDOWN":
            betPostData = {
            // user_id: user_id,
            amount: bet,
            td_odds: odds ? odds : 0.0,
            td: 1
            }
            break;
        case "FIRST DOWN":
            betPostData = {
            // user_id: user_id,
            amount: bet,
            firstdn_odds: odds ? odds : 0.0,
            firstdn: 1
            }
            break;
        case "PENALTY":
            betPostData = {
            // user_id: user_id,
            amount: bet,
            penalty_odds:  odds ? odds : 0.0,
            penalty: 1
            }
            break;
        case "TURNOVER":
            betPostData = {
            // user_id: user_id,
            amount: bet,
            turnover_odds: odds ? odds : 0.0,
            turnover: 1
            }
            break;
        case "3DN CONVERSION":
            betPostData = {
            // user_id: user_id,
            amount: bet,
            third_down_conv_odds: odds ? odds : 0.0,
            third_down_conv: 1
            }
            break;
        default:
            betPostData = {}
            break;
        }
    }
    betPostData['tournament_id'] = tournament_id
    betPostData['team'] = team
    betPostData['last_situation_id'] = last_situation_id
    return betPostData;
};

  export const configureNBAJsonData = (type, bet, tgt, combineBet, selectedButton, odds, tournament_id = null) => {
    let betPostData = {};
    if(type.name === "Possession result"){
      betPostData = {
        // user_id: user_id,
        amount: bet,
        result: tgt,
        odds: odds
      }
    }
    else if (type.name === 'Shooting zone') {
      let zone = "";
      if(combineBet.mainBet !=="") {
        switch (combineBet.mainBet) {
          case 'Mid Range':
            zone = "A";
            break;
          case 'Beyond 3 PT Line':
            zone = "B";
            break;
          case 'In the Paint':
            zone = "C";
            break;
          default:
            zone = "";
            break;
        }
      }
      if(combineBet.playerBet === "") {
        betPostData = {
          // user_id: user_id,
          amount: bet,
          zone: zone,
          odds: odds,
        }
      }
      if(combineBet.playerBet !== "") {
        betPostData = {
          // user_id: user_id,
          amount: bet,
          zone: zone,
          player: combineBet.playerBet,
          odds: odds
        }
      }
    }
    else if (type.name === '2-point shot') {
      if((combineBet.playerBet === "" && combineBet.mainBet !=="") || (combineBet.playerBet === undefined && combineBet.mainBet !=="")) {
        betPostData = {
          // user_id: user_id,
          amount: bet,
          DVCat: selectedButton.DVCat + "2",
          title: selectedButton.title,
          odds: odds
        }
      }
      else if(combineBet.playerBet !== "") {
        betPostData = {
          // user_id: user_id,
          amount: bet,
          DVCat: selectedButton.DVCat,
          title: combineBet.playerBet,
          odds: odds
        }
      }

    }
    else if (type.name === '3-point shot') {
      if((combineBet.playerBet === "" && combineBet.mainBet !=="") || (combineBet.playerBet === undefined && combineBet.mainBet !=="" )) {
        betPostData = {
          // user_id: user_id,
          amount: bet,
          DVCat: selectedButton.DVCat + "3",
          title: selectedButton.title,
          odds: odds,
        }
      }
      else if(combineBet.playerBet !== "") {
        betPostData = {
          // user_id: user_id,
          amount: bet,
          DVCat: selectedButton.DVCat,
          title: combineBet.playerBet,
          odds: odds
        }
      }

    }

      let team = localStorage.getItem("team");
      betPostData["team"] = team
      betPostData["sport"] = 'nba'
      if (tournament_id) {
          betPostData["tournament_id"] = tournament_id
      }

      console.log('betPostData = ', betPostData);

    return betPostData;
  }
