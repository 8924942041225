import React from 'react';
import {Link, useHistory} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
//import SportsOutlinedIcon from '@material-ui/icons/SportsOutlined';
import TrophyIcon from '@material-ui/icons/EmojiEventsOutlined';
import PlayArrowOutlinedIcon from '@material-ui/icons/PlayArrowOutlined';
import BarChartOutlinedIcon from '@material-ui/icons/BarChartOutlined';
import './BottomBar.css'
import constants from "../../constants";


const useStyles = makeStyles({
  root: {
    position: "fixed",
    width: '100%',
    background: '#2a2e35',
    bottom: 0,
    zIndex: 10,
    overflowX: "hidden"
  }
});

export default function BottomBar({selectedTabIdx, setSelectedTabIdx, tournamentId, sport}) {
  const classes = useStyles();
  // const [value, setValue] = React.useState(0);
  // const history = useHistory();

  return (
    <BottomNavigation
      value={selectedTabIdx}
      onChange={(event, newValue) => {
        // console.log('BottomBar newValue = ', newValue)
        setSelectedTabIdx(newValue);
        // if (newValue === 0)
        //   history.push('/tournaments')
        //   // window.location='/tournaments'
        // else if (newValue === 1)
        //   history.push('/calls')
        //   // window.location='/calls'
        // else
        //   history.push('/leaderboard')
        //   // window.location='/leaderboard'
      }}
      showLabels
      className={classes.root}
    >
      {/*<BottomNavigationAction
          component={Link}
          to='/plays'
          label="Games"
          icon={<SportsOutlinedIcon />} />*/}
      <BottomNavigationAction
          component={Link}
          to={tournamentId === 0 ? '/plays' : (sport === constants.SPORT_BASKETBALL ? '/nba/game' : '/game')}
          label="Tournaments"
          icon={<TrophyIcon />} />
      <BottomNavigationAction
          component={Link}
          to='/calls'
          label="Calls"
          icon={<PlayArrowOutlinedIcon />} />
      <BottomNavigationAction
      component={Link}
      to='/leaderboard'
      label="Leaderboard" icon={<BarChartOutlinedIcon />} />
    </BottomNavigation>
  );

}
