import {Box, Container, FormControl, Grid, TextField, Typography} from "@material-ui/core";
import React, { useState } from "react";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link, useHistory } from "react-router-dom";
import { useStyles } from "../styles";
import "./signUp.css";
import {doRegister} from "../../../hooks/register";

function SignUp({setToken, setIsRegisterClicked, setIsTermsClicked, setIsPrivacyPolicyClicked}) {
  // const [email, setEmail] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  // const [fName, setFName] = useState("");
  // const [lName, setLName] = useState("");
  const history = useHistory()
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState("");

  const handleButtonPress = (e) => {
    e.preventDefault();
    if (email && username && password) {
      let params = {
        email: email,
        username: username,
        password: password,
      };

      doRegister(params)
          .then((response) => {
            // console.log('Login Response = ', response)
            if (response.data.error) {
              var errorMsg = response.data.error || "";
              setErrorMessage(errorMsg);
              return
            }
            if (response.data.id) {
              setToken(response.data);
              let values = new Array();
              let expiryDate = new Date();
              values.push(JSON.stringify(response.data));

              expiryDate.setHours(expiryDate.getHours() + 24);
              values.push(expiryDate);
              localStorage.setItem("token", values.join(";"));
            }
            history.push("/plays");

          })
          .catch((error) => {
            console.log(error);
            alert(error);
          });
    } else {
      alert("Please fill out all fields.");
    }
  }

  const handleTermsOfService = () => {
    setIsTermsClicked(true);
  }

  const handlePrivacyPolicy = () => {
    setIsPrivacyPolicyClicked(true);
  }

  return (
    <Box className={classes.loginBody}>
       <Grid item>
          <ArrowBackIcon style={{color: "white", margin: 17}} onClick={(e) => history.goBack()}/>
        </Grid>
      <Container className={classes.logoLogin}></Container>
      <Box className={classes.form} autoCapitalize="none">
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography className={classes.signIn}>CREATE ACCOUNT</Typography>
          </Grid>
          <Grid item>
            <Typography className={errorMessage? "error-label" : "error-label-invisible"}>* {errorMessage}</Typography>
          </Grid>
          {/*<Grid direction="row" container spacing={12} justify="space-between" alignItems="center" style={{padding: "8px"}}>
            <FormControl className={classes.inputName}>
              <TextField
                variant="outlined"
                label="First name"
                type="text"
                value={fName}
                autoCapitalize="none"
                autoComplete="off"
                inputProps={{
                  autoCapitalize: 'none',
                }}
                onChange={(e) => setFName(e.target.value)}
                InputLabelProps={{
                  style: {
                    fontSize: "12px",
                    color:"darkgray",
                    opacity: "0.7",
                  } }}
              />
            </FormControl>
            <FormControl className={classes.inputName}>
              <TextField
                variant="outlined"
                label="Last name"
                type="text"
                value={lName}
                autoCapitalize="none"
                autoComplete="off"
                inputProps={{
                  autoCapitalize: 'none',
                }}
                onChange={(e) => setLName(e.target.value)}
                InputLabelProps={{
                  style: {
                    fontSize: "12px",
                    color:"darkgray",
                    opacity: "0.7",
                  } }}
              />
            </FormControl>
          </Grid>*/}
          <Grid item>
            <FormControl>
              <TextField
                className={classes.input}
                variant="outlined"
                label="Email"
                type="text"
                value={email}
                autoCapitalize="none"
                autoComplete="off"
                inputProps={{
                  autoCapitalize: 'none',
                }}
                onChange={(e) => setEmail(e.target.value)}
                InputLabelProps={{
                  style: {
                    fontSize: "12px",
                    color:"darkgray",
                    opacity: "0.7",
                  } }}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl>
              <TextField
                className={classes.input}
                variant="outlined"
                label="Username"
                type="text"
                value={username}
                autoCapitalize="none"
                autoComplete="off"
                inputProps={{
                  autoCapitalize: 'none',
                }}
                onChange={(e) => setUsername(e.target.value)}
                InputLabelProps={{
                  style: {
                    fontSize: "12px",
                    color:"darkgray",
                    opacity: "0.7",
                  } }}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl>
               <TextField
                className={classes.input}
                variant="outlined"
                type="password"
                label="Password"
                value={password}
                autoCapitalize="none"
                onChange={(e) => setPassword(e.target.value)}
                InputLabelProps={{
                  style: {
                    fontSize: "12px",
                    color:"darkgray",
                    opacity: "0.7",
                  }
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item  style={{paddingTop:"8%"}}>
          <button
            name="login"
            className="login-button"
            onClick={handleButtonPress}
          >
            CREATE ACCOUNT
          </button>
        </Grid>
        <Grid item>
          <div className="termsLink">
            By registering, you confirm that you are at least 18 years<br></br>
            old and agree to our<br></br>
            <Link
              to="/termsOfService"
              target="_blank"
              rel="noopener noreferrer"
              className="termsAndPrivacy"
            >
            Terms of Service </Link>
            and
            <Link
              to="/privacyPolicy"
              target="_blank"
              rel="noopener noreferrer"
              className="termsAndPrivacy"
            > Privacy Policy </Link>
          </div>
        </Grid>
      </Box>
    </Box>
  );
}
export default SignUp;
