import { Button, Grid, Typography } from "@material-ui/core";
import React, {useEffect, useState, useRef} from 'react';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import { useStyles } from "../layouts/styles";

import "./BetButton.css";
import CopyrightIcon from "@material-ui/icons/Copyright";


const PotentialRetunAlert = ({ visible, duration, winAmount, setShowBetWonAlert, setSeeMoreClicked, recentBets, seeMoreClicked }) => {
    const [isVisible, setVisibility] = useState(null);
    const [numBetsWon, setNumBetsWon] = useState(0);
    const [mappedRecentBets, setMappedRecentBets] = useState([]);
    const classes = useStyles();

    let timeout = useRef()

    // console.log('PotentialRetunAlert recentBets = ', recentBets)

    const mapRecentBets = (recentBets) => {
        let mappedRecentBets = [];
        recentBets.forEach((item) => {
            let mappedItem = {
                message: "",
                did_won: null
            };
            for (const property in item) {
            let winning_amount = item.winning_amount ? item.winning_amount : 0;
            switch(property) {
                case 'firstdn':
                    if(item.firstdn === 1) {
                        mappedItem.message = "Run Play, First Down - " + (item.did_win_bet === 1? "$" + winning_amount : "Lost");
                        mappedItem.did_won = item.did_win_bet;
                    }
                    break;
                case 'penalty':
                    if(item.penalty === 1) {
                        mappedItem.message = "Run Play, Penalty - " + (item.did_win_bet === 1? "$" + winning_amount : "Lost");
                        mappedItem.did_won = item.did_win_bet;
                    }
                    break;
                case 'touchdown':
                    if(item.touchdown === 1) {
                        mappedItem.message = "Run Play, Touchdown - " + (item.did_win_bet === 1? "$" + winning_amount : "Lost");
                    }

                    mappedItem.did_won = item.did_win_bet;
                    break;
                case 'turnover':
                    if(item.turnover === 1) {
                        mappedItem.message = "Run Play, Turnover - " + (item.did_win_bet === 1? "$" + winning_amount : "Lost");
                        mappedItem.did_won = item.did_win_bet;
                    }
                    break;
                case 'pos':
                    if(item.pos !== null) {
                        mappedItem.message = item.pos + (item.did_win_bet === 1? " - $" + winning_amount : " - Lost");
                        mappedItem.did_won = item.did_win_bet;
                    }
                    break;
                case 'rpk':
                    if(item.rpk === "RUN") {
                        mappedItem.message = "Run Play";
                    }
                    if(item.rpk === "PASS") {
                        mappedItem.message = "Pass Play";
                    }
                    if(item.tgt !== null  || item.tgt !== "") {
                        mappedItem.message += ", " + item.tgt + (item.did_win_bet === 1? " - $" + winning_amount : " - Lost");
                    }
                    mappedItem.did_won = item.did_win_bet;
                    break;
                case 'tgt':
                    if(item.rpk === null) {
                        mappedItem.message = item.tgt + (item.did_win_bet === 1? " - $" + winning_amount : " - Lost");
                        mappedItem.did_won = item.did_win_bet;
                    }
                    break
                case 'category':
                    mappedItem.message = item['category'] + " - " + (item.did_win_bet === 1? "$" + winning_amount : "Lost");
                    mappedItem.did_won = item.did_win_bet;
                    break;
                case 'id':
                case 'amount':
                case 'winning_amount':
                case 'did_win_bet':
                    break;
                default:
                    console.log("Unrecognized property: " + property);
            }}

            mappedRecentBets.push(mappedItem);
        })
        let i = 1;
        mappedRecentBets.forEach(item => {
            item.message = i + ". " + item.message;
            i++;
        })
        setMappedRecentBets(mappedRecentBets);
    }

    useEffect(() => {
        setVisibility(visible);
    }, [visible]);

    useEffect(() => {
        // console.log('visible = ', visible)
        // console.log('duration = ', duration)
        if (visible && duration !== null) {
            timeout = setTimeout(() => {
                setVisibility(false);
                setShowBetWonAlert(false);
                setSeeMoreClicked(false);
            }, duration)
        }
        return () => {
            if (timeout)
                clearTimeout(timeout);
        }
    }, [visible, duration]);


    useEffect(() => {
        let i = 0;
        // console.log('BetWon recentBets = ', recentBets)
        // console.log('BetWon recentBets.length = ', recentBets?.length)
        if (recentBets && recentBets.length > 0) {
            recentBets.forEach(item => {
                if (item.did_win_bet === 1) {
                    // console.log('did_win_bet!!! ' + item.winning_amount)
                     i++;
                }
            })
        }

        setNumBetsWon(i);
        if (i === 0) {
            // console.log('didnt calculate num bets > 0')
            if (!timeout)
                setShowBetWonAlert(false);
            setSeeMoreClicked(false);
        } else {
            setShowBetWonAlert(true);
            setSeeMoreClicked(false);
            mapRecentBets(recentBets);
        }

        // setShowBetWonAlert(true);

    }, [recentBets]);

    const handleSeeMore = (e) => {
     e.preventDefault();
     setSeeMoreClicked(true);
    };

    if (!isVisible) return null;

    let gridXs = (seeMoreClicked? 12 : 9);

    return (
        <Button alignItems="flex-start" className={seeMoreClicked ? classes.betWonAlertExt : classes.betWonAlert} >
            <Grid container spacing={12} justify="space-between" alignItems="center">
                <Grid xs={gridXs} direction="row" container>
                    {numBetsWon > 1 ?
                        <Grid container className={seeMoreClicked? classes.betWonSecExp : classes.betWonSec} direction={'row'}>
                            <ThumbUpAltIcon style={{paddingRight: '11.31',color: '#FFF', width:'20.38px', height: '20.38px',  display: 'block'}}/>
                            <Typography className={classes.playerTargetSec}>{numBetsWon} Bets won! You earned <b>{winAmount.toFixed(2)}</b></Typography>
                            <CopyrightIcon style={{height: 15, marginTop: 2}}/>
                            {seeMoreClicked && <Typography style={{paddingLeft: '34%'}} onClick={() => {setShowBetWonAlert(false)}} className={classes.playerTargetSec}>x</Typography>}
                            {seeMoreClicked && <Grid className={classes.betStatistic}>
                            {mappedRecentBets.map(bet =>
                                 <Typography className={bet.did_won === 1 ?classes.playerTargetSec : classes.playerTargetSecLost}>{bet.message}</Typography>
                            )}

                        </Grid>}
                        </Grid>
                        :
                        <Grid style={{width: '100%'}}>
                        <Grid container className={seeMoreClicked? classes.betWonSecExp : classes.betWonSec} direction={'row'}>
                            <ThumbUpAltIcon style={{paddingRight: '11.31',color: '#FFF', width:'20.38px', height: '20.38px', display: 'block'}}/>
                            <Typography className={classes.playerTargetSec}>Call won! You earned <b>{winAmount.toFixed(2)}</b></Typography>
                            <CopyrightIcon style={{height: 15, marginTop: 2}}/>
                            {seeMoreClicked && <Typography style={{paddingLeft: '34%'}} onClick={() => {setShowBetWonAlert(false)}} className={classes.playerTargetSec}>x</Typography>}
                        </Grid>
                        {seeMoreClicked && <Grid className={classes.betStatistic}>
                            {mappedRecentBets.map(bet =>
                                 <Typography className={bet.did_won === 1 ?classes.playerTargetSec : classes.playerTargetSecLost}>{bet.message}</Typography>
                            )}

                        </Grid>}
                        </Grid>
                    }
                </Grid>
                {false && !seeMoreClicked &&
                 <Grid item xs={3} container >
                    <div  className="betItemSeeMore" onClick={handleSeeMore}>
                        SEE MORE
                    </div>
                </Grid>}
            </Grid>
        </Button>
    );
};
export default PotentialRetunAlert;
