import {nbaDataResponse} from "./getNbaOdds";
import {convertProbToOdds} from "../utils/probabilityUtils"
export let nbaPlayers = []

export const getNbaData = (item) => {
  let data = [];
  if (nbaDataResponse){
    data = mappNbaData(nbaDataResponse, item.name);
    nbaPlayers = data;
    return data;
  };
}

// let betOptions = ["player_result_cells", "player_shot_cells", "shot_zone_cells", "three_pt_fg_cells", "two_pt_fg_cells", "play_result_cells"]
let betOptions = ["player_result_cells", "three_pt_fg_cells", "two_pt_fg_cells", "play_result_cells"]

const mappNbaData = (response, possessionOutcomeName) => {
  let possessionOutcome = [];
  let i = 0;

  for (const item in response) {
    if(betOptions.indexOf(item) > -1)  {
      response[item].forEach((itm) => {
        // let prob = Number(itm.percent / 100).toFixed(2);
        let prob = itm.percent / 100
        // let odds = ((1 - prob) / prob).toFixed(1);
        let odds = convertProbToOdds(prob)
        itm["odds"] = parseFloat(odds);
        itm["id"] = i;
        i++;
      })
    }
  }

  if(possessionOutcomeName === "2-point shot") {
      possessionOutcome.push(response["two_pt_fg_cells"])
  }
  if(possessionOutcomeName === "3-point shot") {
      possessionOutcome.push(response["three_pt_fg_cells"])
  }
  // if(possessionOutcomeName === "Shooting zone") {
  //     possessionOutcome.push(response["shot_zone_cells"])
  // }
  if(possessionOutcomeName === "Possession result") {
    possessionOutcome.push(response["play_result_cells"])
  }
  // if(possessionOutcomeName === "Player shot") {
  //   possessionOutcome.push(response["player_shot_cells"])
  // }
  return possessionOutcome;
}

const renderShootingZonePlayer = (shootingZone) => {
  let players = [];
  if(nbaPlayers[0]) {
    nbaPlayers[0].forEach((item) => {
      if(item.ProbFam === "Player" && item.title === shootingZone.name) {
        item.name = item.player
        players.push(item);
      }
    })
  }
  return players;
}

const renderPointShotPlayers = (mainOption, pt) => {
  let players = [];
  if(nbaPlayers[0]) {
    nbaPlayers[0].forEach((item) => {
      if(item.DVCat === "player_" + pt + "_fgm" && mainOption.name === "Score") {
        item.name = item.title
        players.push(item);
      }
      if(item.DVCat === "player_" + pt + "_fgx" && mainOption.name === "Miss") {
        item.name = item.title
        players.push(item);
      }
      if(item.DVCat === "player_" + pt + "_fga" && mainOption.name === "Attempt") {
        item.name = item.title
        players.push(item);
      }
    })
  }
  return players;
}


export const renderNbaPlayersWhenMainOptionSelected = (betType, mainOption) => {
  let mainOptionName = mainOption.name;
  let players = []
  switch(betType) {
    case '2-point shot':
      if(mainOptionName === "Score" || mainOptionName === "Miss" || mainOptionName === "Attempt")
        players = renderPointShotPlayers(mainOption, "2pt");
      break;
    case '3-point shot':
      if(mainOptionName === "Score" || mainOptionName === "Miss" || mainOptionName === "Attempt")
        players = renderPointShotPlayers(mainOption, "3pt");
      break;
    case 'Shooting zone':
      if(mainOptionName === "Mid Range" || mainOptionName === "In the Paint" || mainOptionName === "Beyond 3 PT Line")
        players = renderShootingZonePlayer(mainOption);
      break;
    default:
      break;
  }
  return players;
}

export const renderNbaMainOptions = (type) => {
  let makeMiss = [];
  if(nbaPlayers[0]) {
    switch (type.name){
      case '2-point shot':
        nbaPlayers[0].forEach((item) => {
          if(item.title === "MAKE" && item.ProbFam === "2PT3PT MAKE/MISS") {
            item.name = "Score"
            makeMiss.push(item);
          }
          else if(item.title === "MISS" && item.ProbFam === "2PT3PT MAKE/MISS") {
            item.name = "Miss"
            makeMiss.push(item);
          }
          else if(item.title === "ATTEMPT" && item.ProbFam === "POSS EVENT") {
            item.name = "Attempt"
            makeMiss.push(item);
          }
        })
        break;
      case '3-point shot':
        nbaPlayers[0].forEach((item) => {
          if(item.title === "MAKE" && item.ProbFam === "2PT3PT MAKE/MISS") {
            item.name = "Score";
            makeMiss.push(item);
          }
          else if(item.title === "MISS" && item.ProbFam === "2PT3PT MAKE/MISS") {
            item.name = "Miss";
            makeMiss.push(item);
          }
          else if(item.title === "ATTEMPT" && item.ProbFam === "POSS EVENT") {
            item.name = "Attempt";
            makeMiss.push(item);
          }
        })
        break;
      case 'Shooting zone':
        nbaPlayers[0].forEach((item) => {
          if(item.ProbFam === "SHOT ZONE") {
            item.name = item.title;
            makeMiss.push(item);
          }
          else {
            item.name = item.title;
          }
        })
          break;
        case 'Possession result':
          nbaPlayers[0].forEach((item) => {
              item.name = item.title;
              makeMiss.push(item);
          })
            break;
        case "Player shot":
          nbaPlayers[0].forEach((item) => {
              item.name = item.title;
              makeMiss.push(item);
          })
          break;
    }
  }
  return makeMiss;
}
