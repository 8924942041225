import axios from "axios";
import constants from '../constants'

export const getCallsHistory = (user_id, tournament_id = null, sport = constants.SPORT_FOOTBALL) => {
    // return axios.get(constants.API_HOST + '/app/nba/live_games.json')
    // const curr_date_str = getCurrDateStr()
    // console.log('Date = ' + yyyy + mm + dd)
    // return axios.get('https://data.nba.net/prod/v2/20210626/scoreboard.json')
    // return axios.get('https://data.nba.net/prod/v2/' + '20210627' + '/scoreboard.json')
    // return axios.get('https://data.nba.net/prod/v2/' + curr_date_str + '/scoreboard.json')
    const params = {
        user_id
    }
    if (tournament_id)
        params.tournament_id = tournament_id
    return axios.get(constants.API_HOST + "/app/calls_history.json", { params })
    // return axios.get('http://localhost:8000' + "/app/calls_history.json", { params })
}
