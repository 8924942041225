import {Box, Button, Grid, Typography} from "@material-ui/core";
import querystring from 'querystring'
import PlaysHeader from "../../ui/PlaysHeader";
import {useStyles} from "../styles";
import {useTournStyles} from "../Tournament/ListingStyles";
import {useEffect, useState} from "react";

function Rules({token}) {
    const classes = useStyles()
    const tournStyles = useTournStyles()
    const [tournamentTitle, setTournamentTitle] = useState(null)

    useEffect(() => {
    }, [])

    return (<Box className={classes.body}>
        <PlaysHeader showBackButton={true} showAccountIcon={true} data={token} backGoesBack={true}/>
        <Grid
            container
            direction="column"
            justify="space-around"
            spacing={2}
            className={classes.primLeaderboard}
            style={{fontFamily: 'Kanit'}}
        >
            <Grid item>
                <Typography className={tournStyles.sectionTitle} align="left" style={{
                    color: 'rgba(255, 255, 255, 0.7)',
                }}>GAME RULES</Typography>
                <Typography className={tournStyles.sectionTitle} align="left" style={{
                    color: 'rgba(255, 255, 255, 0.7)',
                    marginTop: 12,
                    lineHeight: '18px'
                }}>Welcome to the Play Caller Pro Basketball Finals Experience (the “Finals”). Play Caller enables real-time predictions for individual possessions live during each Finals game. Every possession of the Finals you can make your predictions and see if you are the ultimate “Play Caller” based on the number of tokens you accumulate by the end of the game. Remember that the odds of each prediction happening change in real-time, so be quick in making your decisions. And, don’t forget to check the Leaderboard often to see how you rank and adjust your predictions to have the best chance of winning. During each Pro Basketball Finals game, $150 will be awarded to the top 3 “Play Callers” based on their respective number of tokens at the end of the live game with the leading token holder receiving $100, second place receiving $25 and third place receiving $25. If there is a tie, the amount will be divided evenly between all the respective equally situated token holders.</Typography>

                <Typography className={tournStyles.sectionTitle} align="left" style={{
                    color: 'rgba(255, 255, 255, 0.7)',
                    marginTop: 12,
                    lineHeight: '18px',
                    textDecoration: 'underline'
                }}>More Information</Typography>

                <ul style={{marginRight: 10, fontSize: 14, color: 'rgba(255, 255, 255, 0.7)', letterSpacing: '0.1em', fontFamily: 'Kanit' }}>
                    <li>Each tournament will have an entry fee that enables each player to start with 1,000 tokens.</li>
                    <li>Each player has a limit of 100 tokens per prediction and can only make one prediction per team for each individual possession.</li>
                    <li>If a player ever gets to 0 tokens, the game is over for the player. For everyone else the game will end when the live game ends.</li>
                    <li>Each prediction is based on the team's next possession, not the current possession, as the pro basketball game moves very quickly.</li>
                    <li>For each possession, you can choose a "2-point shot" attempt, miss and/or score, as well as a "3-point shot" attempt, miss and/or score.  For clarity, an "attempt" is either a miss or a score. You may also choose a "Possession result".</li>
                    <li>It's possible at specific points in the game, that some prediction options are not available (e.g. specific players do not show up).</li>
                    <li>If you choose "2-point shot" or "3-point shot" attempt, miss or score and then select a player, to win your prediction, both choices must be correct to win.</li>
                    <li>Each possession also has a general possession result choice such as "2PT FGA (field goal attempt), 3PT FGA (field goal attempt), FTA (free throw attempt) and Turnover".</li>
                    <li>The score, players and possession outcomes update during the game based on a third-party live data feed. This data feed may be significantly ahead of your live TV or OTT broadcast. We know this can be frustrating at times, but it is necessary for the integrity of the game ensuring that no player has a timing advantage.</li>
                </ul>
            </Grid>
        </Grid>
    </Box>)
}
export default Rules;
