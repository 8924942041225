import axios from 'axios';
import querystring from 'querystring';
import constants from "../constants";

const loginURI = constants.API_HOST + "/app/do_login";

const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}

export function doLogin(loginData) {
    return axios.post(loginURI, querystring.stringify(loginData), config);
};

export function rememberMe(loginData) {
    localStorage.setItem('username', loginData.username);
    localStorage.setItem('password', loginData.password);
    localStorage.setItem('rememberMe', true);
};
