import axios from 'axios';
import constants from "../constants";
import querystring from "querystring";

const createURI = constants.API_HOST + "/app/tournament/create";
const gamesForTournURI = constants.API_HOST + "/app/tournament/games";

export const getTournaments = (token, tournament_id=null, sport=null) => {
    // return axios.get(constants.API_HOST + '/app/nba/live_games.json')
    // const curr_date_str = getCurrDateStr()
    // console.log('Date = ' + yyyy + mm + dd)
    // console.log('Sport = ', sport)
    // return axios.get('https://data.nba.net/prod/v2/20210626/scoreboard.json')
    // return axios.get('https://data.nba.net/prod/v2/' + '20210627' + '/scoreboard.json')
    // return axios.get('https://data.nba.net/prod/v2/' + curr_date_str + '/scoreboard.json')

    const headers = {
        'Authorization': 'Bearer ' + token.access
    }
    const params = {
        // user_id: token.id
    }
    if (tournament_id) {
        params.tournament_id = tournament_id
    }
    let uri = "/app/nfl/tournaments.json"
    if (sport === constants.SPORT_BASKETBALL) {
        uri = "/app/nba/tournaments.json"
    }
    // console.log('Headers = ', headers)
    return axios.get(constants.API_HOST + uri, {
        headers,
        params
    });
}

export function getGamesForTournaments(sport) {
    return axios.get(gamesForTournURI, {
        params: {
            sport
        }
    })
}

export function createTournament(data) {
    // this doesn't send the parameters for some reason...
    // return axios.post(createURI, data)

    // have to stringify
    return axios.post(createURI, querystring.stringify(data), {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

export const joinTournament = (token, tournament_id, invite_code = null) => {
    const params = {
        user_id: token.id,
        tournament_id,
        invite_code
    }
    return axios.get(constants.API_HOST + "/app/tournament/join", {
        params
    })
}

export const leaveTournament = (token, tournament_id) => {
    const params = {
        user_id: token.id,
        tournament_id
    }
    return axios.get(constants.API_HOST + "/app/tournament/leave", {
        params
    })
}

export const createTournamentInvite = (token, tournament_id) => {
    const params = {
        user_id: token.id,
        tournament_id
    }
    return axios.post(constants.API_HOST + "/app/tournament/invite/create", querystring.stringify(params))
}
