import {Container, Button, ButtonGroup, Grid, Typography} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import "./BottomBar.css";
import CopyrightIcon from "@material-ui/icons/Copyright";

const useStyles = makeStyles({
    bottom: {
        // position: "absolute",
        // 	left:0,
        width: "100%",
        background: "#2a2d35",
        // bottom: 0,
        zIndex: 99999
    },
    odds: {
        paddingTop: 5,
        paddingBottom: 15,
        color: "#FFF",
    },
    buttonGroup: {
        width: "100%",
        height: 48,
        paddingTop: "12px",
    },
    activeButton: {
        color: "black",
        width: "100%",
        paddingTop: "7px",
        borderColor: "transparent",
        background: "#FDC934",
        fontFamily: "Kanit",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "140%",
        letterSpacing: "0.04em",
        height: 44,
        "&:hover": {
            color: "black",
            background: "#FDC934",
        },
        "&:active": {
            color: "black",
            background: "#FDC934",
        },
        "&:focus": {
            color: "black",
            background: "#FDC934",
        },
    },
    button: {
        color: "white",
        width: "100%",
        borderColor: "transparent",
        background: "#1E2128",
        fontFamily: "Kanit",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "140%",
        letterSpacing: "0.04em",
        height: 44,
        "&:hover": {
            color: "black",
            background: "#FDC934",
        },
        "&:active": {
            color: "black",
            background: "#FDC934",
        },
        "&:focus": {
            color: "black",
            background: "#FDC934",
        },
    },
    betButton: {
        color: "#FFF",
        width: "100%",
        height: 48,
        background: "#1E2128",
        textTransform: "none",
        borderRadius: "4px",
        padding: 20,
        bottom: 5,
        textAlign: "center",
        fontFamily: "Kanit",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "140%",
        letterSpacing: "0.04em",
        "&:disabled": {
            color: "rgba(255, 255, 255, 0.5)",
            opacity: 4,
        },
        "&:hover": {
            color: "black",
            backgroundColor: "#FDC934",
        },
        "&:active": {
            color: "black",
            background: "#FDC934",
        },
        "&:focus": {
            color: "black",
            background: "#FDC934",
        },
        "&:enabled": {
            color: "black",
            backgroundColor: "#FDC934",
        }
    },
});

const DEFAULT_CASHOUT_AMOUNTS = [
    {
        "amount": 20000,
        "displayValue": "20k"
    },
    {
        "amount": 50000,
        "displayValue": "50k"
    },
    {
        "amount": 100000,
        "displayValue": "100k"
    },
    {
        "amount": 200000,
        "displayValue": "250k"
    },
    {
        "amount": 500000,
        "displayValue": "500k"
    },
];
export default function CashoutBar({
    token,
                                       hide,
                                       redeemIsSubmitting,
                                       userBetsStats=null,
                                       gsData=null,
                                   setBetErrorMsg=null,
                                   showBetErrorAlert=null,
                                       onSubmitParent=null
                               }) {
    const classes = useStyles();
    const [cashoutAmount, setCashoutAmount] = useState(null);

    const handleSubmit = () => {
        let data = {};
        let user_coins = 0
        if (userBetsStats?.user?.total_coins) { // new WebSockets way (NBA)
            user_coins = parseFloat(userBetsStats.user.total_coins)
        } else if (gsData?.user?.total_coins) { // old polling way (NFL)
            user_coins = parseFloat(gsData.user.total_coins)
        }

        if (!cashoutAmount) {
            alert("Please specify the amount of coins to redeem");
            return;
        }
        // if (cashoutAmount > user_coins) {
        //     alert("You do not have enough coins")
        //     return
        // }

        const headers = {
            'Authorization': 'Bearer ' + token.access
        }

        if (onSubmitParent)
            onSubmitParent(cashoutAmount)
    };

    let bets = DEFAULT_CASHOUT_AMOUNTS

    // console.log('(type.name === "Run play" || type.name === "Pass play") = ', (type.name === "Run play" || type.name === "Pass play"))
    // console.log('showBetAmounts = ', type?.name)
    const showBetAmounts = true
    const can_submit = true
    let submitButtonDisabled = !can_submit;
    let componentHidden = false
    // console.log('buttonDisabled = ', buttonDisabled)

    // console.log('token = ', token)
    // console.log('gsData = ', gsData)
    // console.log('user_coins = ', user_coins)
    //
    // bets.map((item) => {
    //   console.log('item = ', item)
    //   console.log('user_coins = ', user_coins)
    // })

    return (
        <div hidden={hide || componentHidden} style={{
            position: "fixed",
            left:0,
            right: 0,
            bottom: 0,
            zIndex: 99999}}>

            <Container className={classes.bottom}>
                {showBetAmounts && <Grid item>
                    <ButtonGroup className={classes.buttonGroup} style={{marginBottom: 10}} disableElevation>
                        {bets.map((item) => (
                            <Button
                                className={(item.amount === cashoutAmount) ? classes.activeButton : classes.button}
                                onClick={(e) =>{e.preventDefault(); setCashoutAmount(item.amount);}}
                            >
                                <Typography style={{float: 'left', fontFamily: 'Kanit', color: 'white'}}>{item.displayValue}</Typography>
                                <CopyrightIcon style={{float: 'left', height: 14, marginTop: 1, color: (item.amount === cashoutAmount)?'white':'#FDC934'}}/>
                            </Button>
                        ))}
                    </ButtonGroup>
                </Grid>}
                <Grid item className="betButton">
                    <Button
                        disabled={Boolean(submitButtonDisabled) || redeemIsSubmitting}
                        className={classes.betButton}
                        onClick={handleSubmit}
                    >
                        {redeemIsSubmitting ? "SENDING" : "REDEEM"}
                    </Button>
                </Grid>
            </Container>
        </div>
    );
}
