import { Avatar, Badge, Button, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from 'react';
import { useStyles } from "../layouts/styles";
import UserIcon from "../icons/leaderboard-user-icon.png";

import "./BetButton.css";

const TokenBadge = withStyles((theme) => ({
  badge: {
    top: "50%",
    radius: 4,
    minWidth: 42,
    padding: 3,
    width: 70,
    height: 18,
    pading: 9,
    border: '1px solid #FDC934',
    borderRadius: '15px',
    color: "#FFF",
    background: "rgba(31, 38, 48, 0.2)",
    fontFamily: "Kanit",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "140%",
    textAlign: "center",
    letterSpacing: "0.04em",
  },
}))(Badge);


const LeaderButton = ({ onClick, disable, className, item, setType,type, content, setShowBets, icon, badgeContent,
                        getRunPassData, spanClassName, betClassName, tournScoreSystem }) => {
  const classes = useStyles();

  return (
    <Button
      disable
      onClick={(onClick)
        ? onClick
        : () => { /** what is this?? why call getRunPassData */
        if(setType && item) {
          console.log('Leaderboard Btn = ', item)
          setType(item);
          getRunPassData(item);
        }
        setShowBets(false)
      }}
      className={className}
      startIcon={"" + content.id}
    >
      <Grid container spacing={4} justify="left" alignItems="center">
        <Grid item xs={2}>
          <img style={{width:'32px', height:'32px', marginTop: 3.5}} src={UserIcon}/>
        </Grid>
        <Grid item xs={tournScoreSystem === 'most_bets_won' ? 8: 5}>
          <div className="betItem">
            {content.name}
            </div>
        </Grid>
        <Grid item xs={2} className={betClassName} align='center'>
          {content.bets}
        </Grid>
        {tournScoreSystem !== 'most_bets_won' && <Grid item xs={3} align='center' style={{fontFamily: 'Kanit', }}>
            {badgeContent}
        </Grid>}
      </Grid>
    </Button>
  );
};
export default LeaderButton;
