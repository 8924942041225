import axios from 'axios';
import querystring from 'querystring';
import constants from "../constants";

const registerURI = constants.API_HOST + "/app/register";

const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}

export function doRegister(loginData) {
    return axios.post(registerURI, querystring.stringify(loginData), config);
}

// export function storeRegisterData(loginData) {
//     localStorage.setItem('username', loginData.username);
//     localStorage.setItem('password', loginData.password);
// };
