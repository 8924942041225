import {Box, Grid, Typography} from "@material-ui/core";
import React, {useEffect, useRef, useState} from "react";
import {useStyles} from "../styles";
import PlaysHeader from "../../ui/PlaysHeader";
import CoinIcon from "../../icons/coin.png"
import {useCallsStyles} from "./CallsStyles";
import {getCallsHistory} from "../../../hooks/useHistory";
import constants from '../../../constants'

function renderBasketballBetCell(bet, callsStyles) {
    let bet_category_str = ''
    if (bet.category) {
        bet_category_str = bet.category.toUpperCase().replace("PLAYER_", "")
    }
    let bet_won_or_loss_str = ''
    if (bet.processed === 1)
        bet_won_or_loss_str = bet.winning_amount && bet.winning_amount > 0 ? 'WON' : "LOST"

    return (<div className={callsStyles.betCell}>
        <div className={callsStyles.betCellTopSection}>
            <div className={callsStyles.betCellTopSecTopRow}>
                <div className={callsStyles.teamLabel}>{bet.team ? bet.team.toUpperCase() : ''}</div>
                <div className={callsStyles.winOrLoss} style={(bet_won_or_loss_str === 'WON' ? {color: 'green'} : (bet_won_or_loss_str === 'LOST' ? {color: 'red'} : {}))}>{bet_won_or_loss_str}</div>
            </div>
            <div className={callsStyles.betCellTopSecBottRow}>
                <div className={callsStyles.leftLabel}>Total Amount</div>
                <div className={callsStyles.leftValue}>{bet.amount}</div>
                <img src={CoinIcon} className={callsStyles.coin}/>
                {/*<div className={callsStyles.openTotGains}>Total Gains {bet.winning_amount ? bet.winning_amount : "-"}</div>*/}
                <div className={callsStyles.betCellPotGains}>
                    <div className={callsStyles.leftLabel}>Total Gains</div>
                    <div className={callsStyles.leftValue}>{bet.winning_amount ? bet.winning_amount : "-"}</div>
                    {bet.winning_amount && <img src={CoinIcon} className={callsStyles.coin}/>}
                </div>
            </div>
        </div>

        <div className={callsStyles.betCellBottSection}>
            <div className={callsStyles.betCellBottSecTopRow}>
                <div className={callsStyles.leftLabel}>{bet_category_str}</div>
                {bet.player && <div  className={callsStyles.leftValue}>{bet.player}</div>}
                <div className={callsStyles.betCellOdds}>{bet.odds}x</div>
            </div>
            <div className={callsStyles.betCellBottSecBottRow}>
                <div className={callsStyles.leftLabel}>Amount</div>
                <div className={callsStyles.leftValue}>{bet.amount}</div>
                <img src={CoinIcon} className={callsStyles.coin}/>

                <div className={callsStyles.betCellPotGains}>
                    <div className={callsStyles.leftLabel}>Potential Gains</div>
                    <div className={callsStyles.leftValue}>{(bet.amount * bet.odds).toFixed(2)}</div>
                    <img src={CoinIcon} className={callsStyles.coin}/>
                </div>
            </div>

            {bet.processed === 1 && <div className={callsStyles.resultSection}>
                <div className={callsStyles.leftLabel}>Result:</div>
                <div className={callsStyles.resultDescription}>{bet.clock}&nbsp;&nbsp;{bet.outcome_description}</div>
            </div>}
        </div>
    </div>);
}

const mappLastOutcomeData = (lastOutcome) => {
    // console.log('lastOutcome = ', lastOutcome)
    // let lstOutcomeMsg = (lastOutcome? "last play: " + lastOutcome.rpk[0].toUpperCase() + lastOutcome.rpk.slice(1).toLowerCase() + " Play, " : "");
    let lstOutcomeMsg = ''
    if (lastOutcome && lastOutcome.rpk && lastOutcome.rpk.length > 0 && lastOutcome.rpk[0]) {
        lstOutcomeMsg = lastOutcome.rpk;
        if (lastOutcome.rpk !== 'Kickoff' && lastOutcome.rpk !== 'Punt'
          && lastOutcome.rpk !== 'Extra Point' && lastOutcome.rpk !== 'Penalty NO PLAY') {
            lstOutcomeMsg += ', '
            if (lastOutcome.rpk === 'Pass Incomplete' && (lastOutcome.tgt?.length > 0
              || lastOutcome.pos?.length)) {
                lstOutcomeMsg += 'intended for '
            }
        }
    }

    if (lastOutcome && lastOutcome.tgt && lastOutcome.tgt !== "")
        lstOutcomeMsg += lastOutcome.tgt;
    else if(lastOutcome && lastOutcome.pos && lastOutcome.pos !== "")
        lstOutcomeMsg += lastOutcome.pos + " ";

    for (const property in lastOutcome) {
        switch(property) {
            case 'fumble':
                if (lastOutcome.fumble === 1) {
                    lstOutcomeMsg += ", FUMBLE";
                }
                break;
            case 'firstdn':
                if(lastOutcome.firstdn === 1) {
                    lstOutcomeMsg += ", FIRST DOWN";
                }
                break;
            case 'penalty':
                if(lastOutcome.penalty === 1) {
                    lstOutcomeMsg += ", PENALTY";
                }
                break;
            case 'touchdown':
                if(lastOutcome.touchdown === 1) {
                    lstOutcomeMsg += ", TOUCHDOWN";
                }
                break;
            case 'turnover':
                if(lastOutcome.turnover === 1) {
                    lstOutcomeMsg += ", TURNOVER";
                }
                break;
            default:
                break;
        }
    }

    if (lastOutcome && lastOutcome.yds_gained) {
        if (lastOutcome.yds_gained >= 0) {
            lstOutcomeMsg += ', ' + lastOutcome.yds_gained + ' yd gain';
        } else {
            lstOutcomeMsg += ', ' + Math.abs(lastOutcome.yds_gained) + ' yd loss';
        }
    }

    if (lastOutcome && lastOutcome.play_min_sec) {
        lstOutcomeMsg += ' (' + lastOutcome.play_min_sec + ')'
    }

    if (lastOutcome && lastOutcome.reversed === 1) {
        lstOutcomeMsg += ' -- PLAY REVERSED'
    }
    return lstOutcomeMsg;
};

function renderFootballBetCell(bet, callsStyles) {
    let top_title
    let bet_category_str = (bet.team ? bet.team.toUpperCase() : '')
    if (bet.rpk) {
        top_title = bet.rpk
        if (bet.target) {
            top_title += ', ' + bet.target
        } else if (bet.pos) {
            top_title += ', ' + bet.pos
        }
    } else {
        if (bet.target) {
            top_title = bet.target
        } else if (bet.pos) {
            top_title = bet.pos
        } else if (bet.is_first_down) {
            // bet_category_str = 'FIRST DOWN';
            top_title = 'FIRST DOWN';
        } else if (bet.is_touchdown) {
            // bet_category_str = 'TOUCHDOWN';
            top_title = 'TOUCHDOWN';
        } else if (bet.is_turnover) {
            // bet_category_str = 'TURNOVER';
            top_title = 'TURNOVER';
        } else if (bet.is_penalty) {
            // bet_category_str = 'PENALTY';
            top_title = 'PENALTY';
        } else if (bet.third_down_conv) {
            // bet_category_str = 'PENALTY';
            top_title = '3RD DOWN CONV';
        }
    }
    let bet_won_or_loss_str = ''
    if (bet.processed === 1)
        bet_won_or_loss_str = bet.winning_amount && bet.winning_amount > 0 ? 'WON' : "LOST"

    let result = ''
    if (bet.outcome_description) {
        if (bet.clock) {
            result = bet.clock + '  ' + bet.outcome_description
        } else {
            result = bet.outcome_description
        }
    } else if (bet.last_outcome) {
        result = mappLastOutcomeData(bet.last_outcome)
    }

    return (<div className={callsStyles.betCell}>
        <div className={callsStyles.betCellTopSection}>
            <div className={callsStyles.betCellTopSecTopRow}>
                <div className={callsStyles.teamLabel}>{top_title}</div>
                <div className={callsStyles.winOrLoss} style={(bet_won_or_loss_str === 'WON' ? {color: 'green'} : (bet_won_or_loss_str === 'LOST' ? {color: 'red'} : {}))}>{bet_won_or_loss_str}</div>
            </div>
            <div className={callsStyles.betCellTopSecBottRow}>
                <div className={callsStyles.leftLabel}>Total Amount</div>
                <div className={callsStyles.leftValue}>{bet.amount}</div>
                <img src={CoinIcon} className={callsStyles.coin}/>
                {/*<div className={callsStyles.openTotGains}>Total Gains {bet.winning_amount ? bet.winning_amount : "-"}</div>*/}
                <div className={callsStyles.betCellPotGains}>
                    <div className={callsStyles.leftLabel}>Total Gains</div>
                    <div className={callsStyles.leftValue}>{bet.winning_amount ? bet.winning_amount : "-"}</div>
                    {bet.winning_amount && <img src={CoinIcon} className={callsStyles.coin}/>}
                </div>
            </div>
        </div>

        <div className={callsStyles.betCellBottSection}>
            <div className={callsStyles.betCellBottSecTopRow}>
                <div className={callsStyles.leftLabel}>{bet_category_str}</div>
                {bet.player && <div  className={callsStyles.leftValue}>{bet.player}</div>}
                <div className={callsStyles.betCellOdds}>{bet.odds}x</div>
            </div>
            <div className={callsStyles.betCellBottSecBottRow}>
                <div className={callsStyles.leftLabel}>Amount</div>
                <div className={callsStyles.leftValue}>{bet.amount}</div>
                <img src={CoinIcon} className={callsStyles.coin}/>

                <div className={callsStyles.betCellPotGains}>
                    <div className={callsStyles.leftLabel}>Potential Gains</div>
                    <div className={callsStyles.leftValue}>{(bet.amount * bet.odds).toFixed(2)}</div>
                    <img src={CoinIcon} className={callsStyles.coin}/>
                </div>
            </div>

            {bet.processed === 1 && <div className={callsStyles.resultSection}>
                <div className={callsStyles.leftLabel}>Result:</div>
                <div className={callsStyles.resultDescription}>{result}</div>
            </div>}
        </div>
    </div>);
}

function Calls({token, setIsShowBottomBar, setSelectedTabIdx, sport}) {
    // const [numOpenCalls, setNumOpenCalls] = useState(10)
    // const [numClosedCalls, setNumClosedCalls] = useState(20)
    const [openBets, setOpenBets] = useState([])
    const [closedBets, setClosedBets] = useState([])
    const [tournamentId, setTournamentId] = useState(0)
    const [totalCalls, setTotalCalls] = useState(0)
    const [tournamentTitle, setTournamentTitle] = useState(null)

    const classes = useStyles();
    const callsStyles = useCallsStyles()
    let interval = useRef();

    useEffect(() => {
        setIsShowBottomBar(true)
        setSelectedTabIdx(1)

        // let tournamentLocal = localStorage.getItem("tournament");
        // let tournamentParsed = JSON.parse(tournamentLocal)
        // console.log('Calls tournamentParsed = ', tournamentParsed)
        // // console.log('tournamentParsed.id ', tournamentParsed.id)
        //
        // setTournamentId(tournamentParsed.id)

        return () => {
            setIsShowBottomBar(false)
        }
    }, [])

    useEffect(() => {
        if (token && token.id) {
            let tournamentLocal = localStorage.getItem("tournament");
            let tournament_id = null
            if (tournamentLocal) {
                let tournamentParsed = JSON.parse(tournamentLocal);
                if (tournamentParsed) {
                    // console.log('Calls tournamentParsed = ', tournamentParsed);
                    // console.log('tournamentParsed.id ', tournamentParsed.id)

                    setTournamentId(tournamentParsed.id)
                    setTournamentTitle(tournamentParsed.title)
                    tournament_id = tournamentParsed.id
                }
            }

            loopQueryCallsHistory(token.id, tournament_id);
        }
        return function cleanup() {
            if (interval && interval.current) {
                clearInterval(interval.current)
            }
        }
    }, [token])

    const fetchCallsHistory = (user_id, tournament_id) => {
        // console.log('fetchCallsHistory, tournamentId = ', getTournamentId())
        // console.log('fetchCallsHistory, tournamentId = ', tournament_id)
        getCallsHistory(user_id, tournament_id).then(response => {
            // console.log('Response = ', response.data)
            if (response && response.data) {
                if (Array.isArray(response.data))
                    processBetRowsResponse(response.data)
                else {
                    processBetRowsResponse(response.data.betRows)
                    setTotalCalls(response.data.totalCalls)
                    // console.log('Total Calls: ', response.data.totalCalls)
                }
            } else {
                setOpenBets([])
                setClosedBets([])
                setTotalCalls(0)
            }
        })
    }

    const processBetRowsResponse = (bet_rows) => {
        const openBetsLocal = []
        const closedBetsLocal = []
        for (let i = 0; i < bet_rows.length; ++i) {
            const loopBet = bet_rows[i]
            if (loopBet.processed === 1) {
                closedBetsLocal.push(loopBet)
            } else {
                openBetsLocal.push(loopBet)
            }
        }
        setOpenBets(openBetsLocal)
        setClosedBets(closedBetsLocal)
    }

    const loopQueryCallsHistory = (user_id, tournament_id) => {
        fetchCallsHistory(user_id, tournament_id)
        interval.current = setInterval(() => {
            fetchCallsHistory(user_id, tournament_id)
        }, 4000)
    }

    if (!tournamentId) {
        return (
          <Box className={classes.body}>
              <PlaysHeader showBackButton={false} showAccountIcon={true}/>
              <Grid
                container
                direction="column"
                justify="space-around"
                spacing={2}
                className={classes.primLeaderboard}
              >
                  <Grid item>
                      <Typography align="left">PLEASE SELECT A TOURNAMENT</Typography>
                  </Grid>
              </Grid>
          </Box>
        )
    }

    return (
        <Box className={classes.body}>
            <PlaysHeader showBackButton={false} showAccountIcon={true} data={token}/>
            <Grid
                container
                direction="column"
                justify="space-around"
                spacing={2}
                className={classes.primLeaderboard}
                style={{fontFamily: 'Kanit'}}
            >
                {tournamentTitle !== null && <Grid item>
                    <Typography className={callsStyles.sectionTitle} align="left" style={{
                        color: 'rgba(255, 255, 255, 0.7)',
                    }}>{tournamentTitle}</Typography>
                </Grid>}
                <Grid item>
                    <Typography className={callsStyles.sectionTitle} align="left" style={{
                        color: '#FDC934',
                    }}>{openBets.length} OPEN CALL{openBets.length === 1 ? '' : 'S'}</Typography>
                </Grid>
                { openBets.map(item => {
                    if (sport === constants.SPORT_FOOTBALL)
                        return renderFootballBetCell(item, callsStyles)
                    else
                        return renderBasketballBetCell(item, callsStyles)
                })}
                <div style={{width: '100%', height: 1, backgroundColor: '#34373F', marginTop: 16, marginBottom: 32}}></div>
                <Grid item>
                    <Typography className={callsStyles.sectionTitle} align="left" style={{
                        color: 'rgba(255, 255, 255, 0.5)',
                    }}>{closedBets.length < totalCalls ? 'LAST ' : ''}{closedBets.length} CLOSED CALL{closedBets.length === 1 ? '' : 'S'}</Typography>
                </Grid>
                { closedBets.map(item => {
                    if (sport === constants.SPORT_FOOTBALL)
                        return renderFootballBetCell(item, callsStyles)
                    else
                        return renderBasketballBetCell(item, callsStyles)
                })}
            </Grid>
        </Box>)
}
export default Calls;
