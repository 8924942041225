import axios from "axios";
import constants from '../constants'

// const getGameById = async () => {
// //TODO: add token
//   const { data } = await axios.get(
//       constants.API_HOST + `/app/game_status.json`
// //   `https://jsonplaceholder.typicode.com/posts/${postId}`
//   );
//   return data;
// };
export function getUserTotalCoins(token) {
  const headers = {
    'Authorization': 'Bearer ' + token.access
  }
  const params = {
  }
  // console.log('Params = ', params)
  return axios.get(constants.API_HOST + '/app/user/total_coins', {
    params,
    headers
  });
}

export function getGameInfo(queryParams) {
  // console.log('queryParams = ', queryParams)
  return axios.get(constants.API_HOST + '/app/game_status.json',{ params: queryParams });
}

export function getTournCount() {
  return axios.get(constants.API_HOST + '/app/nfl/tournaments/count');
}

export function getGameInfoNba(queryParams, token) {
  const headers = {
    'Authorization': 'Bearer ' + token.access
  }
  const params = {
    ...queryParams
  }
  // console.log('Params = ', params)
  return axios.get(constants.API_HOST + '/app/nba/game_status.json', {
    params,
    headers
  });
}
