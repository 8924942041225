let API_DOMAIN, API_HOST, WEBSOCKET_PROTOCOL, WEBSOCKET_HOST
// const API_DOMAIN = "playcaller.io"
// const API_DOMAIN = "localhost:8000"

const IS_DEBUG = false
if (IS_DEBUG) {
    API_DOMAIN = "localhost:8000"
    API_HOST = `http://${API_DOMAIN}`
    WEBSOCKET_PROTOCOL = "ws://"
    // WEBSOCKET_HOST = WEBSOCKET_PROTOCOL + API_DOMAIN
} else {
    API_DOMAIN = "playcaller.io"
    // API_DOMAIN = "playcaller-channels.azurewebsites.net"
    API_HOST = `https://${API_DOMAIN}`
    WEBSOCKET_PROTOCOL = "ws://"
    // WEBSOCKET_HOST = WEBSOCKET_PROTOCOL + "playcaller-channels.azurewebsites.net"
}
WEBSOCKET_HOST = WEBSOCKET_PROTOCOL + API_DOMAIN

const APP_HOST = "https://app.playcaller.io"
const CLD_CLOUD_NAME = "diop5ppii"
const CLD_UPLOAD_PRESET = "c4o129re"
const SPORT_FOOTBALL = "PRO FOOTBALL"
const SPORT_BASKETBALL = "PRO BASKETBALL"

export default {
    API_DOMAIN,
    APP_HOST,
    API_HOST,
    CLD_CLOUD_NAME,
    CLD_UPLOAD_PRESET,
    SPORT_FOOTBALL,
    SPORT_BASKETBALL,
    WEBSOCKET_PROTOCOL,
    WEBSOCKET_HOST
}
