import { Box, Grid, Typography } from "@material-ui/core";
import FlagIcon from "@material-ui/icons/Flag";
import {convertProbToOdds} from "../../../utils/probabilityUtils"
import TwoPoint from "../../icons/2-point.png";
import ThreePoint from "../../icons/3-point.png";
import TShirt from "../../icons/t-shirt.png";
import React, { useEffect, useRef, useState, createRef } from "react";
import Fade from "react-reveal/Fade";
import { getGameInfoNba } from "../../../hooks/useGame";
import {
  getDefaultOptionsState,
  // possesionOptions,
} from "../../../service/getNbaOdds";
import BetBar from "../../ui/BetBar";
import BetButton from "../../ui/BetButton";
import BetWonAlert from "../../ui/BetWonAlert";
import PauseScreenNBA from "../../ui/nba/PauseScreenNBA";
import PotentialReturnAlert from "../../ui/PotentialReturnAlert";
import { useStyles } from "../styles";
import BetList from "./BetList";
import "./Game.css";
import MatchHeader from "./MatchHeader";
import {getNbaGameProbabilities} from "../../../hooks/useNbaProbs";
// import {getLiveGames} from "../../../hooks/nbaListings";
import {utcDateStrToDate} from "../../../utils/dateUtils";
import BetErrorAlert from "../../ui/BetErrorAlert";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

let gameId = null

const betOptions = ["player_result_cells", "three_pt_fg_cells", "two_pt_fg_cells", "play_result_cells"]

function GameNBA({ token, setToken, setIsShowBottomBar, setSelectedTabIdx}) {
  const [showBets, setShowBets] = useState(true);
  const [showPauseScreen, setShowPauseScreen] = useState(false);
  const [type, setType] = useState({ name: "", odds: "", icon: "" });
  const [player, setPlayer] = useState({ id: 0, name: "", odds: "" });
  const [newPossesionOptions, setNewPossesionOptions] = useState(getDefaultOptionsState());
  const [nbaDataResponse, setNbaDataResponse] = useState([]);
  // const [newPlays, setNewPlays] = useState(plays);
  const [gameStatus, setGameStatus] = useState();
  const [selectedBet, setSelectedBet] = useState(null);
  const [selectedButton, setSelectedButton] = useState(0);
  const [betMountClicked, setBetMountClicked] = useState(false);
  const [oddsClicked, setOddsClicked] = useState(false);
  const classes = useStyles();
  const [activeShow, setActiveShow] = useState(true);
  const [betAmount, setBetAmount] = useState(0);
  const [showPotentialReturn, setShowPotentialReturn] = useState(false);
  const [betErrorMsg, setBetErrorMsg] = useState(false);
  const [showBetErrorAlert, setShowBetErrorAlert] = useState(false);
  const [showBetWonAlert, setShowBetWonAlert] = useState(false);
  const [showBetBar, setShowBetBar] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [seeMoreClicked, setSeeMoreClicked] = useState(false);
  const [recentBets, setRecentBets] = useState([]);
  const [winAmount, setWinAmount] = useState(0);
  const [outcomeId, setOutcomeId] = useState(0);
  let interval = useRef();
  let statusInterval = useRef();
  let nbaLiveGamesRefreshInterval = useRef();
  let processProbabilities = true;

  const prevGameStatus = usePrevious(gameStatus);
  // const prevNewPlays = usePrevious(newPlays);
  // const prevNewPossesionOptions = usePrevious(newPossesionOptions);
  const prevOutcomeId = usePrevious(outcomeId);
  // const [betListTitle, setBetListTitle] = useState("SELECT BET OPTION");
  const [nextPossesion, setNextPossesion]= useState(false);
  const [nextPossesionClicked, setNextPossClicked] = useState(false);
  const [nextPossessionOptionClicked, setNextPossessionOptionClicked] = useState(false);
  const [makeMissValues, setMakeMissValues] = useState([]);
  const [nbaPlayersVal, setNbaPlayersVal] =  useState([]);
  const [bettingOptsForSection, setBettingOptsForSection] =  useState([]);
  const [nbaResponse, setNbaResponse] = useState([]);
  const [game, setGame] = useState({});
  const [tournament, setTournament] = useState({});
  const [nbaTeam, setNbaTeam] = useState(null);
  // const [tournamentId, setTournamentId] = useState(null);
  // const [gameId, setGameId] = useState(null);
  const [quarter, setQuarter] = useState(0);
  // const prevNbaResponse = usePrevious(nbaResponse);
  const [mainShotZoneOpt, setMainShotZoneOpt] =  useState({
    isClicked: false,
    playerId: '',
    mainShotId:''
  });
  const [combineBet, setConbineBet] =  useState({
    mainBet: "",
    playerBet: ""
  });

  const setGameId = (gameIdParam) => {
    gameId = gameIdParam
  }

  const nbaMainOptionName = ["Mid Range", "In the Paint", "Beyond 3 PT Line", "Score", "Miss", "Attempt"];

  const mappNbaData = (response, possessionOutcomeName) => {
    let possessionOutcome = [];
    let i = 0;

    for (const item in response) {
      if(betOptions.indexOf(item) > -1)  {
        response[item].forEach((itm) => {
          // let prob = Number(itm.percent / 100).toFixed(2);
          let prob = itm.percent / 100
          // let odds = ((1 - prob) / prob).toFixed(1);
          let odds = convertProbToOdds(prob)
          itm["odds"] = parseFloat(odds);
          itm["id"] = i;
          i++;
        })
      }
    }

    if (possessionOutcomeName === "2-point shot") {
      possessionOutcome.push(response["two_pt_fg_cells"])
    }
    if (possessionOutcomeName === "3-point shot") {
      possessionOutcome.push(response["three_pt_fg_cells"])
    }
    // if(possessionOutcomeName === "Shooting zone") {
    //     possessionOutcome.push(response["shot_zone_cells"])
    // }
    if(possessionOutcomeName === "Possession result") {
      possessionOutcome.push(response["play_result_cells"])
    }
    // if(possessionOutcomeName === "Player shot") {
    //   possessionOutcome.push(response["player_shot_cells"])
    // }
    return possessionOutcome;
  }

  const getNbaData = (item, response = nbaDataResponse) => {
    let data = [];
    if (response){
      data = mappNbaData(response, item.name);
      // nbaPlayers = data;
      setBettingOptsForSection(data)
    }
    return data;
  }

  const renderNbaMainOptions = (type, sectionData) => {
    if (!sectionData || sectionData.length === 0) {
      return []
    }
    let makeMiss = [];
    if(sectionData[0]) {
      switch (type.name){
        case '2-point shot':
          sectionData[0].forEach((item) => {
            if(item.title === "MAKE" && item.ProbFam === "2PT3PT MAKE/MISS") {
              item.name = "Score"
              makeMiss.push(item);
            }
            else if(item.title === "MISS" && item.ProbFam === "2PT3PT MAKE/MISS") {
              item.name = "Miss"
              makeMiss.push(item);
            }
            else if(item.title === "ATTEMPT" && item.ProbFam === "POSS EVENT") {
              item.name = "Attempt"
              makeMiss.push(item);
            }
          })
          break;
        case '3-point shot':
          sectionData[0].forEach((item) => {
            if(item.title === "MAKE" && item.ProbFam === "2PT3PT MAKE/MISS") {
              item.name = "Score";
              makeMiss.push(item);
            }
            else if(item.title === "MISS" && item.ProbFam === "2PT3PT MAKE/MISS") {
              item.name = "Miss";
              makeMiss.push(item);
            }
            else if(item.title === "ATTEMPT" && item.ProbFam === "POSS EVENT") {
              item.name = "Attempt";
              makeMiss.push(item);
            }
          })
          break;
        case 'Shooting zone':
          sectionData[0].forEach((item) => {
            if(item.ProbFam === "SHOT ZONE") {
              item.name = item.title;
              makeMiss.push(item);
            }
            else {
              item.name = item.title;
            }
          })
          break;
        case 'Possession result':
          sectionData[0].forEach((item) => {
            item.name = item.title;
            makeMiss.push(item);
          })
          break;
        case "Player shot":
          sectionData[0].forEach((item) => {
            item.name = item.title;
            makeMiss.push(item);
          })
          break;
      }
    }
    return makeMiss;
  }

  const renderPointShotPlayers = (mainOption, pt) => {
    if (!bettingOptsForSection || bettingOptsForSection.length === 0)
      return []
    let players = [];
    if (bettingOptsForSection[0]) {
      bettingOptsForSection[0].forEach((item) => {
        if(item.DVCat === "player_" + pt + "_fgm" && (mainOption.name === "Score" || (pt.toUpperCase() + " FGM") === mainOption.ProbFam)) {
          item.name = item.title
          players.push(item);
        }
        if(item.DVCat === "player_" + pt + "_fgx" && (mainOption.name === "Miss" || (pt.toUpperCase() + " FGX") === mainOption.ProbFam)) {
          item.name = item.title
          players.push(item);
        }
        if(item.DVCat === "player_" + pt + "_fga" && (mainOption.name === "Attempt" || (pt.toUpperCase() + " FGA") === mainOption.ProbFam)) {
          item.name = item.title
          players.push(item);
        }
      })
    }
    return players;
  }

  const renderShootingZonePlayer = (shootingZone) => {
    if (!bettingOptsForSection || bettingOptsForSection.length === 0)
      return []
    let players = [];
    if(bettingOptsForSection[0]) {
      bettingOptsForSection[0].forEach((item) => {
        if(item.ProbFam === "Player" && item.title === shootingZone.name) {
          item.name = item.player
          players.push(item);
        }
      })
    }
    return players;
  }

  const renderNbaPlayersWhenMainOptionSelected = (betType, mainOption) => {
    // console.log('renderNbaPlayersWhenMainOptionSelected betType = ', betType)
    // console.log('renderNbaPlayersWhenMainOptionSelected mainOption = ', mainOption)
    let mainOptionName = mainOption.name;
    let players = []
    switch(betType) {
      case '2-point shot':
        // if(mainOptionName === "Score" || mainOptionName === "Miss" || mainOptionName === "Attempt")
          players = renderPointShotPlayers(mainOption, "2pt");
        break;
      case '3-point shot':
        // if(mainOptionName === "Score" || mainOptionName === "Miss" || mainOptionName === "Attempt")
          players = renderPointShotPlayers(mainOption, "3pt");
        break;
      case 'Shooting zone':
        if(mainOptionName === "Mid Range" || mainOptionName === "In the Paint" || mainOptionName === "Beyond 3 PT Line")
          players = renderShootingZonePlayer(mainOption);
        break;
      default:
        break;
    }
    return players;
  }

  useEffect(() => {
    if(type.name === "2-point shot" || type.name === "3-point shot" || type.name === "Shooting zone") {
      if(nbaMainOptionName.indexOf(selectedButton.name) > -1) {
        setConbineBet((prevState) => ({
          ...prevState,
          mainBet: selectedButton.name
        }));
        let players = [];
        players = renderNbaPlayersWhenMainOptionSelected(type.name, selectedButton);
        setNbaPlayersVal(players);
      }
      else {
        if(combineBet.mainBet !== "") {
          setConbineBet((prevState) => ({
            ...prevState,
            playerBet: selectedButton.name
          }));
        }
      }

    }
  }, [selectedButton]);

  //
  // this doesn't seem to be needed/a holdover from NFL
  //
  // useEffect(() => {
  //   if (
  //       prevNewPlays &&
  //       prevNewPlays.odds !== newPlays.odds
  //   )  {
  //     setNewPlays(plays);
  //   }
  // }, [plays]);

  const processNbaDataResponse = (response) => {
    const sectionOptions = getNbaData(type, response);
    let mainOptions = renderNbaMainOptions(type, sectionOptions);
    setMakeMissValues(mainOptions);
    let players = [];
    // console.log('render players, selectedButton = ', selectedButton)
    players = renderNbaPlayersWhenMainOptionSelected(type.name, selectedButton);
    // console.log('calling setNbaPlayersVal, players = ', players)
    // console.log('calling setNbaPlayersVal, processProbabilities = ' + processProbabilities)
    setNbaPlayersVal(players);
  }

  useEffect(() => {
    // console.log('hello = ', processProbabilities)
    // console.log('nbaDataResponse = ', nbaDataResponse)
    // console.log('hey = ', possesionOptions && possesionOptions[0] && possesionOptions[0].odds)
    // if(processProbabilities && prevNbaResponse && JSON.stringify(prevNbaResponse) !== JSON.stringify(nbaDataResponse)) {
    if (processProbabilities) {
      // console.log('new data = ', nbaDataResponse)

      // don't need this, nbaDataResponse has already been set
      // and that's why we are here
      // setNbaResponse(nbaDataResponse);

      // const sectionOptions = getNbaData(type);
      // let mainOptions = renderNbaMainOptions(type, sectionOptions);
      // setMakeMissValues(mainOptions);
      // let players = [];
      // // console.log('render players, selectedButton = ', selectedButton)
      // players = renderNbaPlayersWhenMainOptionSelected(type.name, selectedButton);
      // // console.log('calling setNbaPlayersVal, players = ', players)
      // // console.log('calling setNbaPlayersVal, processProbabilities = ' + processProbabilities)
      // setNbaPlayersVal(players);

      processNbaDataResponse(nbaDataResponse)
    }
    // if (processProbabilities && possesionOptions && possesionOptions[0]
    //   && newPossesionOptions && newPossesionOptions[0]
    //   && newPossesionOptions[0].odds !== possesionOptions[0].odds) {

    // if (processProbabilities) {
    //   setNewPossesionOptions(possesionOptions)
    // }
  }, [nbaDataResponse]);

  // useEffect(() => {
  //   console.log('change newPossesionOptions = ', newPossesionOptions && newPossesionOptions[0] && newPossesionOptions[0].odds)
  //   // if (
  //   //     prevNewPossesionOptions &&
  //   //     prevNewPossesionOptions.odds !== newPossesionOptions.odds
  //   // )  {
  //   //   setNewPossesionOptions(possesionOptions);
  //   // }
  // }, [possesionOptions]);

  // const processTodaysGamesResponse = (response) => {
  //   // console.log('Response = ', response)
  //   // console.log('processTodaysGamesResponse = ', gameId)
  //   if (response && response.data && response.data.games && response.data.games.length > 0) {
  //     for (let i = 0; i < response.data.games.length; ++i) {
  //       const loopResponseGame = response.data.games[i]
  //       if (loopResponseGame["gameId"] === gameId) {
  //         // console.log('Matched game')
  //         // console.log("Game = ", loopResponseGame)
  //         if (loopResponseGame["isGameActivated"]) {
  //           let quarter = loopResponseGame["quarter"]
  //           // console.log("Quarter = " + quarter)
  //           setQuarter(quarter)
  //         }
  //
  //         const min_game_obj = {
  //           away: loopResponseGame["away"],
  //           home: loopResponseGame["home"],
  //           // away_points: game["awayPoints"] || 0,
  //           // home_points: game["homePoints"] || 0,
  //           // quarter: quarter,
  //           // quarterRaw: game["quarter"],
  //           // clock: game["isGameActivated"] ? game["clock"] : '',
  //           clock: game["clock"],
  //           id: loopResponseGame["gameId"],
  //           gameId: loopResponseGame["gameId"],
  //           startDateEastern: loopResponseGame['startDateEastern'],
  //           // lastOutcomeFormatted: game['lastOutcomeFormatted']
  //         }
  //         if (loopResponseGame['startDatetimeUtc']) {
  //           min_game_obj.startDate = utcDateStrToDate(loopResponseGame['startDatetimeUtc'])
  //         }
  //         // console.log('setting game, game = ', min_game_obj)
  //         setGame(min_game_obj);
  //       }
  //     }
  //   }
  // }

  // const fetchLiveNBAGames = () => {
  //   // console.log('fetchLiveNBAGames, gameId = ', gameId)
  //   if (!gameId)
  //     return
  //   getLiveGames()
  //       .then(response => {
  //         // if (response.data)
  //         //   setLiveGamesArr(response.data)
  //         processTodaysGamesResponse(response)
  //       }).catch((error) => {
  //     console.log("Error getting the list of live NBA games:: ", error);
  //   });
  // }
  //
  // const getLiveNBAGames = () => {
  //   fetchLiveNBAGames();
  //   nbaLiveGamesRefreshInterval.current  = setInterval(() => {
  //     fetchLiveNBAGames();
  //   }, 4000)
  // }

  useEffect(() => {
    // // let team = localStorage.getItem("team");
    // // console.log('GameNBA team = ', team)
    // let game = localStorage.getItem("gameNba");
    // // console.log('GameNBA useEffect')
    // // console.log('GameNBA game3 = ', JSON.parse(game))
    // // console.log('GameNBA game3 home = ', game['home'])
    // // console.log('GameNBA game3 away = ', game.away)
    // let gameParsed = JSON.parse(game)
    // // console.log('Game parsed = ', gameParsed)
    // // const team = gameParsed['away']
    // setGame(gameParsed)
    // setGameId(gameParsed.id)

    // console.log('gameParsed.id = ', gameParsed.id)
    // console.log('game.id = ', game.id)
    // console.log('gameId = ', gameId)
    setNbaPlayersVal([])

    // what was the idea here? why get info for all games
    // getLiveNBAGames()

    if (nextPossessionOptionClicked) {
      // let team = localStorage.getItem("team");
      // console.log('Team = ', team)
      // processProbabilities = true;
      // getNBAGameProbabilities(team);
    }
    return function cleanup() {
      // console.log('--------------GameNBA cleanup')
      // resetBetData()
      // if (interval && interval.current)
      //   clearInterval(interval.current)
      if (nbaLiveGamesRefreshInterval && nbaLiveGamesRefreshInterval.current)
        clearInterval(nbaLiveGamesRefreshInterval.current)
    }
  }, [nextPossessionOptionClicked]);

  useEffect(() => {
    console.log('useEffect type')
    setIsShowBottomBar(true)
    setSelectedTabIdx(0)

    let team = localStorage.getItem("team");
    // console.log('Team = ', team)
    processProbabilities = true;

    let tournamentLocal = localStorage.getItem("tournament");
    // console.log('tournamentLocal = ', tournamentLocal)

    if (tournamentLocal && tournamentLocal !== 'null') {
      let tournamentParsed = JSON.parse(tournamentLocal);
      // console.log('tournamentParsed = ', tournamentParsed)
      // console.log('tournamentParsed.id ', tournamentParsed.id)

      setGameId(tournamentParsed.game_id)
      setTournament(tournamentParsed)
      // setTournamentId(tournamentParsed.id)
      // console.log('Tournament = ', tournament)
      getGameStatusInfo(tournamentParsed, null)
    } else {
      let gameJsonStr = localStorage.getItem('gameNba')
      // console.log('gameJsonStr = ', gameJsonStr)
      if (gameJsonStr) {
        const gameParsed = JSON.parse(gameJsonStr)
        // console.log('gameParsed = ', gameParsed)
        setGameId(gameParsed.id)
        setGame(gameParsed)
        setGameStatus(gameParsed)
        setQuarter(gameParsed.quarter)
        getGameStatusInfo(null, gameParsed.id);
      }
    }

    setNbaPlayersVal([])
    if (team)
      getNBAGameProbabilities(team);

    // let mainOptions = renderNbaMainOptions(type);
    // setMakeMissValues(mainOptions);
    return function cleanup() {
      // console.log('--------------GameNBA type cleanup')
      resetBetData()
      if (interval && interval.current)
        clearInterval(interval.current)

      setIsShowBottomBar(false)
    }
  }, [type]);

  useEffect(() => {}, [selectedBet]);

  useEffect(() => {
    // console.log('showbets = ', showBets)
    if (showBets) {
      // setType({ name: "", odds: "", icon: "" })
      setSelectedButton(0);
      setSelectedBet(null);
      setShowBetBar(false)
      resetBetData()

      setBetMountClicked(false);
      setOddsClicked(false);
    } else {
      setShowBetBar(true)
    }
  }, [showBets]);

  useEffect(() => {
    // console.log('useEffect showBetWonAlert ', showBetWonAlert, ", showBets = ", showBets)
    if (!showBetWonAlert) {
      setWinAmount(0);
      setRecentBets([]);
      if (!showPotentialReturn && !showBetErrorAlert)
        setIsShowBottomBar(true)
    } else {
      setIsShowBottomBar(false)
    }
  }, [showBetWonAlert]);

  useEffect(() => {
    // console.log('useEffect showPotentialReturn ', showPotentialReturn)
    if (!showPotentialReturn) {
      if (!showBetWonAlert && !showBetErrorAlert)
        setIsShowBottomBar(true)
    } else if (!showBets) {
      setIsShowBottomBar(false)
    }
  }, [showPotentialReturn]);

  useEffect(() => {
    // console.log('useEffect showBetErrorAlert ', showBetErrorAlert)
    if (!showBetErrorAlert) {
      if (!showBetWonAlert && !showPotentialReturn)
        setIsShowBottomBar(true)

      setShowBetBar(!showBets)
    } else {
      setActiveShow(false)
      setIsShowBottomBar(false)
      setShowBetBar(false)
      setOddsClicked(false)
    }
  }, [showBetErrorAlert]);

  useEffect(() => {
    if ((!game || !game.id) && tournament && tournament.game_id) {
      // console.log('----------------------------------')
      // console.log('------ tournament: ', tournament)
      // console.log('------ game: ', game)
      // console.log('----------------------------------')
      const min_game_obj = {
        away: tournament.away,
        home: tournament.home,
        id: tournament.game_id,
        gameId: tournament.game_id,
        // startDateEastern: loopResponseGame['startDateEastern'],
        // lastOutcomeFormatted: game['lastOutcomeFormatted']
      }
      const start_date = utcDateStrToDate(tournament.startDatetimeUtc)
      min_game_obj.startDate = start_date

      // const is_started = start_date.getTime() < Date.now()
      // if (!is_started) {
      //   // const start_date_str = formatStartDate(start_date);
      //   min_game_obj.startDateEastern = start_date
      // }
      setGame(min_game_obj)
    }
    if (prevGameStatus &&  JSON.stringify(prevGameStatus) !== JSON.stringify(gameStatus)) {

      if(gameStatus.outcome_id !== prevOutcomeId) {
        setShowBetWonAlert(false);
        setSeeMoreClicked(false);
      }
    }

    // if (gameStatus && gameStatus.open_calls && gameStatus.open_calls > 0) {
    //   setIsShowBottomBar(false)
    //   setShowPauseScreen(true)
    // } else {
    //   setIsShowBottomBar(true)
    //   setShowPauseScreen(false)
    // }
  }, [gameStatus]);

  // const setNbaTeam = (team) => {
  //   // console.log('GameNBA Set NBA Team = ' + team)
  //   nbaTeam = team
  // }

  const getNBAGameProbabilities = async (team) => {
    setNbaTeam(team)
    const cachedResponse = localStorage.getItem('nbaDataResponse')
    // console.log('Cached response = ', cachedResponse)
    if (cachedResponse) {
      processNbaDataResponse(JSON.parse(cachedResponse));
    }
    // console.log('getNBAGameProbabilities Team = ', team)
    // console.log('getNBAGameProbabilities showBets = ', showBets)
    if (!showBets)
      await fetchNbaData(team);
    interval.current = setInterval(async () => {
      if (!showBets)
        await fetchNbaData(team);
    }, 2000);
  };

  const fetchNbaData = async (team) => {
    // console.log('GameNBA fetchNbaData = ', team)
    // dataResponse = await getNbaGameProbabilities();
    if (nbaTeam == null || team !== nbaTeam) {
      return
    }
    let dataResponse = await getNbaGameProbabilities(team);
    // console.log('GameNBA nbaTeam = ' + nbaTeam, ', team = ' + team)
    // console.log('about to call mappMainProbability')
    setNbaDataResponse(dataResponse.data)
  }

  const resetBetData = () => {
    // console.log('GameNBA resetBetData')
    if (interval && interval.current) {
      // console.log('resetBetData clear interval')
      clearInterval(interval.current)
    }
    processProbabilities = false;
    // setNewPossesionOptions(getDefaultOptionsState())
    setMakeMissValues([])
    setNbaPlayersVal([])
    // setNbaTeam(null)
  }

  const fetchBetsData = (response) => {
    // console.log('fetchBetsData response = ', response)
    let amount = 0;
    // let alreadyShow = false;
    // let alreadyLostShow = false;
    let wonBets = [];
    // let betIds = [];
    // let betLostIds = []
    if (response.length > 0) {
      let retrievedBetWonIds = JSON.parse(localStorage.getItem("betsWonIds"));
      // let retrievedBetLostIds = JSON.parse(localStorage.getItem("betsLostIds"));
      response.forEach((item) => {
        // if (item.did_win_bet === 0) {
        //   if(!retrievedBetLostIds) {
        //     betLostIds.push(item.id);
        //     wonBets.push(item);
        //     amount = winAmount - parseFloat(item.amount);
        //     localStorage.setItem("betsLostIds", JSON.stringify(betLostIds));
        //     setRecentBets([...recentBets, item]);
        //     setWinAmount(amount);
        //   }
        //   else {
        //     alreadyLostShow = retrievedBetLostIds.some((arrVal) => item.id === arrVal);
        //     if (!alreadyLostShow) {
        //       retrievedBetLostIds.push(item.id);
        //       wonBets.push(item);
        //       amount = winAmount - parseFloat(item.amount);
        //       localStorage.setItem("betsLostIds", JSON.stringify(retrievedBetLostIds));
        //       setRecentBets([...recentBets, item]);
        //       setWinAmount(amount);
        //     }
        //   }
        //   /* wonBets.push(item);
        //    winAmount -= parseFloat(item.amount);*/
        // }
        if (item.did_win_bet === 1) {
          let alreadyShown = false
          if (retrievedBetWonIds) {
            alreadyShown = retrievedBetWonIds.some((arrVal) => item.id === arrVal);
          }
          if (!alreadyShown) {
            let win_amount = item.winning_amount? item.winning_amount : 0;
            // amount = winAmount + parseFloat(win_amount);
            amount += parseFloat(win_amount);

            wonBets.push(item);
          }
        }
      });

      if (wonBets.length > 0) {
        setWinAmount(amount);
        setRecentBets(wonBets);
        const betsWonIds = wonBets.map(x => x.id)
        if (retrievedBetWonIds) {
          retrievedBetWonIds.push(...betsWonIds);
          localStorage.setItem("betsWonIds", JSON.stringify(retrievedBetWonIds));
        } else {
          localStorage.setItem("betsWonIds", JSON.stringify(betsWonIds))
        }
      }
    }
  }

  const fetchGameStatusData = (tourn, gmId) => {
    // console.log('fetchGameStatusData, tournament id = ', tournament?.id)
    if (token?.id && (tourn?.id || gmId)) {
      let queryParams = {
        // user_id: token.id
      };
      // if (tournament) {
      //   queryParams.tournament_id = tournament.id
      // }
      queryParams.game_id = gmId;
      if (tourn?.id) {
        queryParams.tournament_id = tourn.id;
      }
      // console.log('fetchGameStatusData queryParams = ', queryParams)
      getGameInfoNba(queryParams, token)
          .then((response) => {
            // console.log('getGameInfoNba response.data = ', response.data)
            if (response.data) {
              setGameStatus(response.data);
            }
            if (response.data.recent_bets) {
              fetchBetsData(response.data.recent_bets);
            }
          })
          .catch((error) => {
            console.log("Error getting the game status:: ", error);
            if (error?.response?.status === 403 || error?.response?.status === 400) {
              localStorage.removeItem("token");
              window.location = "/"
            }
          });
    }
  }

  const getGameStatusInfo = (tourn, gmId) => {
    fetchGameStatusData(tourn, gmId);
    statusInterval.current = setInterval(() => {
      // console.log('getGameStatusInfo, tournament id = ', tournamentId)
      fetchGameStatusData(tourn, gmId);
    }, 1000);
  };

  // useEffect(() => {
  //   if (token && token.id) {
  //     getGameStatusInfo();
  //   }
  //   return function cleanup() {
  //     if (statusInterval && statusInterval.current)
  //       clearInterval(statusInterval.current)
  //   }
  // }, [token]);

  useEffect(() => {
    // console.log('useEffect, tournament id = ', tournament.id)
    if (token && token.id) {
      // let queryParams = { user_id: token.id };
      // if (tournament) {
      //   queryParams.tournament_id = tournament.id
      // }
      if (statusInterval && statusInterval.current)
        clearInterval(statusInterval.current)

      getGameStatusInfo();

      return function cleanup() {
        if (statusInterval && statusInterval.current)
          clearInterval(statusInterval.current)
      }
    }
  }, []);

  function onNbaTeamPress(team) {
    onHideBetForTeamPaused()

    const team_lower = team.toLowerCase()
    localStorage.setItem("team", team_lower)
    resetBetData()

    setNextPossClicked(true);
    setNextPossesion(true);
    setNbaTeam(team_lower)
  }

  function onBetForTeamPaused() {
    setIsShowBottomBar(false)
    setShowPauseScreen(true)
  }

  function onHideBetForTeamPaused() {
    setIsShowBottomBar(true)
    setShowPauseScreen(false)
  }

  let nbaBody = (
      <Grid
          direction="row"
          container
          spacing={2}
          className={classes.grid}
          style={{
            // maxHeight:"50vh",
            paddingBottom: 120,
            // overflow: "auto",
          }}
          wrap="wrap"
      >
        <Grid direction="row" container className={classes.containerPlay} style={{marginBottom: 10}}>
          <Grid direction="row" container className={classes.containerPlay}>
            {type.name === "2-point shot" && (
                <Grid item className={classes.svgContainer}>
                  <img alt={"2-point-shot"} className="MuiSvgIcon-root" src={TwoPoint}  style={{fontSize: "14px", paddingLeft: "6.31px", paddingRight: "11.31px"}} />
                </Grid>
            )}
            {type.name === "3-point shot" && (
                <Grid item className={classes.svgContainer}>
                  <img alt={"3-point-shot"} className="MuiSvgIcon-root" src={ThreePoint} style={{fontSize: "14px", paddingLeft: "6.31px", paddingRight: "11.31px"}} />
                </Grid>
            )}
            {type.name === "Possession result" && (
                <Grid item className={classes.svgContainer}>
                  <FlagIcon style={{color: "#FDC934", fontSize: "20px",  paddingLeft: "6.31px", paddingRight: "11.31px"}} />
                </Grid>
            )}
            {/*type.name === "Player shot" && (
                <Grid item className={classes.svgContainer}>
                  <img alt={"Player-shot"} className="MuiSvgIcon-root" src={TShirt} style={{fontSize: "14px", paddingLeft: "6.31px", paddingRight: "11.31px"}} />
                </Grid>
            )*/}
            {/*type.name === "Shooting zone" && (
                <Grid item className={classes.svgContainer}>
                  <CompassCalibrationIcon style={{color: "#FDC934", fontSize: "20px", paddingLeft: "6.31px", paddingRight: "11.31px"}} />
                </Grid>
            )*/}
            {type.name === "2-point shot" && (
                <Grid item>
                  {" "}
                  <Typography className={classes.nbaTargetSec}>
                    2-point shot
                  </Typography>{" "}
                </Grid>
            )}
            {type.name === "3-point shot" && (
                <Grid item>
                  {" "}
                  <Typography className={classes.nbaTargetSec}>
                    3-point shot
                  </Typography>{" "}
                </Grid>
            )}
            {type.name === "Possession result" && (
                <Grid item>
                  {" "}
                  <Typography className={classes.nbaTargetSec}>
                    Possession result
                  </Typography>{" "}
                </Grid>
            )}
            {/*type.name === "Player shot" && (
                <Grid item>
                  {" "}
                  <Typography className={classes.nbaTargetSec}>
                    Player shot
                  </Typography>{" "}
                </Grid>
            )*/}
            {/*type.name === "Shooting zone" && (
                <Grid item>
                  {" "}
                  <Typography className={classes.nbaTargetSec}>
                    Shooting zone
                  </Typography>{" "}
                </Grid>
            )*/}
          </Grid>
        </Grid>
        {makeMissValues.map((item) => (
            <Grid item xs={6} className={classes.item}>
              <BetButton
                  key={item}
                  mainShotZoneOpt={mainShotZoneOpt}
                  onClick={() => {
                    console.log('nbaMainOptionName = ' + nbaMainOptionName)
                    console.log('item.name = ' + item.name)
                    console.log('oddsClicked = ' + oddsClicked)
                    if (nbaMainOptionName.indexOf(item.name) > -1) {
                      setMainShotZoneOpt((prevState) => ({
                        ...prevState,
                        isClicked: true,
                        mainShotId: item.id
                      }));
                    }
                    setSelectedBet(item.odds);
                    setSelectedButton(item);
                    setOddsClicked(true);
                    if (selectedButton.id === item.id && oddsClicked) {
                      if(nbaMainOptionName.indexOf(item.name) > -1) {
                        // console.log('Hello')
                        setNbaPlayersVal([]);
                        setMainShotZoneOpt({
                          playerId: "",
                          isClicked: false,
                          mainShotId:''
                        });
                      }
                      // console.log('setActiveShow(false)')
                      setActiveShow(false);
                      setSelectedButton(0);
                    } else {
                      // console.log('setActiveShow(true)')
                      setActiveShow(true);
                    }
                  }}
                  id ={item.id}
                  className={
                    (item.id === selectedButton.id && showBets && activeShow) ||
                    (item.id === selectedButton.id && oddsClicked && activeShow)
                        ? classes.activeButton
                        : classes.button
                  }
                  setType={setPlayer}
                  type="small"
                  badgeContent={item.odds.toFixed(1) + "x"}
                  content={item.name}
                  setShowBets={setShowBets}
                  setOddsClicked={setOddsClicked}
                  spanClassName={
                    (item.id === selectedButton.id && showBets && activeShow) ||
                    (item.id === selectedButton.id && oddsClicked && activeShow)
                        ? classes.activeBtnSpan
                        : classes.btnSpan
                  }
              />
            </Grid>
        ))}
        { nbaPlayersVal.length > 0 && <Grid item xs={12} className={classes.containerPlayWrap}>
          <Grid direction="row" container className={classes.containerPlay}>
            <Grid item className={classes.svgContainer}>
              <img alt={"Player-shot"} className="MuiSvgIcon-root" src={TShirt} style={{fontSize: "14px", paddingLeft: "6.31px", paddingRight: "11.31px"}} />
            </Grid>
            <Grid item>
              {" "}
              <Typography className={classes.nbaTargetSec}>
                ADD PLAYER
              </Typography>{" "}
            </Grid>
          </Grid>
        </Grid>}
        {nbaPlayersVal.map((item) => (
            <Grid item xs={6} className={classes.item}>
              <BetButton
                  key={item}
                  mainShotZoneOpt = {mainShotZoneOpt}
                  onClick={() => {
                    console.log('---- BET SECTION CLICK')
                    setMainShotZoneOpt((prevState) => ({
                      ...prevState,
                      playerId: item.id
                    }));
                    setSelectedBet(item.odds);
                    setSelectedButton(item);
                    setOddsClicked(true);
                    setActiveShow(true);

                    // if (selectedButton.id === item.id && oddsClicked) {
                    //   setNbaPlayersVal([]);
                    //   setActiveShow(false);
                    //   setSelectedButton(0);
                    //   setMainShotZoneOpt({
                    //     isClicked: false,
                    //     mainShotId: "",
                    //     playerId: ""
                    //   });
                    // } else {
                    //   setActiveShow(true);
                    // }
                  }}
                  item={item}
                  className={
                    (item.id === selectedButton.id && showBets && activeShow) ||
                    (item.id === selectedButton.id && oddsClicked && activeShow)
                        ? classes.activeButton
                        : classes.button
                  }
                  setType={setPlayer}
                  type="small"
                  badgeContent={item.odds.toFixed(1) + "x"}
                  content={item.name}
                  setShowBets={setShowBets}
                  setOddsClicked={setOddsClicked}
                  spanClassName={
                    (item.id === selectedButton.id && showBets && activeShow) ||
                    (item.id === selectedButton.id && oddsClicked && activeShow)
                        ? classes.activeBtnSpan
                        : classes.btnSpan
                  }
              />
            </Grid>
        ))}
      </Grid>

  );


  // console.log('GameNBA2 Game = ', game)
  // console.log('render newPossesionOptions = ', newPossesionOptions && newPossesionOptions[0] && newPossesionOptions[0].odds)
  // console.log('render showBets = ', showBets)
  // console.log('render GameNBA nbaTeam = ', nbaTeam)

  return (
      <Box className={classes.body}>
        <MatchHeader
            gsData={gameStatus}
            onHideBetForTeamPaused={onHideBetForTeamPaused}
            tournament={tournament}
            token={token}
            setShowBets={setShowBets}
            showBets={showBets}
            setRefresh={setRefresh}
            gameType={'NBA'}
            game={game}
            quarter={quarter}
            nextPossesion={nextPossesion}
            setNextPossesion={setNextPossesion}
            nextPossesionClicked={nextPossesionClicked}
            setNextPossClicked={setNextPossClicked}
            nextPossessionOptionClicked={nextPossessionOptionClicked}
            setNextPossessionOptionClicked={setNextPossessionOptionClicked}
            setMainShotZoneOpt={setMainShotZoneOpt}
            setNbaPlayersVal={setNbaPlayersVal}
            setNbaTeam={setNbaTeam}
            onNbaTeamPress={onNbaTeamPress}
        />

        {showPauseScreen ? <PauseScreenNBA nbaTeam={nbaTeam} onHideBetForTeamPaused={onHideBetForTeamPaused}/> :
        <div>
          <Fade bottom>
            <Grid container spacing={2} className={classes.game}>
              {showBets && (
                  <BetList
                      newPossesionOptions={newPossesionOptions}
                      setType={setType}
                      setShowBets={setShowBets}
                      getNbaData={getNbaData}
                      setOddsClicked={setOddsClicked}
                      gameType={'NBA'}
                      game={game}
                      gameStatus={gameStatus}
                      setNextPossesion={setNextPossesion}
                      nextPossesion={nextPossesion}
                      setNextPossessionOptionClicked={setNextPossessionOptionClicked}
                      onNbaTeamPress={onNbaTeamPress}
                      nbaTeam={nbaTeam}
                      tournament={tournament}
                  />
              )}
            </Grid>
          </Fade>
          <Fade bottom when={!showBets}>
            {!showBets && nbaBody}
          </Fade>
          {/*<Fade bottom when={!showBets}>
            {!showBets && gameType === "NBA-PhoenixVsLALakers" && nbaBody}
          </Fade>*/}
          <BetBar
              gameType={'NBA'}
              nbaTeam={nbaTeam}
              hide={!showBetBar}
              setShowPauseScreen={onBetForTeamPaused}
              selectedButton = {selectedButton}
              tgt={selectedButton.name}
              setShowBets={setShowBets}
              type={type}
              odds={selectedBet}
              token={token}
              setToken={setToken}
              rpk={type.name.split(/[ ]+/)[0].toUpperCase()}
              setBetMountClicked={setBetMountClicked}
              betMountClicked={betMountClicked}
              activeShow={activeShow}
              setBetAmount={setBetAmount}
              setShowPotentialReturn={setShowPotentialReturn}
              combineBet={combineBet}
              setConbineBet={setConbineBet}
              setNbaPlayersVal={setNbaPlayersVal}
              setMainShotZoneOpt={setMainShotZoneOpt}
              setNextPossessionOptionClicked={setNextPossessionOptionClicked}
              tournament={tournament}
              gsData={gameStatus}
              setBetErrorMsg={setBetErrorMsg}
              setShowBetErrorAlert={setShowBetErrorAlert}
          />

          <Grid
            className={
              showBetErrorAlert ? classes.fadeIn : classes.fadeOut
            }
          >
            <Grid item className={classes.potentialReturnContainer}>
              <BetErrorAlert
                visible={showBetErrorAlert}
                betErrorMsg={betErrorMsg}
                setShowBetErrorAlert={setShowBetErrorAlert}
                duration={4000}
              />
            </Grid>
          </Grid>

          <Grid
              className={
                showPotentialReturn && showBets ? classes.fadeIn : classes.fadeOut
              }
          >
            <Grid item className={classes.potentialReturnContainer}>
              <PotentialReturnAlert
                  visible={showPotentialReturn && showBets}
                  betAmount={betAmount}
                  setShowPotentialReturn={setShowPotentialReturn}
                  duration={4000}
                  tournament={tournament}
              />
            </Grid>
          </Grid>

          <Grid
              className={
                showBetWonAlert ? classes.fadeIn : classes.fadeOut
              }
          >
            <Grid item className={classes.potentialReturnContainer}>
              <BetWonAlert
                  visible={showBetWonAlert}
                  winAmount={winAmount}
                  setShowBetWonAlert={setShowBetWonAlert}
                  recentBets={recentBets}
                  seeMoreClicked={seeMoreClicked}
                  setSeeMoreClicked={setSeeMoreClicked}
                  duration={seeMoreClicked ? null : 3000}
              />
            </Grid>
          </Grid>
        </div>}
      </Box>
  );
}
export default GameNBA;
