// utc_date_str - a string in format "YYYY-mm-DD HH:MM"
export function utcDateStrToDate(utc_date_str) {
    // first convert into a format like "2011-10-10T14:48:00.000+09:00"
    const utc_date_str_split = utc_date_str.split(" ")
    const date_str = utc_date_str_split[0]
    const time_str = utc_date_str_split[1]
    const new_datetime_str = date_str + "T" + time_str + ":00.000+00:00"

    return new Date(Date.parse(new_datetime_str))
}

export const formatStartDate = (date) => {
    // console.log('date = ', date)
    // const days_of_week = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    let hours = date.getHours()
    let am_pm_str
    if (hours >= 12) {
        am_pm_str = "PM"
    } else {
        am_pm_str = "AM"
    }
    let hour_str
    if (hours > 12)
        hour_str = (hours - 12).toString()
    else
        hour_str = hours.toString()
    let mins_str
    if (date.getMinutes() < 10) {
        mins_str = "0" + date.getMinutes().toString()
    } else {
        mins_str = date.getMinutes().toString()
    }
    return months[date.getMonth()] + " " + date.getDate() + ", " + hour_str + ":" + mins_str + " " + am_pm_str
}

export const validateHHHHMMDD = s => {
    // console.log('s = ', s)
    if (!s || s.trim().length === 0) {
        return false
    }
    const hyphen_split = s.split("-")
    if (hyphen_split.length !== 3) {
        return false
    }
    const year = parseInt(hyphen_split[0])
    // console.log('year = ', year)
    if (year < 2022 || year > 2023) {
        return false
    }
    const month = parseInt(hyphen_split[1])
    // console.log('month = ', year)
    if (month < 1 || month > 12) {
        return false
    }
    const day = parseInt(hyphen_split[2])
    if (day < 1 || day > 31) {
        return false
    }
    // const now = new Date().getTime()
    // const date_validating = Date.parse(s)
    // if (now > date_validating) {
    //     return false
    // }
    // // console.log('now = ', now.toISOString());
    // // console.log('blah = ', new Date(Date.parse(s)).toISOString())
    return true
}
