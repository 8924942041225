import React, {useEffect, useState, useRef} from 'react';
import { Box, Typography, Grid, Container } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useStyles } from "../layouts/styles";

import "./PauseScreen.css";



const PauseScreen = ({gameStatus}) => {
    const classes = useStyles();
    // console.log('pause gameStatus= ', gameStatus)

    const isHalfTime = () => {
        return ((gameStatus.last_play && gameStatus.last_play.indexOf('End of Half') >= 0)
            || gameStatus?.last_outcome?.rpk === 'End 2nd Quarter')
    }

    const getTitle = () => {
        if (isHalfTime()) {
            return "END OF HALF"
        } else if (gameStatus.last_play_was_score) {
            return "SCORE!"
        } else if (gameStatus.offense_team) {
            return "GAME IN PLAY...";
        }
        return "BETS PAUSED...";
    }

    const getSubTitle = () => {
        if (isHalfTime()) {
            return (<Grid item className={classes.msgH1}>
                <Typography className="msgGamePaused">Play will resume shortly...</Typography>
            </Grid>)
        } else if (gameStatus.last_play_was_score) {
            return (<Grid item className={classes.msgH1}>
                <Typography className="msgGamePaused">Changing possession...</Typography>
                <Typography className="msgGamePausedSml">All Calls are paused. Wait for the next</Typography>
                <Typography className="msgGamePausedSml">kickoff to place your next Call.</Typography>
            </Grid>)
        } else if (gameStatus.offense_team) {
            return (<Grid item className={classes.msgH1}>
                <Typography className="msgGamePaused">Play in progress..</Typography>
                <Typography className="msgGamePausedSml">All Calls are paused. Wait for the play</Typography>
                <Typography className="msgGamePausedSml">to finish to place your next Call.</Typography>
            </Grid>)
        }
        // Game hasn't started?
        return (<Grid item className={classes.msgH1}>
            <Typography className="msgGamePaused">Please wait..</Typography>
            <Typography className="msgGamePausedSml">All Calls are paused. Wait for the game</Typography>
            <Typography className="msgGamePausedSml">to start to place your next Call.</Typography>
        </Grid>)
    }

    return (
    <Box className={classes.pauseScreen}>
        <Container className={classes.pausedPlay}></Container>
        <Grid
            container
            direction="column"
            justify="space-evenly"
            spacing={2}
            className={classes.prim}
        >
            <Grid item>
                <Typography align="left">{getTitle()}</Typography>
            </Grid>
        </Grid>
        <Box className={classes.gamePaused}>
        <Grid container direction="column" spacing={2} style={{alignItems: "center"}}>
            {getSubTitle()}
        </Grid>
      </Box>
    </Box>
    ); };
export default PauseScreen;
