import { makeStyles } from "@material-ui/core";
import logoLogin from "../icons/logoLogin.png";
import pauseIcon from "../icons/hourglass.png";
export const useStyles = makeStyles((theme) => ({
  forgotPass: {
    fontFamily: "Kanit",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "12px",
    lineHeight: "140%",
    textAlign: "right",
    paddingTop: 12,
    color: "#FDC934",
    opacity: 0.7,
  },
  rememberMe: {
    fontFamily: "Kanit",
    fontStyle: "normal",
    fontWeight: 450,
    fontSize: "14px",
    lineHeight: "120%",
    textAlign: "right",
    color: "#FFF",
    opacity: 0.7,
  },
  signIn: {
    fontFamily: "Kanit",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "140%",
    textAlign: "left",
    letterSpacing: "0.01em",
    paddingLeft: 10,
    color: "#FFF",
  },
 /* input: {
    boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.3)",
    borderRadius: "4px",
    width: "343px",
    opacity: 1,
  },*/
  terms: {
    width: "100%",
    height: "100%",
    color: "white",
    margin: "0px",
    padding: "10px",
    overflowY: "scroll",
    height: "760px",
    fontSize: "12px",
    whiteSpace: "pre-line"

  },
  teamLogo: {
    height: "50px",
    width:"50px"
  },
  teamLogoTitle: {
    width: "30px",
    height: "30px",
    marginRight: "10px",
  },
  patternContent: {
    position: "absolute",
    zIndex: -999,
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
  },
  form: {
    transform: "translate(-50%, -20%)",
    left: "50%",
    bottom: "10%",
    width: "343px",
    position: "absolute",
  },
  patternContainer: {
    position: "relative",
  },
  bg: {
    zIndex: -1000,
  },
  logo: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  header: {
    paddingVertical: "0.4em",
    backgroundColor: "#162640",
    flex: 1,
    flexDirection: "column",
  },
  root: {
    flexGrow: 1,
    overflowX: 'hidden'
  },
  matchHeaderText: {
    // width:"250px",
    fontFamily: "Kanit",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "140%",
    textAlign: "center",
    letterSpacing: "0.04em",
    textTransform: "none !important",
    paddingLeft: 16,
    paddingRight: 16
  },
  matchBtnText: {
    fontFamily: "Kanit",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "140%",
    textAlign: "center",
    letterSpacing: "0.04em",
    textTransform: "none !important"
  },
  logoLogin: {
    backgroundImage: "url(" + logoLogin + ")",
    backgroundRepeat: "no-repeat",
    backgroundPositionX: '50%',
    width: "100%",
    height: "100%",
    top: "20%",
    "@media (max-height: 640px)": {
      top: "10%",
    },
    position: "absolute",
  },
  logoLoginImgWrap: {
    width: '100%',
    textAlign: 'center',
    marginTop: "10%",
    "@media (max-height: 300px)": {
      display: "none",
    },
    "@media (min-height: 641px)": {
      marginTop: 'min(30%, 140px)',
    },
  },
  logoLoginImg: {
    width: "50%",
    maxWidth: 200
  },
  playerTarget: {
    textAlign: "left",
    fontSize: "12px",
    fontFamily: "Kanit",
    fontStyle: "normal",
    fontWeight: "normal",
    letterSpacing: "0.1em",
    lineHeight: "140%",
    color: "#FFFFFF",
  },
  playerTargetSec: {
    textAlign: "left",
    fontSize: "12px",
    fontFamily: "Kanit",
    fontStyle: "normal",
    fontWeight: "normal",
    letterSpacing: "0.1em",
   /* lineHeight: "140%",*/
    color: "#FFFFFF",
    lineHeight: "20.38px",
    margin: "0"
  },
  nbaTargetSec: {
    textAlign: "left",
    fontSize: "14px",
    fontFamily: "Kanit",
    fontStyle: "normal",
    fontWeight: "normal",
    letterSpacing: "0.1em",
    lineHeight: "18px",
    color: "#FFFFFF",
    height: 18
  },
  svgContainer: {
    display:"inline-flex",
    verticalAlign:"top",
    justifyContent:"center",
    alignItems:"center",
    height: 18,
    lineHeight: '18px'
  },
  playerTargetSecLost: {
    textAlign: "left",
    fontSize: "12px",
    fontFamily: "Kanit",
    fontStyle: "normal",
    fontWeight: "normal",
    letterSpacing: "0.1em",
    lineHeight: "140%",
    color: "#91b5fe"
  },
  betWonSec: {
    textAlign: "left",
    fontSize: "14px",
    fontFamily: "Kanit",
    fontStyle: "normal",
    fontWeight: "normal",
    letterSpacing: "0.1em",
    lineHeight: "140%",
    color: "#FFFFFF",
    marginTop: "auto",
    marginBottom:"auto"
  },
  betWonSecExp: {
    paddingTop:"10px",
    paddingBottom: "10px",
    textAlign: "left",
    fontSize: "14px",
    fontFamily: "Kanit",
    fontStyle: "normal",
    fontWeight: "normal",
    letterSpacing: "0.1em",
    lineHeight: "140%",
    color: "#FFFFFF",
    marginTop: "auto",
    marginBottom:"auto"
  },
  betStatistic: {
    textAlign: "left",
    fontSize: "14px",
    fontFamily: "Kanit",
    fontStyle: "normal",
    fontWeight: "normal",
    letterSpacing: "0.1em",
    lineHeight: "140%",
    color: "#FFFFFF",
    marginTop: "auto",
    marginBottom:"auto"
  },
  selectBet: {
    textAlign: "left",
    fontSize: "12px",
    fontFamily: "Kanit",
    fontStyle: "normal",
    fontWeight: "normal",
    letterSpacing: "0.1em",
    lineHeight: "140%",
    color: "rgba(255, 255, 255, 0.7)",
  },
  selectBetIcn: {
    textAlign: "left",
    fontSize: "12px",
    fontFamily: "Kanit",
    fontStyle: "normal",
    fontWeight: "normal",
    letterSpacing: "0.1em",
    lineHeight: "140%",
    color: "rgba(255, 255, 255, 0.7)",
    display: "flex",
    alignItems: "center",
  },
  seeMoreContainer: {
    with: '100% !important',
  },
  loginBody: {
    background: "linear-gradient(349.69deg, #21232C 0.52%, #333641 100%)",
    position: "fixed",
    top: 0,
    left: 0,
    minWidth: "100%",
    minHeight: "100vh",
  },
  body: {
    background: "#1E2128",
    position: "relative",
    top: 0,
    left: 0,
    paddingBottom: '60px',
    minWidth: "100%",
    minHeight: "calc(100vh - 60px)",
  },
  matchHeader: {},
  quota: {
    radius: 4,
    width: 42,
    height: 24,
    padding: 3,
    borderRadius: 4,
    background: "rgba(31, 38, 48, 0.2)",
    border: "1px solid rgba(255, 255, 255, 0.2)",
  },
  matchTeamCode: {
    color: 'white',
    fontSize: 24,
    textAlign: 'center',
    height: 50,
    lineHeight: 50
  },
  score: {},
  game: {
    paddingLeft: 16,
    paddingTop: 24,
    paddingRight: 16,
    "& > *": {},
  },
  prim: {
    paddingTop: 24,
    paddingLeft: 16,
    paddingRight: 16,
    "& > *": {},
  },
  primLeaderboard: {
    overflowY: "scroll",
    paddingTop: 24,
    paddingLeft: 16,
    paddingRight: 16,
    "& > *": {},
  },
  item: {
    position: "relative",
  },
  grid: {
    padding: 16,
    flexGrow: 1, padding: 16,
    flexGrow: 1,
  },
  matchButton: {
    background: "#2a2d35",
    height: 135,
    width: "100%",
    color: "white",
    borderRadius: "4px",
    padding: 20,
    textAlign: "center",
    "&:hover": {
      color: "black",
      backgroundColor: "#FDC934",
    },
    "&:active": {
      color: "black",
      background: "#FDC934",
    },
    "&:focus": {
      color: "black",
      background: "#FDC934",
    },
  },
  matchButtonNba: {
    background: "#2a2d35",
    height: 135,
    width: "100%",
    color: "white",
    borderRadius: "4px",
    padding: 20,
    marginBottom:20,
    textAlign: "center",
    "&:hover": {
      color: "black",
      backgroundColor: "#FDC934",
    },
    "&:active": {
      color: "black",
      background: "#FDC934",
    },
    "&:focus": {
      color: "black",
      background: "#FDC934",
    },
  },
  dismissButton : {
    background: "#FDC934",
    textTransform: "none",
    height: "68px",
    width: "100%",
    color: "darkolivegreen",
    borderRadius: "4px",
    padding: 20,
    textAlign: "center",
    fontFamily: "Kanit",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "140%",
    letterSpacing: "0.02em",
    textAlign: "end",
  },
  potentialReturnContainer: {
    left: "0",
    width: "100%",
    bottom: "5px",
    position: "absolute",
    overflow: "hidden",
    textAlign: "center",
    zIndex: 100
  },
  seeMoreButton: {
    background: "#246bfd",
    textTransform: "none",
    height: "68px",
    width: "100%",
    color: "#bdd3fe",
    borderRadius: "4px",
    padding: "23px 0px 20px 0px",
    textAlign: "end",
    fontFamily: "Kanit",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "140%",
    letterSpacing: "0.02em",
    textAlign: "end",
  },
  activeButton: {
    background: "#FDC934 !important",
    textTransform: "none",
    height: "68px",
    width: "100%",
    color: "#000 !important",
    borderRadius: "4px",
    // padding: 20,
    // textAlign: "center",
    "& > *": {
      color: "black !important",
    },
    "& > span > MuiGrid-root > MuiGrid-item > MuiBadge-root> MuiBadge-badge": {
      color: "black !important",
    },
    "&:hover": {
      color: "black !important",
      backgroundColor: "#FDC934",
    },
    "&:active": {
      color: "black !important",
      background: "#FDC934",
    },
    "&:focus": {
      color: "black !important",
      background: "#FDC934",
    },
  },
  spanActive: {
    color: "#000 !important"
  },
  dimmedButton: {
    background: "#2a2d35",
    opacity: 0.4,
    textTransform: "none",
    height: "68px",
    width: "100%",
    color: "#FFF",
    borderRadius: "4px",
    padding: 20,
    textAlign: "center",
    "&:hover": {
      color: "black",
      backgroundColor: "#FDC934",
    },
    "&:active": {
      color: "black",
      background: "#FDC934",
    },
    "&:focus": {
      color: "black",
      background: "#FDC934",
    },
  },
  button: {
    background: "#2a2d35",
    textTransform: "none",
    height: "68px",
    width: "100%",
    color: "#FFF",
    borderRadius: "4px",
    // padding: 20,
    // textAlign: "center",
    "&:hover": {
      color: "#FFF",
      background: "#2a2d35",
       textTransform: "none",

    },/*
    "&:active": {
      color: "black",
      background: "#FDC934",
    },
    "&:focus": {
      color: "black",
      background: "#FDC934",
    },*/
  },
  input: {
    boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.3)",
    borderRadius: "4px",
    width: "343px",
    opacity: 1,
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "grey"
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "grey"
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "white"
    },
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      fontFamily: 'Kanit',
      fontSize: 14,
      color: 'blue'
    }
  },
  inputName: {
    boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.3)",
    borderRadius: "4px",
    width: "48%",
    opacity: 1,
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "grey"
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "grey"
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "white"
    },
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: 'blue'
    }
  },
  activeBtnSpan: {
    color: "black !important",
  },
  btnSpan: {
    color:"#FDC934",
  },
  btnLeaderSpan: {
    color:"#FFF",
  },
  containerPlay: {
    alignItems: "flex-end",
  },
  containerPlayWrap: {
    padding: "8px 8px 8px 0px !important"
  },
  fadeOut: {
    top: "100%",
    transform:" translate(0, 100%)",
  },
  fadeIn: {
    width: "100%",
    bottom: "115px",
    position: "absolute",
    height:"100px",
    transition: "all 500ms ease",
  },
  fadeOutTop: {
    top: -200,
    transform:" translate(0, 100%)",
  },
  fadeInTop: {
    width: "100%",
    top: 0,
    position: "absolute",
    height: "80px",
    transition: "all 500ms ease",
  },
  potentialPayoutAlert: {
    background: "#FDC934",
    textTransform: "none",
    height: "68px",
    bottom: "0px",
    width: "96%",
    color: "#000 !important",
    borderRadius: "4px",
    padding: 20,
    textAlign: "center",
    "&:hover": {
      background: "#FDC934"
    }
  },
  betErrorAlert: {
    background: "#BC121D",
    color: "#FFF !important",
    textTransform: "none",
    height: "68px",
    bottom: "0px",
    width: "96%",
    borderRadius: "4px",
    padding: 20,
    textAlign: "center",
    "&:hover": {
      background: "#BC121D"
    }
  },
  betWonAlert: {
    background: "#246bfd",
    textTransform: "none",
    height: "68px",
    bottom: "0px",
    width: "96%",
    color: "#FFF !important",
    borderRadius: "4px",
    padding: 20,
    textAlign: "center",
    "&:hover": {
      color: "#FFF !important",
      background: "#246bfd",
    },
    zIndex: 20
  },
  betWonAlertExt: {
    background: "#246bfd",
    alignItems: "flex-start",
    textTransform: "none",
    bottom: "0px",
    width: "96%",
    color: "#FFF !important",
    borderRadius: "4px",
    textAlign: "center",
    "&:hover": {
      color: "#FFF !important",
      background: "#246bfd",
    },
  },
  exitAlert: {
    top: "5px",
    left: "95%",
    position: "absolute",
  },
  leaderboardBtn: {
    left: "0",
    width: "100%",
    bottom: "0",
    position:"absolute",
  },
  leaderboardContainer: {
    // height: "570px",
    overflowY: "scroll",
  },
  playerLeaderBoard:{
    padding: "8px",
  },
  leaderBets: {
    color: '#FDC934',
    fontFamily: 'Kanit',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '140%',
    letterSpacing: '0.04em'
  },
  leaderBetActive: {
    color: 'black',
    fontFamily: 'Kanit',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '140%',
    letterSpacing: '0.04em'
  },
  pausedPlay: {
    width: "100%",
    height: "100%",
    top: "100px",
    position: "absolute",
  },
  pauseMsg: {
    fontFamily: "Kanit",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "140%",
    textAlign: "left",
    letterSpacing: "0.01em",
    paddingLeft: 10,
    color: "#FFF",
  },
  gamePaused: {
    left: "50%",
    top: "55%",
    width: "343px",
    position: "absolute",
    transform: "translate(-50%, -50%)"
  },
  linkToGame: {
    left: "50%",
    top: "71%",
    width: "343px",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    textAlign: 'center'
  },
  msgH1: {
    alignItems: "center"
  },
  pauseScreen: {
    backgroundImage: "url(" + pauseIcon + ")",
    backgroundRepeat: "no-repeat",
    backgroundPositionX: '50%',
    backgroundPositionY: '20%',
    left: "0",
    position: "fixed",
    minWidth: "100%",
    background: "#1E2128",
    minHeight: "100%"
  }
}));
